export function checkIfEmpty(duration) {
  if (duration === undefined || duration === null || duration === "") {
    return "Can't be empty.";
  }
  return "";
}

export function checkIfInteger(duration) {
  const parsedDuration = parseInt(duration);
  if (
    duration !== undefined &&
    duration !== null &&
    duration !== "" &&
    (isNaN(parsedDuration) ||
      duration.toString() !== parsedDuration.toString() ||
      duration.includes("."))
  ) {
    return "Has to be an integer.";
  }
  return "";
}

export function checkIfInRange(duration, range, isDecimal) {
  const numericDuration = Number(duration);
  if (
    duration !== undefined &&
    duration !== null &&
    duration !== "" &&
    range[1] !== null &&
    range[1] !== undefined &&
    range[1] !== "" &&
    !isNaN(numericDuration) &&
    (numericDuration < range[0] || numericDuration > range[1])
  ) {
    let formattedRangeStart = range[0];
    if (isDecimal) {
      formattedRangeStart = range[0] === 0.0 ? "0.0" : range[0];
    }

    const formattedRangeEnd = range[1].toString();
    return `Has to be a value from ${formattedRangeStart} to ${formattedRangeEnd}.`;
  }
  return "";
}

export function checkIfDecimal(duration) {
  const numericDuration = Number(duration);
  if (duration === "") return "";
  if (
    duration !== undefined &&
    duration !== null &&
    !isNaN(numericDuration) &&
    !Number.isInteger(numericDuration)
  ) {
    return "";
  }
  return "Has to be a decimal.";
}
