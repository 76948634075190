import React from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useHistory, useParams } from "react-router-dom";
import { Modal } from "../../../../app/components";

const UnsavedChangesModal = ({
  open = false,
  onToggle,
  to,
  handleSave,
  handleBackToList,
}) => {
  const history = useHistory();
  const { id } = useParams();

  const handleSaveAndExit = async () => {
    const saved = await handleSave();
    if (saved) redirect();
    else onToggle();
  };

  const handleExitWithoutSaving = async () => {
    handleBackToList();
  };

  const redirect = () =>
    to ? history.push(to) : history.push(`/ctpo/study-plans/${id}`);

  return (
    <Modal
      open={open}
      onClose={onToggle}
      aria-labelledby="clone study plan"
      aria-describedby="clone a study plan"
      title="You Have Unsaved Changes"
    >
      <Typography>
        Are you sure you want to exit without saving your changes first? Select
        an action below to continue.
      </Typography>
      <Container>
        <Button variant="outlined" onClick={handleExitWithoutSaving}>
          Exit Without Saving
        </Button>
        <Button variant="contained" color="primary" onClick={handleSaveAndExit}>
          Save & Exit
        </Button>
      </Container>
    </Modal>
  );
};

export default UnsavedChangesModal;

const Container = styled.div`
  margin-top: 2em;
  display: flex;
  justify-content: flex-end;
  gap: 1em;
`;
