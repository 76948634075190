import { Button, Typography } from "@material-ui/core";
import React, { useEffect, useMemo } from "react";
import AddIcon from "@material-ui/icons/Add";
import { SectionHeader } from "../../../../app/components";
import TableHeader from "./table.header";
import TableRow from "./table.row";
import { Tooltip } from "recharts";
import { getSequentialTableDefaults } from "../../../../app/contexts/helpers";
import { isNumberNotEmpty } from "../../utils/helpers";
import moment from "moment";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useStudyPlan } from "../../../../app/contexts/study.context";
import { updateCalculatedFields } from "./utilities";

export default function SequentialCohortSetupTable() {
  const {
    studyPlan,
    seqCohorts,
    setSeqCohorts,
    setSeqCohortChangeLog,
    seqCohortsStatus,
    seqCohortColumnHeaders,
    updateCohortStudyPlan,
  } = useStudyPlan();

  const { planId: studyPlanId } = useParams();
  const PREDECESSOR_ACTIVITY_OPTIONS = useMemo(() => {
    return [
      {
        name: "Screening",
        value: "Screening",
      },
      { name: "Enrolling", value: "Enrolling" },
      { name: seqCohortColumnHeaders.columnHeader3, value: "DTL" },
      { name: seqCohortColumnHeaders.columnHeader4, value: "SRC" },
    ];
  }, [seqCohortColumnHeaders]);

  const croStartDate = useMemo(
    () =>
      studyPlan.croProjectStartDateMillis
        ? moment.utc(studyPlan.croProjectStartDateMillis).format("D/MMM/YY")
        : "",
    [studyPlan],
  );
  const fpsStartDate = useMemo(() => {
    if (studyPlan) {
      const { countries } = studyPlan;
      if (countries) {
        const lCountries = [...countries];
        const fpsDateValues = lCountries.map((country) => {
          const { siteGroups } = country;
          const { milestones } = siteGroups[0];
          let dateValue = "";
          milestones.forEach((ml) => {
            if (ml["milestoneName"] === "DURATION_TO_FPS")
              dateValue = ml["milestoneDateMillis"];
          });
          return dateValue;
        });

        return moment.utc(Math.min(...fpsDateValues)).format("D/MMM/YY");
      }
    }
  }, [studyPlan]);

  // call update calculated fields when DURATION_TO_FPS milestone changes
  useEffect(() => {
    if (fpsStartDate) {
      const newSeqCohorts = updateCalculatedFields(
        seqCohorts,
        fpsStartDate,
        croStartDate,
      );
      setSeqCohorts(newSeqCohorts);

      (async () => {
        if (newSeqCohorts.length)
          await updateCohortStudyPlan(studyPlan.studyId, true, newSeqCohorts);
      })();
    }
    // eslint-disable-next-line
  }, [fpsStartDate]);

  useEffect(() => {
    if (seqCohortsStatus === "resolved") {
      // trigger table value calculation and formatting on page load
      handleSeqValueChange("load", "load", "load", "load");
    }
    // eslint-disable-next-line
  }, [seqCohortsStatus]);

  const addNewRow = () => {
    setSeqCohorts([
      ...seqCohorts,
      getSequentialTableDefaults(parseInt(studyPlanId || "0")),
    ]);
    setSeqCohortChangeLog({ someValue: "seq cohort change" });
  };

  const removeRow = (identifier) => {
    setSeqCohorts([
      ...seqCohorts.filter(
        (sp) =>
          (isNumberNotEmpty(sp.studyPlanCohortId)
            ? sp.studyPlanCohortId
            : sp.key) !== identifier,
      ),
    ]);
    setSeqCohortChangeLog({ someValue: "seq cohort change" });
  };

  const isUnique = (cohorts, value) => {
    return !cohorts.filter((c) => c.cohortName === value).length;
  };

  const handleSeqValueChange = (name, value, key, type = "number") => {
    if (name === "cohortName" && value) {
      if (!isUnique(seqCohorts, value)) return;
    }
    let newSeqCohorts = seqCohorts.map((sp) => {
      if ((sp.studyPlanCohortId || sp.key) === key) {
        // checking the number inputs to not have a negative value - this doesn't include the lag field
        if (type === "number" && value) {
          if (parseInt(value) < 0) return sp;
        }
        return {
          ...sp,
          [name]: type === "number" && value ? parseInt(value) : value,
        };
      }
      return sp;
    });

    newSeqCohorts = updateCalculatedFields(
      newSeqCohorts,
      fpsStartDate,
      croStartDate,
    );

    setSeqCohorts(newSeqCohorts);
    if (name !== "load")
      setSeqCohortChangeLog({ someValue: "seq cohort change" });
    // else setSeqCohortChangeLog({});
  };

  const onSeqChange = (name, value, key, type) => {
    handleSeqValueChange(name, value, key, type);
  };

  const renderTableRows = () => {
    return seqCohorts.map((sp, index) => {
      return (
        <TableRow
          key={sp.key || sp.studyPlanCohortId}
          sp={sp}
          seqCohorts={seqCohorts}
          onSeqChange={onSeqChange}
          index={index}
          PREDECESSOR_ACTIVITY_OPTIONS={PREDECESSOR_ACTIVITY_OPTIONS}
          removeRow={removeRow}
        />
      );
    });
  };

  return (
    <Container>
      <SectionHeader endAdornment={<Tooltip info="Sequential Cohort Setup" />}>
        Sequential Cohort Setup
      </SectionHeader>
      <Section>
        <Table noBorder>
          <Row noBorder>
            <Cell
              fullBorder
              width={`${(8 / 70) * 100}%`}
              variant="header"
            >
              <Typography variant="body2">
                <strong>CRO Start Date</strong>
              </Typography>
            </Cell>
            <Cell
              rightBorder
              width={`${(8 / 70) * 100}%`}
            >
              {croStartDate}
            </Cell>
          </Row>
          <Row noBorder>
            <Cell
              width={`${(8 / 70) * 100}%`}
              fullBorder
              variant="header"
            >
              <Typography variant="body2">
                <strong>Start Date (FPS)</strong>
              </Typography>
            </Cell>
            <Cell
              rightBorder
              width={`${(8 / 70) * 100}%`}
            >
              {fpsStartDate}
            </Cell>
          </Row>
        </Table>
      </Section>
      <Table>
        <TableHeader />
        {renderTableRows()}
      </Table>

      <ButtonContainer>
        <Button
          sx={{ width: "4rem" }}
          onClick={addNewRow}
          startIcon={<AddIcon />}
        >
          Add Row
        </Button>
      </ButtonContainer>
    </Container>
  );
}

const Section = styled.div`
  margin-bottom: 1rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  margin: 1rem 0rem;
`;

const Row = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  height: 50px;
  border-bottom: ${(p) => (p.noBorder ? "none" : "1px solid #ccc")};
  &:last-child {
    border: none;
  }
`;

const Cell = styled.div`
  background: ${(p) => (p.variant === "header" ? "#f3f4f4" : "white")};
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  width: ${(p) => (p.width ? p.width : "100%")};
  border-right: 1px solid #ccc;
  padding: 5px;
  text-align: center;
  border-left: ${(p) => (p.leftBorder ? "1px solid #ccc" : "none")};
  &:last-child {
    border: ${(p) => (p.rightBorder ? "1px solid #ccc" : "none")};
  }
  border: ${(p) => (p.fullBorder ? "1px solid #ccc" : "")};
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  border: ${(p) => (p.noBorder ? "none" : "1px solid #ccc")};
  border-radius: 4px;
  width: 100%;
`;
