import React, { useEffect, useState } from "react";
import { TextField, makeStyles } from "@material-ui/core";

import { Autocomplete } from "@material-ui/lab";
import fuzzysort from "fuzzysort";
// Add a comment describing the purpose of this component
/*
  This component is a fuzzy search input that allows the user to search for a value
  and returns the value to the parent component.
  It uses fuzzysort to handle the search.
  The input text will be highlighted in the options.
  The parent component must pass a callback function to handle the value.
  The parent component must also pass an array of options.
  The parent component can pass a default value.
  The parent component can pass a helper text.
  The parent component can pass a message.
  The parent component can pass an object of input props.
*/
export default function FuzzySearchInput({
  options: mainOptions = [],
  defaultValue,
  onChange,
  helperText,
  message,
  error,
  ...props
}) {
  const classes = useStyles();
  const [options] = useState(mainOptions);
  const [value] = useState(defaultValue);
  const [textInput, setTextInput] = useState(defaultValue);

  useEffect(() => {
    if (textInput?.length > props.inputProps?.maxLength)
      setTextInput(defaultValue);
    // eslint-disable-next-line
  }, [defaultValue]);

  const [timeOut, setCustomTimeOut] = useState(null);

  useEffect(() => {
    if (timeOut) clearTimeout(timeOut);
    setCustomTimeOut(
      setTimeout(() => {
        if (textInput !== defaultValue)
          onChange(
            textInput.slice(0, props.inputProps?.maxLength || textInput.length)
          );
      }, 500)
    );
    // eslint-disable-next-line
  }, [textInput]);

  return (
    <Autocomplete
      defaultValue={defaultValue}
      value={value}
      size="small"
      id="autocomplete"
      freeSolo
      disableClearable
      classes={classes}
      options={[...options]}
      onChange={(e, selected) => {
        onChange(selected);
      }}
      filterOptions={(options, params) => {
        const results = fuzzysort
          .go(params.inputValue, [...options])
          .slice(0, 5);
        const highlights = results.map((result) =>
          fuzzysort.highlight(result || [], "", "")
        );
        return highlights;
      }}
      renderOption={(option) => {
        return (
          <span
            dangerouslySetInnerHTML={{
              __html: option
                .toString()
                .replace(
                  new RegExp(
                    textInput
                      .replaceAll("(", "&#40;")
                      .replaceAll(")", '"&#41;"')
                      .trim(),
                    "gi"
                  ),
                  (match) =>
                    `<strong class='font-bold text-navy-black'>${match}</strong>`
                ),
            }}
          ></span>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={(e) => {
            setTextInput(e.target.value);
          }}
          variant="outlined"
          placeholder="..."
          helperText={helperText}
          InputProps={{ ...params.InputProps }}
          error={error}
          inputProps={{
            ...params.inputProps,
            ...props.inputProps,
          }}
        />
      )}
      {...props}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    backgroundColor: "white",
  },
}));
