import React from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";

import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import { SectionHeader, Tooltip } from "../../../../app/components";

export default function StudyPlanTypeOptions({
  errors,
  touched,
  setValues,
  values,
}) {
  return (
    <Container>
      <SectionHeader
        endAdornment={
          <Tooltip info="Choose overall enrollment duration if you want to remodel based on a specific last patient enrolled date. This modeling will provide the average enrollment rate needed to complete enrollment by that date." />
        }
        hasStartAdornment={false}
      >
        <Typography style={{ color: "#333" }}>
          What type of study plan would you like to create?
        </Typography>
      </SectionHeader>

      <FormControl component="fieldset">
        <RadioGroup
          row
          aria-label="studyPlanType"
          name="studyPlanTypes"
          value={values.studyPlanType}
          onChange={(e) => {
            setValues({ ...values, studyPlanType: parseInt(e.target.value) });
          }}
        >
          <FormControlLabel
            value={1}
            control={<Radio color="primary" />}
            label="Enrollment Rates"
          />
          <FormControlLabel
            value={2}
            control={<Radio color="primary" />}
            label="Overall Enrollment Duration"
          />
        </RadioGroup>
      </FormControl>
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  grid-gap: 1em;
  margin: 2em 0em;
`;
