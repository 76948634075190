import { Divider } from "@material-ui/core";
import MuiPaper from "@material-ui/core/Paper";
import React from "react";
import styled from "styled-components";
import StudyPlanSearch from "../../study.plan.search";
import Header from "../common/header";
import ScheduleEventsTable from "../schedule.events.table";
import StudyTable from "../study.table";

export default function TablesContainer() {
  return (
    <MainContainer>
      <StudyPlanSearch hasMargin={false} />
      <Paper>
        <Container>
          <Header />
          <StudyTable />
          <Divider style={{ margin: "3rem 0px" }} />
          <ScheduleEventsTable />
        </Container>
      </Paper>
    </MainContainer>
  );
}

const MainContainer = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 350px 1fr;
  width: 100%;
  min-width: calc(100vw - 30px);
`;

const Container = styled.div`
  margin-bottom: 2em;
  padding: 1em;
  overflow: auto;
`;

const Paper = styled(MuiPaper)`
  overflow: hidden;
  gap: 1em;
  flex-direction: column;
  display: flex;
  position: relative;
  width: 100%;
  height: 87vh;
  height: calc(100vh - 110px) !important;
`;
