import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { SectionHeader, Tooltip } from "../../../../app/components";
import SpecialRadioButton from "../../../../app/components/special.radiobutton";

export default function EnrollmentRate({ errors, touched, values, setValues }) {
  return (
    <Container>
      <SectionHeader
        hasStartAdornment={false}
        endAdornment={
          <Tooltip info="Select the enrollment rate you want to use in the remodel. You can chose the planned rate, the actual rate or input a new rate. In the next section, you can select a country-specific rate. The country specific rates will supersede this overall rate." />
        }
      >
        Overall Enrollment Rate (p/s/m)
      </SectionHeader>
      <FormControl component="fieldset">
        <RadioGroup
          row
          aria-label="enrollmentRateSelection"
          name="enrollmentRateSelection"
          value={values.enrollmentRateSelection}
          onChange={(e) => {
            if (!e.target.value) return;
            setValues({
              ...values,
              enrollmentRateSelection: parseFloat(e.target.value),
            });
          }}
        >
          <TableContainer>
            <Table>
              <Row>
                <Cell variant="header">
                  <Typography align="center">
                    <strong>Planned</strong>
                  </Typography>
                </Cell>
                <Cell>
                  {!(values?.enrollmentRatePlanned === null) && (
                    <FormControlLabel
                      value={1}
                      control={<Radio color="primary" />}
                      label={values?.enrollmentRatePlanned}
                    />
                  )}
                </Cell>
              </Row>
            </Table>
            <Table>
              <Row>
                <Cell variant="header">
                  <Typography align="center">
                    <strong>Actual</strong>
                  </Typography>
                </Cell>
                <Cell>
                  {!(values?.enrollmentRateActual === null) && (
                    <FormControlLabel
                      value={2}
                      control={<Radio color="primary" />}
                      label={values?.enrollmentRateActual}
                    />
                  )}
                </Cell>
              </Row>
            </Table>
            <Table>
              <Row>
                <Cell variant="header">
                  <Typography align="center">
                    <strong>Other</strong>
                  </Typography>
                </Cell>
                <Cell>
                  <SpecialRadioButton
                    type="number"
                    currentRadioValue={3}
                    onChange={(value) =>
                      setValues({
                        ...values,
                        enrollmentRateOther: parseFloat(value),
                      })
                    }
                    value={values?.enrollmentRateOther}
                    error={!!errors?.enrollmentRateOther}
                  />
                </Cell>
              </Row>
            </Table>
          </TableContainer>
        </RadioGroup>
        {errors.overallEnrollment && (
          <ErrorMessageContainer className="MuiFormHelperText-root">
            {errors.overallEnrollment}
          </ErrorMessageContainer>
        )}
      </FormControl>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TableContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-bottom: 1px solid #ccc;
  height: 10em;
`;

const Cell = styled.div`
  background: ${(p) => (p.variant === "header" ? "#f3f4f4" : "white")};
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  border-bottom: 1px solid #ccc;
  padding: 0.5em;
`;

const Table = styled.div`
  display: flex;
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const ErrorMessageContainer = styled.div`
  color: #f44336 !important;
  margin: 3px 14px !important;
`;
