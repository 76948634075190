export const VISIT_INTERVAL_TYPE_OPTIONS = [
  "Hour",
  "Day",
  "Week",
  "Month",
  "Year",
];
export const CDR_REVIEW_OPTIONS = [
  "0%",
  "10%",
  "20%",
  "30%",
  "40%",
  "50%",
  "60%",
  "70%",
  "80%",
  "90%",
  "100%",
];

export const ADDITIONAL_TIME_ON_SITE_FACTOR = ["5%", "10%", "15%"];

export const ESTIMATED_ACTIVATED_NON_ENROLLING_SITES = [
  "5%",
  "10%",
  "15%",
  "20%",
];

export const REQUIRED_ADMINISTRATIVE_IMV_NON_ENROLLING_SITES = [
  "Semi Annually (q6 months post Site Activation)",
  "Annually (q12 months post Site Activation)",
  "<blank value>",
];

export const SUBJECT_REPORTING_AVERAGE_FREQUENCY = [
  "Monthly",
  "q 6 Weeks",
  "q 2 Months",
  "Quarterly",
  "q 4 Months",
  "q 6 Months",
  "q 9 Months",
  "Annual",
];

export const SUBJECT_REPORTING_HOURS_TO_GENERATE = [
  "1",
  "1.5",
  "2",
  "2.5",
  "3",
  "3.5",
  "4",
  "4.5",
  "5",
  "8",
];

export const STUDY_REPORTING_HOURS_TO_GENERATE = [
  "7.5",
  "15",
  "20",
  "25",
  "30",
  "40",
  "45",
];
