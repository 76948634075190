import React from "react";
import { AccordionTable } from "../../../../../app/components";
import { useStudyPlan } from "../../../../../app/contexts/study.context";
import { COLUMN_HEADERS } from "./table.utils";
import TableCell from "@material-ui/core/TableCell";

const renderRow = (item) => {
  const defaultText = "## weeks";
  const fields = [
    item.siteGroupName,
    item["Site ID Duration (start to last site ID)"] ?? defaultText,
    item["Submission to Approval"] ?? defaultText,
    item["Approval to First Site Initiated"] ?? defaultText,
    item["Site Initiation Duration"] ?? defaultText,
  ];

  return fields.map((field, index) => (
    <TableCell
      key={index}
      align={index === 0 ? "left" : "center"}
      component={index === 0 ? "th" : undefined}
      scope={index === 0 ? "row" : undefined}
    >
      {index === 0 ? <strong>{field}</strong> : field}
    </TableCell>
  ));
};

const CountryStartUpDurations = () => {
  const { simulationResults } = useStudyPlan();
  const { durationTable = [] } = simulationResults;

  return (
    <AccordionTable
      summary="Country Start-Up Durations"
      columns={COLUMN_HEADERS}
      data={durationTable}
      renderRow={renderRow}
    />
  );
};

export default CountryStartUpDurations;
