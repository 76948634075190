import React from "react";
import styled from "styled-components";
import { SectionHeader } from "../../../app/components";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import { makeStyles } from "@material-ui/styles";

export default function SeasonalEnrollmentVariation({
  enrollmentVariations = [],
}) {
  const inputClasses = useInputStyles();

  return (
    <Container>
      <SideBySide>
        <SectionHeader hasStartAdornment={false} marginBottom={false}>
          Site Group Enrollment Seasonal Variations
        </SectionHeader>
      </SideBySide>
      <Table>
        <Row>
          <Cell variant="header">
            <Typography>
              <strong>Jan</strong>
            </Typography>
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>Feb</strong>
            </Typography>
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>Mar</strong>
            </Typography>{" "}
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>Apr</strong>
            </Typography>
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>May</strong>
            </Typography>
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>Jun</strong>
            </Typography>
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>Jul</strong>
            </Typography>
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>Aug</strong>
            </Typography>
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>Sep</strong>
            </Typography>
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>Oct</strong>
            </Typography>
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>Nov</strong>
            </Typography>
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>Dec</strong>
            </Typography>
          </Cell>
        </Row>
        <Row>
          {enrollmentVariations.map((item, index) => (
            <Cell key={index}>
              <InputBase
                type="number"
                classes={inputClasses}
                value={item.enrollmentSeasonalVariation}
                disabled
              />
            </Cell>
          ))}
        </Row>
      </Table>
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  height: 50px;
  border-bottom: 1px solid #ccc;
  &:last-child {
    border: none;
  }
`;

const Cell = styled.div`
  background: ${(p) => (p.variant === "header" ? "#f3f4f4" : "white")};
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  border-right: 1px solid #ccc;
  padding: 0.5em;
  &:last-child {
    border: none;
  }
  &:first-child {
    justify-content: flex-end;
    padding-right: 1em;
  }
  `;

  const Table = styled.div`
  display: grid;
  width: 100%;
  grid-template-rows: auto auto;
  border: 1px solid #ccc;
  border-radius: 4px;
`;



const useInputStyles = makeStyles(() => ({
  input: {
    textAlign: "center",
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "-moz-appearance": "textfield",
  },
}));

const SideBySide = styled.div`
  display: flex;
  justify-content: space-between;
`;



