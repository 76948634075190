import MuiTableRow from "@material-ui/core/TableRow";
import MuiTooltip from "@material-ui/core/Tooltip";
import React from "react";
import TableCell from "@material-ui/core/TableCell";
import moment from "moment";
import { withStyles } from "@material-ui/core";

const TooltipCell = ({ children, title, style }) => (
  <StyledTableCell
    align="left"
    style={style}
  >
    <MuiTooltip title={<p style={{ fontSize: "14px" }}>{title}</p>}>
      <span>{children}</span>
    </MuiTooltip>
  </StyledTableCell>
);

// create a styled table cell with no horizontal padding
export const StyledTableCell = withStyles((theme) => ({
  root: {
    paddingLeft: "5px",
    paddingRight: "5px",

    "&:first-child": {
      paddingLeft: "10px",
    },

    "&:not(:first-child)": {
      minWidth: "150px",
      maxWidth: "160px",
    },
  },
}))(TableCell);

const SidesTableRow = (props) => {
  const {
    Country,
    "Central / Local": centralLocal,
    "Site Number - PI": siteNumber,
    "Site Selected Date": siteSelectedDate,
    "Site Activated - Actual": siteActivatedActual,
    "Site Activated - Forecast": siteActivatedForecast,
    "Site Status": siteStatus,
  } = props;

  const formatDate = (date) => {
    if (!date) return "";
    // format date to MM/DD/YYYY using moment
    return moment(date).format("DD/MMM/YYYY");
  };

  const cells = [
    {
      title: props.index === 0 ? `Total: ${props.projects.length}` : Country,
      style: { minWidth: "7rem" },
    },
    { title: centralLocal, style: { minWidth: "7rem" } },
    { title: siteNumber },
    { title: formatDate(siteSelectedDate) },
    { title: formatDate(siteActivatedActual) },
    { title: formatDate(siteActivatedForecast) },
    { title: siteStatus },
  ];

  return (
    <MuiTableRow>
      <StyledTableCell align="left"></StyledTableCell>
      {cells.map((cell, i) => (
        <TooltipCell
          key={i}
          title={cell.title}
          style={cell.style ? cell.style : {}}
        >
          {cell.title}
        </TooltipCell>
      ))}
    </MuiTableRow>
  );
};

export default SidesTableRow;
