import React from "react";
import styled from "styled-components";
import Label from "../label";

const scenarioB = ({ siteGroup }) => {
  const { eventualSiteEnrollmentRate, eventualSiteEnrollmentDays } = siteGroup;

  return (
    <Container>
      <Content>
        <Label value={eventualSiteEnrollmentDays}>
          Number of Days to Site Fatigue
        </Label>
        <Label value={eventualSiteEnrollmentRate}>
          Reduced Enrollment Rate Percentage (in decimals)
        </Label>
      </Content>
    </Container>
  );
};

export default scenarioB;

const Container = styled.div``;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 1em;
`;
