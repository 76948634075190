import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

const viewOptions = [
  { value: "monthly", label: "Monthly (Cumulative)" },
  { value: "monthlyNonCumulative", label: "Monthly (Non-Cumulative)" },
  { value: "weekly", label: "Weekly (Cumulative)" },
  { value: "weeklyNonCumulative", label: "Weekly (Non-Cumulative)" },
];

const ViewSelector = ({ onChange, value, type }) => (
  <div>
    {
      <div style={{ display: "flex", justifyContent: "center" }}>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="view"
            name="view"
            value={value}
            onChange={(event) => onChange(event.target.value)}
            row
          >
            {viewOptions.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio />}
                label={option.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </div>
    }
  </div>
);

export default ViewSelector;
