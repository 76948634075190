export const opportunityStageOptions = [
  "Closed Won",
  "Closed Cancelled",
  "Written Confirmation of Award",
  "Proposal Sent",
  "Rebid Sent",
  "Closed Not Bidding",
  "On Hold by Client",
  "RFP In House",
  "RFI Sent",
  "WCA Rebid Sent",
  "Closed Lost",
  "RFI Requested",
  "Rebid In House",
  "WCA Cancelled",
  "Verbal Award",
  "WCA Rebid in House",
  "Verbal Award Rebid Sent",
  "Drafting Proposal",
  "Developing Project Scope",
];

export const headCells = [
  {
    id: "Country",
    type: "text",
    label: "Country",
  },
  {
    id: "Central / Local",
    type: "text",
    label: "Central / Local",
  },
  {
    id: "Site Number - PI",
    type: "text",
    label: "Site Number - PI",
  },
  {
    id: "Site Selected Date",
    type: "date",
    label: "Site Selected Date",
  },
  {
    id: "Site Activated - Actual",
    type: "date",
    label: "Site Activated - Actual",
  },
  {
    id: "Site Activated - Forecast",
    type: "date",
    label: "Site Activated - Forecast",
  },
  {
    id: "Site Status",
    type: "text",
    label: "Site Status",
  },
];
