import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

const ViewSelector = ({ onChange, value }) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <FormControl component="fieldset">
        <FormGroup row>
          <RadioGroup
            row
            aria-label="view"
            name="view"
            value={value}
            onChange={handleChange}
            style={{ justifyContent: "center" }}
          >
            <FormControlLabel
              value="region"
              control={<Radio color="primary" />}
              label="Region"
            />
            <FormControlLabel
              value="site-group"
              control={<Radio color="primary" />}
              label="Site-Group"
            />
          </RadioGroup>
        </FormGroup>
      </FormControl>
    </div>
  );
};

export default ViewSelector;