import { makeStyles, TextField } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { Field } from "formik";

export default function PrimaryDetails({ touched, errors, values }) {
  const classes = useStyles();

  return (
    <Container id="details">
      <Field name="studyPlanName">
        {({ field }) => (
          <TextField
            {...field}
            variant="outlined"
            label="Study Plan Name"
            InputLabelProps={{ classes }}
            error={!!errors.studyPlanName}
            required
            inputProps={{
              maxLength: 50,
            }}
            helperText={
              <AlignBetween>
                <span>{touched.studyPlanName && errors.studyPlanName}</span>
                <span>{`${(values.studyPlanName || "").length} / ${50}`}</span>
              </AlignBetween>
            }
          />
        )}
      </Field>
      <Field name="studyPlanDescr">
        {({ field }) => (
          <TextField
            {...field}
            variant="outlined"
            label="Description"
            InputLabelProps={{ classes }}
            error={!!(touched.studyPlanDescr && errors.studyPlanDescr)}
            helperText={touched.studyPlanDescr && errors.studyPlanDescr}
            inputProps={{
              maxLength: 2000,
            }}
            required
          />
        )}
      </Field>
    </Container>
  );
}

const Container = styled.div`
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const AlignBetween = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const useStyles = makeStyles({
  asterisk: {
    color: "red",
  },
});
