import { useHistory, useParams } from "react-router-dom";

import Button from "@material-ui/core/Button";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import React from "react";
import styled from "styled-components";
import { useSnackbar } from "../../../../app/contexts/snackbar.context";
import { useStudyPlan } from "../../../../app/contexts/study.context";
import { GetApp as GetAppIcon } from '@material-ui/icons';
import { makeStyles } from "@material-ui/styles";
import { useExportPlan } from '../../countries.and.site.groups/country.tabs/controls/export.plan/export.js';
const TopControls = ({ errors }) => {
  const history = useHistory();
  const { id, planId } = useParams();
  const {
    runSimulation,
    studyPlan,
    validateSequentialCohort,
    validateSeasonalVariations,
    isSequential,
  } = useStudyPlan();
  const { scenarioStatus, simOptRunId } = studyPlan;
  const { showSnackbar } = useSnackbar();

  const classes = useStyles();

  const exportPlan = useExportPlan(); 
  
  const handleBackToList = () => history.push(`/ctpo/study-plans/${id}`);

  const handleCancel = () => history.push(`/ctpo/study-plans/${id}`);

  const handleExportPlan = () => {
    exportPlan();
  };

  const handleRunSimulation = () => {
    if (scenarioStatus === "Draft") {
      const seqValid = isSequential
        ? validateSequentialCohort(history.location.pathname)
        : true;
      const seasonalValid = validateSeasonalVariations();
      if (
        Object.keys(errors || {}).length !== 0 ||
        !seqValid ||
        !seasonalValid
      ) {
        showSnackbar(
          <ul>
            {Object.keys(errors || {}).map((key) => {
              if (key !== "BUTTON_TYPE") return <li>{errors[key]}</li>;
              return "";
            })}
            {!seqValid && <li>Sequential cohorts setup is not valid</li>}
            {!seasonalValid && (
              <li>Country enrollment seasonal variations are not valid</li>
            )}
          </ul>,
          "error"
        );
        return;
      }
      runSimulation();
    }
    history.push(`/ctpo/study-plans/${id}/${planId}/simulation`);
  };

  const handleViewSimulation = () => {
    history.push(`/ctpo/study-plans/${id}/${planId}/simulation?type=view`);
  };

  return (
    <Container>
      <Button color="primary" onClick={handleBackToList}>
        <ChevronLeftIcon />
        Back to Study Plan List
      </Button>

      <ButtonContainer>
        <Button color="primary" onClick={handleExportPlan}>
          <GetAppIcon classes={classes} /> EXPORT PLAN
        </Button>
        <Button variant="outlined" onClick={handleCancel}>
          Cancel
        </Button>
        {scenarioStatus === "Draft" && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleRunSimulation}
          >
            Run Simulation
          </Button>
        )}

        {simOptRunId !== null && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleViewSimulation}
          >
            View Simulation
          </Button>
        )}
      </ButtonContainer>
    </Container>
  );
};

export default TopControls;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-gap: 1em;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: ".2em",
  },
}));