import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import SidesTableRow from "./../table.row/sides.not.included";
import TableSideHead from "./../table.head";
import { TablePagination } from "@material-ui/core";
import TableSidesNotIncludedFilterHead from "./../table.head/filter";
import { getComparator } from "../../../app/helpers/utils";
import { makeStyles } from "@material-ui/core/styles";

export const SiteListTable = ({
  rows,
  order,
  orderBy,
  handleRequestSort,
  filterActive,
  setFilterActive,
  filters,
  setFilters,
  page,
  rowsPerPage,
  count,
  onChangePage,
  onChangeRowsPerPage,
  showInfo,
  stableSort,
}) => {
  const classes = useStyles();

  return (
    <>
      <TableContainer classes={classes}>
        <Table
          size="medium"
          aria-label="study table"
        >
          <TableSideHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            filterActive={filterActive}
            setFilterActive={setFilterActive}
          />
          {filterActive && (
            <TableSidesNotIncludedFilterHead
              filters={filters}
              setFilters={setFilters}
              filterActive={filterActive}
              setFilterActive={setFilterActive}
            />
          )}
          <TableBody>
            {[
              {},
              ...stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
              ),
            ].map((row, index) => (
              <SidesTableRow
                key={index}
                {...row}
                projects={rows}
                index={index}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ flexGrow: 1 }}>
          {showInfo && (
            <p>
              * These sites will appear in the Outputs for past months but will
              not be factored into projecting patients for future months.
            </p>
          )}
        </div>
        <div style={{ marginLeft: showInfo ? "0" : "auto" }}>
          <TablePagination
            style={{ justifyContent: "flex-end" }}
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            rowsPerPage={rowsPerPage}
            page={page}
            count={count}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        </div>
      </div>
    </>
  );
};
const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "4px",
    height: "calc(100vh - 260px)",
    maxHeight: "calc(100vh - 260px)",
  },
}));
