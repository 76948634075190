export const headerData = [
  { text: "Planned", flex: 2 },
  { text: "Actual/Projected", flex: 2 },
  { text: "Other", flex: 2 },
  { text: "Remove Country", flex: 2 },
];

export const NUMBER_SITES_INCLUDE_PROJECTIONS_INFO = {
  Paragraph1: `Choose the number of sites in each country that you want to include in the remodel. If you want to add additional sites in a country, input a new value in the Other option. The Actual/Projected value includes all sites in the Remarque Start-up Module that are currently either active or have a projected activation date.`,

  Paragraph2: `For countries that you do not want to include in a Remodel (i.e., there are not active or closed sites in this country and there are no longer plans to include sites or patients in this country), select the radio button under Other and include 0 for this country. This country will not be included in the remodel plan.`,

  Paragraph3: `If there is a country that has closed sites and no additional sites or patients planned in this country, use the Actual/Projected radio button (with 0) so that the closed sites appear in the Site Activation Details.`,
};
