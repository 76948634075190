import React from "react";
import Typography from "@material-ui/core/Typography";
import moment from "moment";

const MilestoneItem = ({ milestoneLabel, comments, milestoneDateMillis }) => {
  return (
    <div
      style={{
        height: "auto",
        marginBottom: "10px",
      }}
    >
      <Typography>
        <strong>
          {milestoneDateMillis === null
            ? "DD/MM/YYYY"
            : moment(milestoneDateMillis).utc().format("DD/MMM/YYYY")}
        </strong>
        {` ${milestoneLabel}`}
      </Typography>
      {comments && (
        <Typography
          style={{ marginTop: "-.5rem", overflow: "auto", maxHeight: "100px" }}
        >
          <strong>Comments: </strong>
          {comments}
        </Typography>
      )}
    </div>
  );
};

export default MilestoneItem;
