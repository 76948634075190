import { Cell, Container, Row } from "..";

import { Accordion } from "../../../../../app/components";
import EditNotesInput from "../edit.notes.input";
import React from "react";

export default function EPriceInfoTable({ data }) {
  const [editState, setEditState] = React.useState({});

  const renderHeader = () => {
    return (
      <Row>
        <Cell
          variant="header"
          justify="flex-end"
          width="30%"
          style={{ borderBottom: "none" }}
          textAlign="right"
        />
        <Cell variant="header" width="10%">
          Visit Sampling SDR/SDV Time (reduced SDR/SDV)
        </Cell>
        <Cell variant="header" width="10%">
          100% SDR/SDV
        </Cell>
        <Cell variant="header" width="10%">
          Data Review (CDA): KRI Review and Study Level Reporting Only
        </Cell>
        <Cell variant="header" width="10%">
          SDR/SDV % Subject Visits
        </Cell>
        <Cell variant="header" width="30%">
          Notes
        </Cell>
      </Row>
    );
  };

  const renderRows = () => {
    return data.map((d, idx) => {
      return (
        <Row>
          <Cell
            variant="header"
            justify="flex-end"
            width="30%"
            style={{ borderBottom: "none" }}
            textAlign="right"
          >
            {d.rowLabel}
          </Cell>
          <Cell width="10%">{d.cellValue1}</Cell>
          <Cell width="10%">{d.cellValue2}</Cell>
          <Cell width="10%">{d.cellValue3}</Cell>
          <Cell width="10%">{d.cellValue4}</Cell>
          <Cell
            style={{ position: "relative" }}
            textAlign="left"
            justify="flex-start"
            width="30%"
          >
            <EditNotesInput
              d={d}
              editState={editState}
              setEditState={setEditState}
            />
          </Cell>
        </Row>
      );
    });
  };

  return (
    <Accordion scrollIntoView={true} width={"100%"} summary={"ePrice Info"}>
      <Container>
        {renderHeader()}
        {renderRows()}
      </Container>
    </Accordion>
  );
}
