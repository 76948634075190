import { useEffect, useRef, useState } from "react";

export default function useLocalStorage(key, initialValue = "") {
  const [value, setValues] = useState(() => {
    return localStorage.getItem(key)
      ? JSON.parse(localStorage.getItem(key))
      : typeof initialValue === "function"
      ? initialValue()
      : initialValue;
  });

  const ref = useRef(key);

  useEffect(() => {
    if (key !== ref.current) {
      localStorage.removeItem(ref.current);
      ref.current = key;
    }

    if (value) localStorage.setItem(key, JSON.stringify(value));
  }, [value, key]);

  return [value, setValues];
}
