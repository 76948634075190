import React, { useState } from "react";
import styled from "styled-components";

import ExportChartsModal from "./export.charts.modal";
import Button from "@material-ui/core/Button";

const ChartControls = ({ detailsView, type, downloadImage, setChartIds }) => {
  const [open, setOpen] = useState(false);

  return (
    <Container>
      <StyledButton
        variant="outlined"
        onClick={() => setOpen(true)}
      >
        Export Charts
      </StyledButton>

      <ExportChartsModal
        open={open}
        detailsView={detailsView}
        onClose={() => setOpen(false)}
        downloadImage={() => downloadImage()}
        setChartIds={setChartIds}
        type={type}
      />
    </Container>
  );
};

export default ChartControls;

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  height: fit-content;
  margin-top: 6px;
  margin-bottom: -2em;
`;

const StyledButton = styled(Button)`
  background-color: white;
`;
