import React, { useMemo, useState, useEffect } from "react";
import styled from "styled-components";
import { SpecialTextField, Tooltip } from "../../../../../../app/components";
import { useStudyPlan } from "../../../../../../app/contexts/study.context";
import Label from "../Label";

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

const ScenarioA = ({
  country,
  siteGroup,
  values,
  setValues,
  setHasErrors,
  setFieldErrors,
}) => {
  const { parameters, siteGroupId, milestones } = siteGroup;
  const { countryId } = country;
  const {
    expectedScreenFailureRate,
    expectedTreatmentDropoutRate,
    siteEnrollmentRateAtSIV,
    timeCountryStartUpSize,
    timeSiteIdSize,
  } = siteGroup;

  const { updateCountries } = useStudyPlan();
  const [sites_to_activate, subjects_to_screen] = parameters;
  const [
    number_patients_enrolled,
    number_patients_treatment,
    number_patients_follow_up,
  ] = milestones.filter(
    ({ milestoneId }) =>
      milestoneId === 144 || milestoneId === 147 || milestoneId === 150,
  );
  const [showPatientsScreenedError, setShowPatientsScreenedError] =
    useState(false);

  const isEmpty = (value) => value === "" || value == null;

  const isPatientsScreenedLowerEmpty = isEmpty(subjects_to_screen.lowerBound);
  const isPatientsScreenedUpperEmpty = isEmpty(subjects_to_screen.upperBound);

  const handleUpdateSiteGroup = (property, value) => {
    const update = { [property]: value };
    updateCountries(countryId, siteGroupId, update);
  };

  const handleUpdateSiteGroupParameters = (param, property, value) => {
    const index = siteGroup.parameters
      .map((param) => param.parameterName)
      .indexOf(param.parameterName);
    const updated_param = { ...param, [property]: value };
    const new_parameters = [...siteGroup.parameters];
    new_parameters[index] = updated_param;

    const update = { parameters: new_parameters };
    updateCountries(countryId, siteGroupId, update);
  };

  const handleUpdateSiteGroupMilestones = (milestone, property, value) => {
    const index = siteGroup.milestones
      .map((milestone) => milestone.milestoneName)
      .indexOf(milestone.milestoneName);
    const updated_milestone = { ...milestone, [property]: value };
    const new_milestones = [...siteGroup.milestones];
    new_milestones[index] = updated_milestone;

    const update = { milestones: new_milestones };
    updateCountries(countryId, siteGroupId, update);
  };

  const customValidationDefinition = useMemo(
    () => ({
      enrollmentRate: {
        range: [0, Infinity],
        validateEmpty: false,
        checkIfInt: false,
        checkIfDec: false,
      },
      numberofactivatedsites: {
        range: [1, Infinity],
        validateEmpty: true,
        checkIfInt: true,
        checkIfDec: false,
      },
      numberofpatientsscreened: {
        range: [number_patients_follow_up.lowerBound || 1, Infinity],
        validateEmpty: false,
        checkIfInt: true,
        checkIfDec: false,
      },
      "screenfailrate(indecimals)": {
        range: [0.0, 0.99],
        validateEmpty: false,
        checkIfInt: false,
        checkIfDec: true,
      },
      "dropoutrate(indecimals)": {
        range: [0.0, 0.99],
        validateEmpty: false,
        checkIfInt: false,
        checkIfDec: true,
      },
      numbrofpatientscompletingfollowup: {
        range: [1, subjects_to_screen.lowerBound],
        validateEmpty: false,
        checkIfInt: true,
        checkIfDec: false,
      },
      pauseEnrollment: {
        range: [1, 9999],
        validateEmpty: false,
        checkIfInt: true,
        checkIfDec: false,
      },
    }),
    [
      subjects_to_screen.lowerBound,
      number_patients_enrolled.lowerBound,
      number_patients_follow_up.lowerBound,
    ],
  );

  useEffect(() => {
    const numberPatientsFollowUp = number_patients_follow_up.lowerBound;

    const isEmpty =
      numberPatientsFollowUp === "" || numberPatientsFollowUp == null;

    setShowPatientsScreenedError(!isEmpty);

    setFieldErrors((prevState) => {
      const { numberPatientsFollowUpError, ...rest } = prevState;

      if (isEmpty) {
        return {
          ...rest,
          numberPatientsFollowUpError: {
            ...numberPatientsFollowUpError,
            errors: ["Can't be empty."],
          },
        };
      }

      return rest;
    });
  }, [number_patients_follow_up.lowerBound, setFieldErrors]);

  useEffect(() => {
    const newErrors =
      showPatientsScreenedError &&
      (isPatientsScreenedLowerEmpty || isPatientsScreenedUpperEmpty)
        ? ["Can't be empty."]
        : [];

    setHasErrors((prevErrors) => {
      if (newErrors.length === 0) {
        return prevErrors;
      }
      return newErrors;
    });
  }, [
    showPatientsScreenedError,
    isPatientsScreenedLowerEmpty,
    isPatientsScreenedUpperEmpty,
  ]);

  return (
    <Container>
      <SideBySideRows>
        <Label
          value={timeSiteIdSize ?? "- - "}
          variant="secondary"
        >
          Site ID Effort {timeSiteIdSize && " - "}
        </Label>
        <Label
          value={timeCountryStartUpSize ?? "- - "}
          variant="secondary"
        >
          Country Start-Up Time Frame {timeCountryStartUpSize && " - "}
        </Label>
      </SideBySideRows>
      <SideBySideCols>
        <SpecialTextField
          setHasErrors={setHasErrors}
          showArrows={false}
          label="Enrollment Rate (p/s/m)"
          value={siteEnrollmentRateAtSIV}
          onChange={(value) =>
            handleUpdateSiteGroup("siteEnrollmentRateAtSIV", value)
          }
          endadornment={
            <Tooltip info="Complete with country specific enrollment rates (if you expect an overall rate that is the same for all countries, you can complete this at Overall Parameters and skip here)." />
          }
          customValidation={customValidationDefinition["enrollmentRate"]}
        />
        <div style={{ display: "flex", gap: "1em" }}>
          <SpecialTextField
            setHasErrors={setHasErrors}
            showArrows={false}
            altlabel="Lower Bound"
            value={sites_to_activate.lowerBound}
            onChange={(value) => {
              handleUpdateSiteGroupParameters(
                sites_to_activate,
                "lowerBound",
                value,
              );
              // trigger formic form update so that we can validate fields
              setValues({ ...values, updated: new Date().getTime() });
            }}
            key={sites_to_activate.parameterName + 1}
            required
            customValidation={
              customValidationDefinition["numberofactivatedsites"]
            }
          />
          <SpecialTextField
            setHasErrors={setHasErrors}
            showArrows={false}
            label={toTitleCase(sites_to_activate.parameterDescr)}
            altlabel="Upper Bound"
            value={sites_to_activate.upperBound}
            onChange={(value) =>
              handleUpdateSiteGroupParameters(
                sites_to_activate,
                "upperBound",
                value,
              )
            }
            key={sites_to_activate.parameterName + 2}
            required
            endadornment={
              <Tooltip info="Complete the range of sites targeted for each country; ensure the total number of sites across all countries will allow for scenarios that match the total number of sites entered in Overall Parameters; the modeling will return the quickest scenario to last patient enrolled." />
            }
            customValidation={
              customValidationDefinition["numberofactivatedsites"]
            }
          />
        </div>
        <EmptyRow />
        <div style={{ display: "flex", gap: "1em" }}>
          <SpecialTextField
            setHasErrors={setHasErrors}
            showCustomError={
              showPatientsScreenedError && isPatientsScreenedLowerEmpty
            }
            customMessageError={"Can't be empty."}
            showArrows={false}
            altlabel="Lower Bound"
            value={subjects_to_screen.lowerBound}
            onChange={(value) =>
              handleUpdateSiteGroupParameters(
                subjects_to_screen,
                "lowerBound",
                value,
              )
            }
            required={
              number_patients_follow_up.lowerBound !== "" &&
              number_patients_follow_up.lowerBound !== null &&
              number_patients_follow_up.lowerBound !== undefined
            }
            key={subjects_to_screen.parameterName + 1}
            customValidation={
              customValidationDefinition["numberofpatientsscreened"]
            }
          />
          <SpecialTextField
            setHasErrors={setHasErrors}
            showArrows={false}
            showCustomError={
              showPatientsScreenedError && isPatientsScreenedUpperEmpty
            }
            customMessageError={"Can't be empty."}
            label={toTitleCase(subjects_to_screen.parameterDescr)}
            altlabel="Upper Bound"
            value={subjects_to_screen.upperBound}
            onChange={(value) =>
              handleUpdateSiteGroupParameters(
                subjects_to_screen,
                "upperBound",
                value,
              )
            }
            required={
              number_patients_follow_up.lowerBound != "" &&
              number_patients_follow_up.lowerBound != null &&
              number_patients_follow_up.lowerBound != undefined
            }
            key={subjects_to_screen.parameterName + 2}
            endadornment={
              <Tooltip info="These fields are required if a specific rate (screen fail or dropout) is needed in this country." />
            }
            customValidation={
              customValidationDefinition["numberofpatientsscreened"]
            }
          />
        </div>
      </SideBySideCols>
      <SideBySideRows>
        <SpecialTextField
          setHasErrors={setHasErrors}
          showArrows={false}
          label="Screen Fail Rate (in decimals)"
          value={expectedScreenFailureRate}
          onChange={(value) =>
            handleUpdateSiteGroup("expectedScreenFailureRate", value)
          }
          inputProps={{ step: 0.01 }}
          min={0.01}
          disabled={typeof number_patients_enrolled.lowerBound === "number"}
          endadornment={
            <Tooltip info="Complete if country specific screen fail rates are expected." />
          }
          customValidation={
            customValidationDefinition["screenfailrate(indecimals)"]
          }
        />
        <SpecialTextField
          setHasErrors={setHasErrors}
          showArrows={false}
          label="Drop Out Rate (in decimals)"
          value={expectedTreatmentDropoutRate}
          onChange={(value) =>
            handleUpdateSiteGroup("expectedTreatmentDropoutRate", value)
          }
          inputProps={{ step: 0.01 }}
          min={0.01}
          disabled={typeof number_patients_treatment.lowerBound === "number"}
          endadornment={
            <Tooltip info="Complete if country specific drop out rates are expected." />
          }
          customValidation={
            customValidationDefinition["dropoutrate(indecimals)"]
          }
        />
      </SideBySideRows>
      <SideBySideRows>
        <SpecialTextField
          setHasErrors={setHasErrors}
          showArrows={false}
          label={number_patients_follow_up.milestoneDescr}
          value={number_patients_follow_up.lowerBound}
          onChange={(value) =>
            handleUpdateSiteGroupMilestones(
              number_patients_follow_up,
              "lowerBound",
              value,
            )
          }
          key={number_patients_follow_up.milestoneName}
          endadornment={
            <Tooltip info="Only complete this field if it is applicable to the protocol and a specific number of patients are needed in this country. To use this field, you must also complete the other fields in this section." />
          }
          customValidation={
            customValidationDefinition["numbrofpatientscompletingfollowup"]
          }
        />
      </SideBySideRows>
    </Container>
  );
};

export default ScenarioA;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;
`;

const SideBySideRows = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0;
`;

const SideBySideCols = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-gap: 1em;
  grid-auto-flow: column;
`;

const EmptyRow = styled.div`
  height: 56px;
  width: 100%;
`;
