import { FormControlLabel, Radio, TextField } from "@material-ui/core";
import React, { useState } from "react";
import styled from "styled-components";
import CustomDatePicker from "../custom.date.picker";

export default function SpecialRadioButton({
  value,
  onChange,
  currentRadioValue,
  type = "text",
  error,
}) {
  let [dynamicValue, setDynamicValue] = useState(value);

  return (
    <Container>
      <FormControlLabel
        value={currentRadioValue}
        control={<Radio color="primary" />}
      />
      {type === "date" ? (
        <CustomDatePicker
          label="Date"
          onChange={(value) => {
            onChange(value.getTime());
            setDynamicValue(value.getTime());
          }}
          showYearDropdown
          showMonthDropdown
          useShortMonthInDropdown
          error={error}
          dateFormat={"dd/MMM/yyyy"}
        />
      ) : (
        <TextField
          type={type}
          style={{ width: "5rem" }}
          variant="outlined"
          value={dynamicValue}
          onChange={(e) => {
            onChange(e.target.value);
            setDynamicValue(e.target.value);
          }}
          error={error}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
`;
