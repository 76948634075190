import moment from "moment";
import { isNumberNotEmpty } from "../../utils/helpers";

const getPrevFpsStartDate = (
  newStudyPlanValues,
  predecessorCohortName,
  predecessorActivity,
) => {
  const prevCohort = newStudyPlanValues.filter(
    (nsv) => nsv.cohortName && nsv.cohortName === predecessorCohortName,
  );
  const activityEndKey =
    predecessorActivity === "Screening"
      ? "screeningEndDate"
      : predecessorActivity === "Enrolling"
      ? "enrollingEndDate"
      : predecessorActivity === "DTL"
      ? "dtlEndDate"
      : predecessorActivity === "SRC"
      ? "srcEndDate"
      : "";
  if (!prevCohort.length || !activityEndKey || !prevCohort[0][activityEndKey])
    return null;
  return prevCohort[0][activityEndKey];
};

export const updateCalculatedFields = (
  newStudyPlanValues,
  fpsStartDate,
  croStartDate,
) => {
  const lNewStudyPlanValues = [...newStudyPlanValues];
  return newStudyPlanValues.map((npv, index) => {
    const lFpsStartDate =
      index === 0
        ? fpsStartDate
        : getPrevFpsStartDate(
            lNewStudyPlanValues,
            lNewStudyPlanValues[index].predecessorCohortName,
            lNewStudyPlanValues[index].predecessorActivity,
          );
    if (!lFpsStartDate) return npv;
    const cohortStartDate =
      !isNaN(npv.lagDays) && lFpsStartDate
        ? moment
            .utc(lFpsStartDate, "D/MMM/YY")
            .add("days", npv.lagDays)
            .format("D-MMM-YY")
        : "";
    const cohortStartDay =
      cohortStartDate && croStartDate
        ? Math.abs(
            moment
              .utc(cohortStartDate, "D-MMM-YY")
              .diff(moment.utc(croStartDate, "D/MMM/YY"), "days"),
          )
        : "";

    const screeningEndDate =
      cohortStartDate && isNumberNotEmpty(npv.screeningDurationDays)
        ? moment
            .utc(cohortStartDate, "D-MMM-YY")
            .add("days", npv.screeningDurationDays)
            .format("D-MMM-YY")
        : "";
    const screeningStudyDay =
      screeningEndDate && croStartDate
        ? Math.abs(
            moment
              .utc(screeningEndDate, "D-MMM-YY")
              .diff(moment.utc(croStartDate, "D/MMM/YY"), "days"),
          )
        : "";
    const enrollingEndDate =
      screeningEndDate && isNumberNotEmpty(npv.enrollingDurationDays)
        ? moment
            .utc(screeningEndDate, "D-MMM-YY")
            .add("days", npv.enrollingDurationDays)
            .format("D-MMM-YY")
        : "";
    const enrollingEndDay =
      enrollingEndDate && croStartDate
        ? Math.abs(
            moment
              .utc(enrollingEndDate, "D-MMM-YY")
              .diff(moment.utc(croStartDate, "D/MMM/YY"), "days"),
          )
        : "";
    const dtlEndDate =
      enrollingEndDate && isNumberNotEmpty(npv.dtlDurationDays)
        ? moment
            .utc(enrollingEndDate, "D-MMM-YY")
            .add("days", npv.dtlDurationDays)
            .format("D-MMM-YY")
        : "";
    const dtlEndDay =
      dtlEndDate && croStartDate
        ? Math.abs(
            moment
              .utc(dtlEndDate)
              .diff(moment.utc(croStartDate, "D/MMM/YY"), "days"),
          )
        : "";
    const srcEndDate =
      dtlEndDate && isNumberNotEmpty(npv.srcDurationDays)
        ? moment
            .utc(dtlEndDate, "D-MMM-YY")
            .add("days", npv.srcDurationDays)
            .format("D-MMM-YY")
        : "";
    const srcEndDay =
      srcEndDate && croStartDate
        ? Math.abs(
            moment
              .utc(srcEndDate)
              .diff(moment.utc(croStartDate, "D/MMM/YY"), "days"),
          )
        : "";
    const newNpv = {
      ...npv,
      cohortStartDate: cohortStartDate,
      cohortStartDay: cohortStartDay,
      screeningEndDate: screeningEndDate,
      screeningEndDay: screeningStudyDay,
      enrollingEndDate: enrollingEndDate,
      enrollingEndDay: enrollingEndDay,
      dtlEndDate: dtlEndDate,
      dtlEndDay: dtlEndDay,
      srcEndDate: srcEndDate,
      srcEndDay: srcEndDay,
    };
    lNewStudyPlanValues[index] = newNpv;
    return newNpv;
  });
};
