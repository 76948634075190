import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import MuiTableHead from "@material-ui/core/TableHead";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import { headCells } from "./constants";
/*
  This component is responsible for rendering the table head of the schedule of events table.
  It uses the material ui table head component.
  It uses the material ui table cell component.
  It uses the material ui table sort label component.
  It uses the material ui table row component.
  The parent component must pass a callback function to handle the sorting of the table.
  The parent component must pass the order of the table.
  The parent component must pass the order by of the table.
*/
const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: "5px",

    "&:first-child": {
      paddingLeft: "10px",
    },
  },
}))(TableCell);

const TableHead = ({
  filterActive,
  setFilterActive,
  orderBy,
  order,
  onRequestSort,
}) => {
  const classes = useStyles();
  const otherClasses = useOtherStyles();
  const handleSort = (property) => (event) => onRequestSort(event, property);

  return (
    <MuiTableHead classes={classes}>
      <TableRow classes={classes}>
        <StyledTableCell>
          <IconButton onClick={() => setFilterActive(!filterActive)}>
            <FilterListIcon fontSize="small" />
          </IconButton>
        </StyledTableCell>
        {headCells.map(({ id, label, minWidth }) => (
          <StyledTableCell
            key={id}
            style={{ minWidth }}
            align="left"
            padding="default"
            sortDirection={orderBy === id ? order : false}
          >
            <TableSortLabel
              active={orderBy === id}
              direction={orderBy === id ? order : "asc"}
              onClick={handleSort(id)}
            >
              <strong>{label}</strong>
              {orderBy === id && (
                <span className={otherClasses.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              )}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  );
};

export default TableHead;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#e6e7e8",
    borderRadius: "10px",
  },
}));

const useOtherStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));
