import React from "react";
import { Title } from "../../../../app/components";
import TitleLabel from "./title.label";
import moment from "moment";
import styled from "styled-components";
import { useStudyPlan } from "../../../../app/contexts/study.context";
import ScenarioA from "../../review/overall.study.inputs/scenario.a";
import ScenarioB from "../../review/overall.study.inputs/scenario.b";

const SummaryOfOverallParameters = () => {
  const { studyPlan, STUDY_PLAN_TYPES } = useStudyPlan();

  const { studyPlanType } = studyPlan;
  const { SCENARIO_A, SCENARIO_B } = STUDY_PLAN_TYPES;
  let scenarioRender;
  if (studyPlanType === SCENARIO_A) {
    scenarioRender = <ScenarioA />;
  }

  if (studyPlanType === SCENARIO_B) {
    scenarioRender = <ScenarioB />;
  }
  const {
    croProjectStartDateMillis,
    finalProtocolStartDateMillis,
    studyPlanName,
    studyPlanDescr,
    scenarioStatus,
    actualAsOfDate,
    studyPlanTypeDetail,
  } = studyPlan;

  const startDate = moment(croProjectStartDateMillis)
    .utc()
    .format("DD/MMM/YYYY");

  const protocolDate = moment(finalProtocolStartDateMillis)
    .utc()
    .format("DD/MMM/YYYY");

  const renderStatusAndActualAsOfDate = () => {
    if (["Remodel (D)", "Remodel (R)"].includes(studyPlanTypeDetail)) {
      return (
        <TitleLabel
          label="Status"
          value={`${scenarioStatus} (Actuals as of: ${
            moment(actualAsOfDate).isValid()
              ? moment(actualAsOfDate).format("DD/MMM/YYYY")
              : ""
          })`}
        />
      );
    } else {
      return (
        <TitleLabel
          label="Status"
          value={scenarioStatus}
          color="black"
        />
      );
    }
  };
  return (
    <Container>
      <StyledDetailsTable>
        <div style={{paddingBottom: "0px" }}>
          <Title>Study Details</Title>
        </div>
        <Content>
          <InerContent>
            <div>
              <TitleLabel
                label="Study Plan Name"
                value={studyPlanName}
                color="black"
              />
              <TitleLabel
                label="Description"
                value={studyPlanDescr}
                color="black"
              />
            </div>
            <div>
              <TitleLabel
                label="Type"
                value={studyPlanTypeDetail}
                color="black"
              />
              {renderStatusAndActualAsOfDate()}
            </div>
            <div>
              <TitleLabel
                label="CRO Start Date"
                value={startDate}
                color="black"
              />
              <TitleLabel
                label="Protocol Date"
                value={protocolDate}
                color="black"
              />
            </div>
          </InerContent>
        </Content>
      </StyledDetailsTable>
      <StyledDetailsTableBottom>
        <div style={{paddingBottom: "0px" }}>
          <Title>Summary of the Overall Parameters</Title>
        </div>
        <Content>{scenarioRender}</Content>
      </StyledDetailsTableBottom>
    </Container>
  );
};

export default SummaryOfOverallParameters;

const Content = styled.div`
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-gap: 0em;
`;
const InerContent = styled.div`
  display: flex;
  width: 100%;

  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`;
const StyledDetailsTable = styled.div`
  border-top: 0.1px solid rgba(224, 224, 224, 0.25);
  border-left: 0.1px solid rgba(224, 224, 224, 0.5);
  border-right: 0.1px solid rgba(224, 224, 224, 0.5);
  border-bottom: 2px solid rgba(224, 224, 224, 1);
  overflow-x: auto;
  width: 100%;
  padding: 20px 20px 0;
`;

const StyledDetailsTableBottom = styled.div`
  border-top: 0.1px solid rgba(224, 224, 224, 0.25);
  border-left: 0.1px solid rgba(224, 224, 224, 0.5);
  border-right: 0.1px solid rgba(224, 224, 224, 0.5);
  border-bottom: 2px solid rgba(224, 224, 224, 1);
  overflow-x: auto;
  width: 100%;
  padding: 20px 20px 10px;
`;