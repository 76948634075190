import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import CustomDatePicker from "../../../../app/components/custom.date.picker";
import { formatDate } from "../../../../app/helpers/utils";
import styled from "styled-components";

const VISIT_INTERVAL_TYPE_OPTIONS = ["Hour", "Day", "Week", "Month", "Year"];

export const NewScheduleForm = ({
  SOEEventParameters,
  handleChange,
  errors,
  classes,
}) => {
  return (
    <SubContainer>
      <TextField
        style={{ width: "100%" }}
        value={SOEEventParameters.scheduleOfEventsName}
        onChange={(e) => handleChange("scheduleOfEventsName", e.target.value)}
        variant="outlined"
        label="SOE Name"
        InputLabelProps={{ classes }}
        required
        error={!!errors.scheduleOfEventsName}
        inputProps={{
          maxLength: 50,
        }}
        helperText={
          <AlignBetween>
            <span>{errors.scheduleOfEventsName}</span>
            <span>{`${
              (SOEEventParameters.scheduleOfEventsName || "").length
            } / ${50}`}</span>
          </AlignBetween>
        }
      />
      <TextField
        style={{ width: "100%" }}
        value={SOEEventParameters.scheduleOfEventsDescr}
        error={errors.scheduleOfEventsDescr}
        onChange={(e) => handleChange("scheduleOfEventsDescr", e.target.value)}
        variant="outlined"
        label="SOE Description"
        InputLabelProps={{ classes }}
        required
        multiline
        inputProps={{
          maxLength: 2000,
        }}
        rows={4}
        helperText={
          <AlignBetween>
            <span>{errors.scheduleOfEventsDescr}</span>
            <span>{`${
              (SOEEventParameters.scheduleOfEventsDescr || "").length
            } / ${2000}`}</span>
          </AlignBetween>
        }
      />
      <TextField
        style={{ width: "100%" }}
        value={SOEEventParameters.protocolVersion}
        onChange={(e) => handleChange("protocolVersion", e.target.value)}
        variant="outlined"
        label="Protocol Version"
        InputLabelProps={{ classes }}
        required
        error={!!errors.protocolVersion}
        inputProps={{
          maxLength: 25,
        }}
        helperText={
          <AlignBetween>
            <span>{errors.protocolVersion}</span>
            <span>{`${
              (SOEEventParameters.protocolVersion || "").length
            } / ${25}`}</span>
          </AlignBetween>
        }
      />
      <CustomDatePicker
        label="Protocol Date"
        onChange={(value) => handleChange("protocolDate", formatDate(value))}
        showYearDropdown
        showMonthDropdown
        useShortMonthInDropdown
        required={true}
        error={errors.protocolDate}
        dateFormat={"dd/MMM/yyyy"}
      />
      <FormControl
        error={errors.visitIntervalType}
        required
        style={{ width: "100%" }}
        variant="outlined"
      >
        <InputLabel id="visit-interval-type-label-id">
          Visit Interval Type
        </InputLabel>
        <Select
          labelId="visit-interval-type-label-id"
          id="visit-interval-type-id"
          value={SOEEventParameters.visitIntervalType}
          onChange={(event) =>
            handleChange("visitIntervalType", event.target.value)
          }
          label="Visit Interval Type"
        >
          {VISIT_INTERVAL_TYPE_OPTIONS?.map((type) => (
            <MenuItem value={type}>{type}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl
        error={errors.visitWindowIntervalType}
        required
        style={{ width: "100%" }}
        variant="outlined"
      >
        <InputLabel id="visit-window-interval-type-label-id">
          Visit Window Interval Type
        </InputLabel>
        <Select
          labelId="visit-window-interval-type-label-id"
          id="visit-window-interval-type-id"
          value={SOEEventParameters.visitWindowIntervalType}
          onChange={(event) =>
            handleChange("visitWindowIntervalType", event.target.value)
          }
          label="Visit Window Interval Type"
        >
          {VISIT_INTERVAL_TYPE_OPTIONS?.map((type) => (
            <MenuItem value={type}>{type}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </SubContainer>
  );
};

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const AlignBetween = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
