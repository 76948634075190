import React from "react";
import { Route } from "react-router-dom";

export default function ScheduleEditRoute({
  component: Component,
  additionalProps = {},
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) => <Component {...props} {...additionalProps} />}
    />
  );
}
