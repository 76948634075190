import { TextField } from "@material-ui/core";
import React from "react";
import TransferList from "../../../../app/components/transfer.list";
import styled from "styled-components";

export const ScenarioC = ({
  transferListPlans,
  setSelectedStudyPlans,
  cohortPlanParameters,
  classes,
  errors,
  handleChange,
}) => {
  return (
    <>
      <TransferList
        items={transferListPlans}
        setSelected={(selected) => setSelectedStudyPlans(selected)}
      />
      <div>
        <TextField
          style={{ width: "100%" }}
          value={cohortPlanParameters.studyPlanName}
          onChange={(e) => handleChange("studyPlanName", e.target.value)}
          variant="outlined"
          label="Study Plan Name"
          InputLabelProps={{ classes }}
          required
          error={!!errors.studyPlanName}
          inputProps={{
            maxLength: 50,
          }}
          helperText={
            <AlignBetween>
              <span>{errors.studyPlanName}</span>
              <span>{`${
                (cohortPlanParameters.studyPlanName || "").length
              } / ${50}`}</span>
            </AlignBetween>
          }
        />
        <TextField
          style={{ width: "100%" }}
          value={cohortPlanParameters.studyPlanDescr}
          error={errors.studyPlanDescr}
          onChange={(e) => handleChange("studyPlanDescr", e.target.value)}
          variant="outlined"
          label="Description"
          InputLabelProps={{ classes }}
          required
        />
      </div>
    </>
  );
};

const AlignBetween = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
