import { ClickAwayListener, MenuList } from "@material-ui/core";

import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const OptionsMenu = ({
  anchor,
  open,
  setEditOpen,
  setCloneOpen,
  setDeleteOpen,
  onClose,
  scheduleOfEventsId,
}) => {
  const classes = useStyles();

  const handleEditSOE = () => {
    setEditOpen(true);
    onClose();
  };
  const handleCloneSOE = () => {
    setCloneOpen(true);
    onClose();
  };

  const handleDeleteSOE = () => {
    setDeleteOpen(true);
    onClose();
  };

  return (
    <Popper open={open} anchorEl={anchor} placement="bottom-end">
      <Paper classes={classes}>
        <ClickAwayListener onClickAway={onClose}>
          <MenuList
            autoFocusItem={open}
            id="composition-menu"
            aria-labelledby="composition-button"
          >
            <MenuItem onClick={handleEditSOE}>Edit SOE</MenuItem>
            <MenuItem onClick={handleCloneSOE}>Clone SOE</MenuItem>
            <MenuItem onClick={handleDeleteSOE}>Delete SOE</MenuItem>
          </MenuList>
        </ClickAwayListener>
      </Paper>
    </Popper>
  );
};

export default OptionsMenu;

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#e6e7e8",
    padding: "0",
  },
}));
