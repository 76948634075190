import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import Button from "@material-ui/core/Button";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CircularProgress from "@material-ui/core/CircularProgress";
import UnsavedChangesModal from "../../../common/unsaved.changes.modal";
import styled from "styled-components";
import { useSnackbar } from "../../../../app/contexts/snackbar.context";
import { useStudyPlan } from "../../../../app/contexts/study.context";

const Controls = ({ errors, hasErrors = [], fieldErrors = {} }) => {
  const history = useHistory();
  const { id, planId } = useParams();
  const [open, setOpen] = useState(false);
  const { showSnackbar } = useSnackbar();
  const {
    studyPlan,
    savePlan,
    unsavedChange,
    saveLoading,
    validateSequentialCohort,
    validateSeasonalVariations,
    isSequential,
    hideEnrollmentDuration,
    clearStudyPlan,
  } = useStudyPlan();
  const {
    studyPlanName,
    croProjectStartDateMillis,
    studyPlanDescr,
    finalProtocolStartDateMillis,
    treatmentDuration,
    screeningPeriodDuration,
    overallEnrollmentDuration,
  } = studyPlan;
  const [sites_to_activate, subjects_to_screen] = studyPlan?.parameters;

  let milestones = [];
  if (
    studyPlan.countries[0] &&
    studyPlan.countries[0].siteGroups &&
    studyPlan.countries[0].siteGroups[0]
  ) {
    milestones = studyPlan.countries[0].siteGroups[0].milestones;
  }

  const isOverrideAndEmptyComment = milestones.some(
    (milestone) => milestone.isUserOverride && milestone.comments === "",
  );
  const handleBackToList = () => {
    if (unsavedChange) {
      setOpen(true);
    } else {
      history.push(`/ctpo/study-plans/${id}`);
    }
  };

  const handleCancel = () => {
    if (unsavedChange) {
      setOpen(true);
    } else {
      clearStudyPlan();
      history.push(`/ctpo/study-plans/${id}`);
    }
  };

  const handleSave = () => {
    const seqValid = isSequential
      ? validateSequentialCohort(history.location.pathname)
      : true;
    const seasonalValid = validateSeasonalVariations();
    if (
      (Object.keys(errors || {}).length !== 0 &&
        ["ALL"].includes(errors.BUTTON_TYPE)) ||
      !seqValid ||
      !seasonalValid
    ) {
      showSnackbar(
        <ul>
          {Object.keys(errors || {}).map((key) => {
            if (key === "ENROLLMENT_DURATION") return "";
            if (key !== "BUTTON_TYPE") return <li>{errors[key]}</li>;
            return "";
          })}
          {!seqValid && <li>Sequential cohorts setup is not valid</li>}
          {!seasonalValid && (
            <li>Country enrollment seasonal variations are not valid</li>
          )}
        </ul>,
        "error",
      );
      return;
    }
    const isNew = planId.includes("new-plan_") ? true : false;
    if (isNew) {
      savePlan({ isNew, redirect });
    } else {
      if (unsavedChange) savePlan({ isNew: false, undefined });
    }
  };

  const redirect = (lPlanId) => {
    history.push(`/ctpo/study-plans/${id}/${lPlanId}`);
  };

  const handleDisabled = () => {
    const mustValidateFields = ["studyPlanName", "studyPlanDescr"];
    for (let i = 0; i < mustValidateFields.length; i++) {
      if (errors[mustValidateFields[i]]) {
        return true;
      }
    }
    const hasErrors = Object.values(fieldErrors).some(
      (field) => field.errors && field.errors.length > 0,
    );
    return (
      hasErrors ||
      !studyPlanName ||
      !croProjectStartDateMillis ||
      !studyPlanDescr ||
      !finalProtocolStartDateMillis ||
      sites_to_activate.lowerBound === null ||
      !(sites_to_activate.lowerBound > 0) ||
      subjects_to_screen.lowerBound === null ||
      !(subjects_to_screen.lowerBound > 0) ||
      treatmentDuration === null ||
      !(treatmentDuration > 0) ||
      screeningPeriodDuration === null ||
      !(screeningPeriodDuration >= 0) ||
      (!hideEnrollmentDuration &&
        (overallEnrollmentDuration === null ||
          !(overallEnrollmentDuration >= 0)))
    );
  };

  return (
    <Container>
      <Button
        color="primary"
        onClick={handleBackToList}
        disabled={saveLoading}
      >
        <ChevronLeftIcon />
        Back to Study Plan List
      </Button>

      <ButtonContainer>
        <Button
          variant="outlined"
          onClick={handleCancel}
          disabled={saveLoading}
        >
          Cancel
        </Button>
        <Button
          disabled={
            handleDisabled() ||
            saveLoading ||
            isOverrideAndEmptyComment ||
            hasErrors.length > 0
          }
          variant="contained"
          color="primary"
          onClick={handleSave}
        >
          {saveLoading ? <CircularProgress size="1em" /> : "Save Draft"}
        </Button>
      </ButtonContainer>
      <UnsavedChangesModal
        open={open}
        onToggle={(prev) => setOpen(!prev)}
      />
    </Container>
  );
};

export default Controls;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-gap: 1em;
`;
