import React from "react";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import { useStudyPlan } from "../../../../app/contexts/study.context";

export default function TableHeader() {
  const { seqCohortColumnHeaders } = useStudyPlan();
  return (
    <>
      <Row>
        <Space width={`${(8 / 70) * 100}%`} />
        <Space width={`${(8 / 70) * 100}%`} />
        <Space width={`${(6 / 70) * 100}%`} />
        <Space width={`${(3 / 70) * 100}%`} />
        <Space width={`${(3 / 70) * 100}%`} />
        <Cell width={`${(6 / 70) * 100}%`} leftBorder variant="header">
          <Typography variant="body2">
            <strong>Cohort Start</strong>
          </Typography>
        </Cell>
        <Cell width={`${(9 / 70) * 100}%`} variant="header">
          <Typography variant="body2">
            <strong>Screening</strong>
          </Typography>
        </Cell>
        <Cell width={`${(9 / 70) * 100}%`} variant="header">
          <Typography variant="body2">
            <strong>Enrolling</strong>
          </Typography>
        </Cell>
        <Cell width={`${(9 / 70) * 100}%`} variant="header">
          <Typography variant="body2">
            <strong>{seqCohortColumnHeaders.columnHeader3}</strong>
          </Typography>
        </Cell>
        <Cell width={`${(9 / 70) * 100}%`} variant="header">
          <Typography variant="body2">
            <strong>{seqCohortColumnHeaders.columnHeader4}</strong>
          </Typography>
        </Cell>
      </Row>
      <Row>
        <Cell width={`${(8 / 70) * 100}%`} variant="header">
          <Typography variant="body2">
            <strong>Cohort Name</strong>
          </Typography>
        </Cell>
        <Cell width={`${(8 / 70) * 100}%`} variant="header">
          <Typography variant="body2">
            <strong>Predecessor Cohort</strong>
          </Typography>
        </Cell>
        <Cell width={`${(6 / 70) * 100}%`} variant="header">
          <Typography variant="body2">
            <strong>Predecessor Activity</strong>
          </Typography>{" "}
        </Cell>
        <Cell width={`${(3 / 70) * 100}%`} variant="header">
          <Typography variant="body2">
            <strong>Enrolled Patients</strong>
          </Typography>
        </Cell>
        <Cell width={`${(3 / 70) * 100}%`} variant="header">
          <Typography variant="body2">
            <strong>Lag</strong>
          </Typography>
        </Cell>
        <Cell width={`${(6 / 70) * 100}%`} variant="header">
          <Typography variant="body2">
            <strong>Start Date</strong>
          </Typography>
        </Cell>
        {/* <Cell width={`${(2 / 70) * 100}%`} variant="header">
          <Typography variant="body2">
            <strong>Study Day</strong>
          </Typography>
        </Cell> */}
        <Cell width={`${(4 / 70) * 100}%`} variant="header">
          <Typography variant="body2">
            <strong>Duration</strong>
          </Typography>
        </Cell>
        <Cell width={`${(5 / 70) * 100}%`} variant="header">
          <Typography variant="body2">
            <strong>End Date</strong>
          </Typography>
        </Cell>
        {/* <Cell width={`${(2 / 70) * 100}%`} variant="header">
          <Typography variant="body2">
            <strong>Study Day</strong>
          </Typography>
        </Cell> */}
        <Cell width={`${(4 / 70) * 100}%`} variant="header">
          <Typography variant="body2">
            <strong>Duration</strong>
          </Typography>
        </Cell>
        <Cell width={`${(5 / 70) * 100}%`} variant="header">
          <Typography variant="body2">
            <strong>End Date</strong>
          </Typography>
        </Cell>
        {/* <Cell width={`${(2 / 70) * 100}%`} variant="header">
          <Typography variant="body2">
            <strong>Study Day</strong>
          </Typography>
        </Cell> */}
        <Cell width={`${(4 / 70) * 100}%`} variant="header">
          <Typography variant="body2">
            <strong>Duration</strong>
          </Typography>
        </Cell>
        <Cell width={`${(5 / 70) * 100}%`} variant="header">
          <Typography variant="body2">
            <strong>End Date</strong>
          </Typography>
        </Cell>
        {/* <Cell width={`${(2 / 70) * 100}%`} variant="header">
          <Typography variant="body2">
            <strong>Study Day</strong>
          </Typography>
        </Cell> */}
        <Cell width={`${(4 / 70) * 100}%`} variant="header">
          <Typography variant="body2">
            <strong>Duration</strong>
          </Typography>
        </Cell>
        <Cell width={`${(5 / 70) * 100}%`} variant="header">
          <Typography variant="body2">
            <strong>End Date</strong>
          </Typography>
        </Cell>
        {/* <Cell width={`${(2 / 70) * 100}%`} variant="header">
          <Typography variant="body2">
            <strong>Study Day</strong>
          </Typography>
        </Cell> */}
      </Row>
    </>
  );
}

const Row = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  height: 50px;
  border-bottom: ${(p) => (p.noBorder ? "none" : "1px solid #ccc")};
  &:last-child {
    border: none;
  }
`;

const Cell = styled.div`
  background: ${(p) => (p.variant === "header" ? "#f3f4f4" : "white")};
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  width: ${(p) => (p.width ? p.width : "100%")};
  border-right: 1px solid #ccc;
  padding: 5px;
  text-align: center;
  border-left: ${(p) => (p.leftBorder ? "1px solid #ccc" : "none")};
  &:last-child {
    border: ${(p) => (p.rightBorder ? "1px solid #ccc" : "none")};
  }
  border: ${(p) => (p.fullBorder ? "1px solid #ccc" : "")};
`;

const Space = styled.div`
  height: 100%;
  width: ${(p) => (p.width ? p.width : "100%")};
  border-right: 1px solid transparent;
  padding: 5px;
  text-align: center;
  border-left: ${(p) => (p.leftBorder ? "1px solid transparent" : "none")};
  &:last-child {
    border: none;
  }
`;
