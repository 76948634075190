import React, { useState } from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { useParams } from "react-router-dom";

import NewScheduleEventModal from "./new.schedule.event.modal";
import { useScheduleEvents } from "../../../app/contexts/schedule.events.context";

const Controls = ({ search = "", onSearch, clearSearch }) => {
  const [openNewSOEModal, setOpenNewSOEModal] = useState(false);
  const { id } = useParams();
  const { createNewSOEPlan, fetchSOEStudy } = useScheduleEvents();

  const redirect = async () => {
    await fetchSOEStudy(id);
  };

  const handleClick = async (soe) => {
    const soeEventData = {
      studyId: id ? parseInt(id) : id,

      scheduleOfEventsName: soe.scheduleOfEventsName,

      scheduleOfEventsDescr: soe.scheduleOfEventsDescr,

      scheduleOfEventsStatus: "Draft",
      visitIntervalType: soe.visitIntervalType,
      visitWindowIntervalType: soe.visitWindowIntervalType,
      protocolDate: soe.protocolDate,
      protocolVersion: soe.protocolVersion,
    };
    await createNewSOEPlan(soeEventData, redirect);
  };

  return (
    <Container>
      <Button
        onClick={() => setOpenNewSOEModal(true)}
        variant="contained"
        color="primary"
      >
        Add New SOE
      </Button>

      {openNewSOEModal && (
        <NewScheduleEventModal
          onContinue={handleClick}
          open={openNewSOEModal}
          onClose={() => setOpenNewSOEModal(false)}
        />
      )}
    </Container>
  );
};
export default Controls;

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 1em 0;
  gap: 1em;
  align-content: center;
`;
