import React, { Fragment, useEffect, useMemo, useState } from "react";
import Controls from "./controls";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "./table.head";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "./table.row";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import { useStudyPlan } from "../../app/contexts/study.context";
import { useUser } from "../../app/contexts/user.context";
import TableHeadFilter from "./table.head/filter";
import { debounce } from "lodash";
import moment from "moment";
import { getComparator } from "../../app/helpers/utils";

const ROWS_PER_PAGE_KEY = "ctpo_study_plan_table_rows_per_page";
const defaultRowsPerPage = () =>
  localStorage.getItem(ROWS_PER_PAGE_KEY)?.toString() || 5;

const StudyTable = () => {
  const { study } = useStudyPlan();
  const [filter, setFilter] = useState("");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowsPerPage);
  const [page, setPage] = React.useState(0);
  const [filterActive, setFilterActive] = useState(false);
  const [filters, setFilters] = useState({});
  const [studyPlans, setStudyPlans] = useState(study.studyPlans || []);
  const [filteredStudyPlans, setFilteredStudyPlans] = useState(studyPlans);
  const classes = useStyles();
  const { setURLStudyId } = useUser();
  const { id } = useParams();

  const debounceFilter = useMemo(
    () =>
      debounce((lFilters) => {
        const filterKeys = Object.keys(lFilters);
        if (!filterKeys.length) return setFilteredStudyPlans(studyPlans);
        // for each filter, filter projects using the id and value and set the filtered projects

        const filtered = studyPlans.filter((plan) => {
          return filterKeys.every((filterId) => {
            const { value, type, start, end } = lFilters[filterId];
            if (type === "date") {
              if (!start || !end) return true;
              const startDate = moment(start).valueOf();
              const endDate = moment(end).valueOf();
              if (!plan[filterId]) return false;
              const projectDate = moment(plan[filterId]).valueOf();
              let startD = new Date(startDate);
              let endD = new Date(endDate);
              let projectD = new Date(projectDate);

              startD = new Date(
                startD.getFullYear(),
                startD.getMonth(),
                startD.getDate(),
              );
              endD = new Date(
                endD.getFullYear(),
                endD.getMonth(),
                endD.getDate(),
              );
              projectD = new Date(
                projectD.getFullYear(),
                projectD.getMonth(),
                projectD.getDate(),
              );

              if (projectD >= startD && projectD <= endD) return true;
              else return false;
            }

            if (type === "select") {
              if (!plan[filterId]) return false;
            }

            if (plan[filterId]) {
              return plan[filterId].toLowerCase().includes(value.toLowerCase());
            } else {
              return false;
            }
          });
        });
        setFilteredStudyPlans(filtered);
        setPage(0);
      }, 500),
    [studyPlans],
  );

  useEffect(() => {
    debounceFilter(filters);
  }, [debounceFilter, filters]);

  useEffect(() => {
    setURLStudyId(id ?? 3712);
    // eslint-disable-next-line
  }, []);

  /**
   *  Sorting Fuctions
   */
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  /**
   * Pagination
   */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const { value } = event.target;
    const rows = parseInt(value);

    setRowsPerPage(rows);
    setPage(0);

    localStorage.setItem(ROWS_PER_PAGE_KEY, rows);
  };

  return (
    <Fragment>
      <Controls
        search={filter}
        onSearch={(value) => setFilter(value)}
        clearSearch={() => setFilter("")}
        onNewPlan={(noop) => noop}
      />
      <Paper>
        <Paper style={{ minHeight: "100%" }}>
          <TableContainer classes={classes}>
            <Table
              size="medium"
              aria-label="study table"
            >
              <TableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                filterActive={filterActive}
                setFilterActive={setFilterActive}
              />
              {filterActive && (
                <TableHeadFilter
                  filters={filters}
                  setFilters={setFilters}
                  filterActive={filterActive}
                  setFilterActive={setFilterActive}
                />
              )}
              <TableBody>
                {stableSort(filteredStudyPlans, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow
                      key={row.studyPlanId}
                      {...row}
                      studyPlans={filteredStudyPlans?.studyPlans || []}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            rowsPerPage={rowsPerPage}
            page={page}
            count={filteredStudyPlans.length || 0}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </Paper>
    </Fragment>
  );
};

export default StudyTable;

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "4px",
  },
}));
