import { makeStyles } from "@material-ui/core/styles";

export const descendingComparator = (a, b, orderBy) => {
  let valueA = a[orderBy] ?? "";
  let valueB = b[orderBy] ?? "";

  if (orderBy === "isFavorite") {
    return valueB - valueA;
  }

  if (typeof valueA === "boolean") {
    valueA = valueA ? 1 : 0;
  }

  if (typeof valueB === "boolean") {
    valueB = valueB ? 1 : 0;
  }

  if (typeof valueA === "number" && typeof valueB === "number") {
    return valueB - valueA;
  } else if (typeof valueA === "string" && typeof valueB === "string") {
    return valueB.localeCompare(valueA);
  } else {
    throw new Error("Unsupported data type for comparison");
  }
};

export const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const otherStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    fontWeight: "normal",
    "&:hover": {
      fontWeight: "bold",
    },
  },
}));

export const formatDate = (date) => {
  return new Date(date).toISOString();
};
