export const makeTabLabels = (country) => {
  return country.countryCodeISO3.includes("USA")
    ? `${country.countryCodeISO3} - ${
        country.siteGroupName.includes("Central")
          ? "C"
          : country.siteGroupName.includes("Local")
          ? "L"
          : ""
      }`
    : country.countryCodeISO3;
};
