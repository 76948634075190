import React from "react";
import styled from "styled-components";
import MuiTypography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import TuneIcon from "@material-ui/icons/Tune";

const SectionHeader = ({
  children,
  endAdornment,
  hasStartAdornment = true,
  marginBottom = true,
  isBold = true,
}) => {
  const classes = useStyles();

  return (
    <Container
      marginBottom={marginBottom}
      isFlex={hasStartAdornment || endAdornment}
    >
      {hasStartAdornment && (
        <IconContainer>
          <TuneIcon />
        </IconContainer>
      )}
      <MuiTypography className={isBold ? classes.root : classes.rootLight}>
        {children}
      </MuiTypography>
      {endAdornment && <div style={{ marginLeft: "1em" }}>{endAdornment}</div>}
    </Container>
  );
};

export default SectionHeader;

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: "1.2rem",
    fontWeight: "600",
    color: "#002e6e",
  },
  rootLight: {
    fontSize: "1.2rem",
    color: "#002e6e",
  },
}));

const Container = styled.div`
  display: ${({ isFlex }) => (isFlex ? "flex" : "inherit")};
  justfiy-content: space-between;
  align-items: center;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? "1em" : "0")};
`;

const IconContainer = styled.div`
  margin-right: 0.5em;
  color: ${({ theme }) => theme.palette.primary.main};
`;
