export const DETAILS_OPTIONS = [
  {
    name: "detailsMonthly",
    label: "Details Table Monthly (Cumulative)",
  },
  {
    name: "detailsMonthlyNonCumulative",
    label: "Details Table Monthly (Non-Cumulative)",
  },
  { name: "detailsWeekly", label: "Details Table Weekly (Cumulative)" },
  {
    name: "detailsWeeklyNonCumulative",
    label: "Details Table Weekly (Non-Cumulative)",
  },
];

export const COHORT_DETAILS_OPTIONS = [
  {
    name: "detailsMonthlyCohort",
    label: "Details Table Monthly (Cumulative) - Cohort",
  },
  {
    name: "detailsMonthlyNonCumulativeCohort",
    label: "Details Table Monthly (Non-Cumulative) - Cohort",
  },
  {
    name: "detailsWeeklyCohort",
    label: "Details Table Weekly (Cumulative) - Cohort",
  },
  {
    name: "detailsWeeklyNonCumulativeCohort",
    label: "Details Table Weekly (Non-Cumulative) - Cohort",
  },
];

export const SUMMARIES_OPTIONS = [
  {
    name: "combinedReport",
    label:
      "Combined Report (Summary, Country Start-up Milestones, Enrollment Milestones)",
  },
  { name: "proposal", label: "Proposal Report" },
];

export const DURATIONS_OPTIONS = [
  { name: "timeline", label: "Timeline Durations" },
  { name: "countryDurations", label: "Country Start-up Durations" },
];

export const ProposalHeader1Style = {
  font: { bold: true, color: { rgb: "FFFFFF" }, sz: 12 },
  fill: { fgColor: { rgb: "00B0F0" } },
  innerWidth: 20,
  alignment: { wrapText: true, horizontal: "center" },
  border: {
    top: { style: "thin", color: { rgb: "5A5A5A" } },
    right: { style: "thin", color: { rgb: "5A5A5A" } },
    bottom: { style: "thin", color: { rgb: "5A5A5A" } },
    left: { style: "thin", color: { rgb: "5A5A5A" } },
  },
};
