export const HEADERS = {
  assessmentType: "Assessment Type",
  assessmentName: "Assessment Name",
  assessmentNameMapping: "Assessment Name Mapping",
  assessmentCost: "Assessment Cost",
  overhead: "Overhead",
  exclude: "Exclude",
  ipAdministered: "IP Administered",
  commentsAssessment: "Assessment Comments",
  commentsCost: "Cost Comments",
  commentsClinicalStrategy: "Clinical Strategy Comments",
  commentsDataManagement: "Data Management Comments",
};

export const COUNTRY_HEADERS = (SOECountries) => [
  "Assessment Type",
  "Assessment Name",
  "Assessment Name Mapping",
  ...SOECountries.filter((soc) => soc.siteGroupId).map(
    (soc) => `${soc.siteGroupName} - ${soc.dropDownListLabel} - Assessment Cost`
  ),
];

export const ASSESSMENT_TYPES = [
  { name: "Procedure", value: "Procedure" },
  { name: "Non Procedure", value: "Non Procedure" },
  { name: "Site Cost 1x at Activation", value: "Site Cost 1x at Activation" },
  { name: "Site Cost 1x at Close", value: "Site Cost 1x at Close" },
  { name: "Site Cost Recurring Monthly", value: "Site Cost Recurring Monthly" },
  { name: "Site Cost Recurring Yearly", value: "Site Cost Recurring Yearly" },
  { name: "Site Cost Adhoc", value: "Site Cost Adhoc" },
];

export const ASSESSMENT_TYPES_WITH_SITE_COST = ASSESSMENT_TYPES.filter((at) =>
  at.value.includes("Site Cost")
).map((at) => at.value);
