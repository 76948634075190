import React from "react";
import Typography from "@material-ui/core/Typography";

const Label = ({ children, value = "- -", variant = "primary" }) => {
  if (variant !== "primary") {
    return (
      <Typography style={{ height: "30px" }}>
        {children}
        <strong>{value + " "}</strong>
      </Typography>
    );
  }
  return (
    <Typography style={{ height: "30px" }}>
      <strong>{value + " "}</strong>
      {children}
    </Typography>
  );
};

export default Label;
