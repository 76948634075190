import { useStudyPlan } from "../../../../../app/contexts/study.context";
import deleteIcon from "../../../../../app/images/deleteIcon.png";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import styled from "styled-components";
import { TextField } from "@material-ui/core";

const useInputStyles = makeStyles(() => ({
  root: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      display: "none",
    },
    "& input[type=number]": {
      textAlign: "center",
      "-moz-appearance": "textfield",
    },
  },
}));
const TOTAL_COLUMNS = 11;

const ActivationProfilesTable = ({
  activationProfiles,
  FSIV,
  siteGroupId,
  countryId,
}) => {
  const inputClasses = useInputStyles();

  const { updateCountries } = useStudyPlan();

  const handleChange = (value, index, property) => {
    const newActivationProfiles = [...activationProfiles];
    if (property === "siteActivationProfilePct") {
      newActivationProfiles[index][property] =
        value === "" ? null : parseFloat(value);
    } else if (property === "siteActivationProfileDays") {
      newActivationProfiles[index][property] =
        value === "" ? null : parseInt(value, 10);
    } else {
      newActivationProfiles[index][property] = value;
    }

    // Set label to "Projected using PP" when user starts typing in an empty column
    if (
      value !== "" &&
      newActivationProfiles[index].siteActivationLabel === ""
    ) {
      newActivationProfiles[index].siteActivationLabel = "Projected using PP";
    }

    // Clear label when both percentage and day are empty
    if (
      newActivationProfiles[index].siteActivationProfilePct === null &&
      newActivationProfiles[index].siteActivationProfileDays === null
    ) {
      newActivationProfiles[index].siteActivationLabel = "";
    }

    // Remove the 'empty-' prefix from the ID when a value is added
    const profileId = newActivationProfiles[index].siteActivationProfileId;
    if (
      value !== "" &&
      typeof profileId === "string" &&
      profileId.startsWith("empty-")
    ) {
      newActivationProfiles[index].siteActivationProfileId = profileId.replace(
        "empty-",
        "",
      );
    }

    const update = {
      activationProfiles: newActivationProfiles.filter(
        (profile) =>
          profile.siteActivationProfilePct !== null ||
          profile.siteActivationProfileDays !== null ||
          (typeof profile.siteActivationProfileId === "string" &&
            !profile.siteActivationProfileId.startsWith("empty-")),
      ),
    };
    updateCountries(countryId, siteGroupId, update);
  };

  const handleDelete = (profileId) => {
    const newActivationProfiles = [...activationProfiles];

    const update = {
      activationProfiles: newActivationProfiles.filter(
        (profile) => profile.siteActivationProfileId !== profileId,
      ),
    };
    updateCountries(countryId, siteGroupId, update);
  };

  return (
    <Table>
      <Row repeat={TOTAL_COLUMNS}>
        <Cell variant="header">
          <Typography align="right">
            <strong>Label</strong>
          </Typography>
        </Cell>
        {activationProfiles.map((profile, index) => (
          <Cell key={profile.siteActivationProfileId}>
            <Typography>{profile.siteActivationLabel || ""}</Typography>
          </Cell>
        ))}
      </Row>
      <Row repeat={TOTAL_COLUMNS}>
        <Cell variant="header">
          <Typography align="right">
            <strong>Percentage</strong>
          </Typography>
        </Cell>
        {activationProfiles.map((profile, index) => (
          <Cell key={`percentage-${index}`}>
            <TextField
              style={{ width: "80%" }}
              type="number"
              inputProps={{ step: "0.01", min: "0", max: "1" }}
              classes={inputClasses}
              value={profile.siteActivationProfilePct ?? ""}
              onChange={(event) =>
                handleChange(
                  event.target.value,
                  index,
                  "siteActivationProfilePct",
                )
              }
              variant="outlined"
              size="small"
            />
          </Cell>
        ))}
      </Row>
      <Row repeat={TOTAL_COLUMNS}>
        <Cell variant="header">
          <Typography align="right">
            <strong>Days</strong>
          </Typography>
        </Cell>
        {activationProfiles.map((profile, index) => (
          <Cell key={`percentage-${index}`}>
            <TextField
              style={{ width: "80%" }}
              type="number"
              classes={inputClasses}
              value={profile.siteActivationProfileDays ?? ""}
              onChange={(event) =>
                handleChange(
                  event.target.value,
                  index,
                  "siteActivationProfileDays",
                )
              }
              variant="outlined"
              size="small"
            />
          </Cell>
        ))}
      </Row>
      <Row repeat={TOTAL_COLUMNS}>
        <Cell variant="header">
          <Typography align="right">
            <strong>Date</strong>
          </Typography>
        </Cell>
        {activationProfiles.map((profile) => (
          <Cell key={profile.siteActivationProfileId}>
            <Typography>
              {FSIV.milestoneDateMillis && profile.siteActivationProfileDays
                ? moment(
                    FSIV.milestoneDateMillis +
                      profile.siteActivationProfileDays * 24 * 60 * 60 * 1000,
                  )
                    .utc()
                    .format("DD/MMM/YYYY")
                : ""}
            </Typography>
          </Cell>
        ))}
      </Row>
      <Row repeat={TOTAL_COLUMNS}>
        <Cell variant="header">
          <Typography align="right">
            <strong>Delete</strong>
          </Typography>
        </Cell>
        {activationProfiles.map((profile) => (
          <Cell key={profile.siteActivationProfileId}>
            {profile.siteActivationProfileDays ? (
              <DeleteButton
                onClick={() => handleDelete(profile.siteActivationProfileId)}
              >
                <img
                  src={deleteIcon}
                  alt="Delete icon"
                />
              </DeleteButton>
            ) : (
              ""
            )}
          </Cell>
        ))}
      </Row>
    </Table>
  );
};

export default ActivationProfilesTable;

const Table = styled.div`
  margin-top: 20px;
  display: grid;
  width: 100%;
  grid-template-rows: auto auto;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 120px repeat(${TOTAL_COLUMNS}, 1fr);
  height: 50px;
  border-bottom: 1px solid #ccc;
  &:last-child {
    border: none;
  }
`;

const Cell = styled.div`
  background: ${(p) => (p.variant === "header" ? "#f3f4f4" : "transparent")};
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  border-right: 1px solid #ccc;

  &:first-child {
    justify-content: flex-end;
    padding-right: 1em;
  }
`;

const DeleteButton = styled.div`
  border: none;
  background: none;
  &:hover {
    cursor: pointer;
    transform: scale(1.1);
    transition: transform 0.2s;
  }
`;
