import Button from "@material-ui/core/Button";
import React from "react";

export const CloseButton = ({ setRemodelViewOpen }) => {
  return (
    <Button
      onClick={() => setRemodelViewOpen(false)}
      variant="outlined"
      style={{ width: "fit-content", padding: "5px" }}
    >
      Close
    </Button>
  );
};
