import { Button } from "@material-ui/core";
import React from "react";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

export default function TopControls() {
  const { id } = useParams();
  const history = useHistory();

  const handleBackStudyActual = () => {
    history.push(`/ctpo/study-actuals/${id}`);
  };

  return (
    <Container>
      <Button color="primary" onClick={handleBackStudyActual}>
        <ChevronLeftIcon />
        Back
      </Button>
    </Container>
  );
}

const Container = styled.div`
  margin-top: 1rem;
`;
