import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import React from "react";

export const CheckBoxesExportTable = ({
  options,
  isChecked,
  type = null,
  setIsChecked,
  section,
}) => {
  const handleChange = (e, value) => {
    const { name } = e.target;
    setIsChecked((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      {options.map(({ name, label }) => (
        <FormControlLabel
          key={name}
          control={
            <Checkbox
              checked={isChecked[name]}
              onChange={handleChange}
              name={name}
            />
          }
          label={label}
        />
      ))}
      {type !== null && section === "Summaries" && type === "cohort" && (
        <FormControlLabel
          control={
            <Checkbox
              checked={isChecked.summaryCohort}
              onChange={handleChange}
              name="summaryCohort"
            />
          }
          label="Summary - Cohort"
        />
      )}
    </>
  );
};
