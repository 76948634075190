import AppBar from "./app.bar";
import Logo from "./logo";
import Navigation from "./navigation";
import React from "react";
import UserMenu from "./user.menu";
import styled from "styled-components";

const TopBar = () => {
  return (
    <AppBar>
      <Container
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Logo />
        <Navigation />
      </Container>
      <Container>
        <UserMenu />{""}
      </Container>
    </AppBar>
  );
};

export default TopBar;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
