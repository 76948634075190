import React from "react";
import { Route } from "react-router-dom";

export default function AddRouteComponentProp({
  component: Component,
  additionalProps = {},
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) => {
        return <Component {...props} {...additionalProps} />;
      }}
    />
  );
}
