import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Divider } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import { useFavorite } from "../../app/contexts/favorites.context";
import { useUser } from "../../app/contexts/user.context";
import LinkIcon from "@material-ui/icons/Link";
import { PRIMARY_COLOR, PRIMARY_COLOR_DARK, LINKS } from "./constants";

const FavoriteStudy = ({ height }) => {
  const history = useHistory();
  const location = useLocation();
  const { fetchFavorites, favorites } = useFavorite();
  const { user } = useUser();

  useEffect(() => {
    fetchFavorites(user?.userId);
    // eslint-disable-next-line
  }, []);

  // paramaterizing 'study plans', 'study actuals' part of URL - TODO: useParams() to replace this?
  const currTab = location.pathname.split("/").slice(2, 3);
  const handleClick = (favorite) =>
    favorite && history.push(`/ctpo/${currTab}/${favorite.studyId}`);

  const renderFavorites = () => {
    if (favorites.length === 0) {
      return <Typography color="textSecondary">No favorities yet.</Typography>;
    } else {
      return (
        <List>
          {favorites.map((favorite) => (
            <Item
              key={favorite.studyId}
              onClick={() => handleClick(favorite)}
            >
              <Typography noWrap>
                {favorite.projectId} - {favorite.studyName}
              </Typography>
            </Item>
          ))}
        </List>
      );
    }
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <h2
        style={{
          display: "flex",
          justifyContent: "space-between",
          color: PRIMARY_COLOR_DARK,
          fontSize: "1.2rem",
          alignItems: "center",
        }}
      >
        <span>My Favorites</span>
        <span style={{ color: PRIMARY_COLOR }}></span>
      </h2>
      <Divider
        dashed
        style={{ margin: "0.5rem 0rem" }}
      />
      <div style={{ overflowY: "auto", height }}>{renderFavorites()}</div>
      <div
        style={{
          display: "flex",
          gap: ".5rem",
          flexDirection: "column",
          position: "absolute",
          bottom: "35px",
        }}
      >
        <Divider
          dashed
          style={{ marginRight: "-6.3rem" }}
        />
        <h2
          style={{
            display: "flex",
            justifyContent: "space-between",
            color: PRIMARY_COLOR_DARK,
            fontSize: "1.2rem",
            alignItems: "center",
          }}
        >
          <span>Quick Links</span>
        </h2>
        {LINKS.map(({ href, text }) => (
          <QuickLink
            key={href}
            href={href}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkIcon style={{ marginRight: "0.5rem" }} />
            <Typography>{text}</Typography>
          </QuickLink>
        ))}
      </div>
    </div>
  );
};
export default FavoriteStudy;

const Item = styled.div`
  margin-bottom: 0.5em;
  cursor: pointer;
  color: inherit;
  text-decoration: none;

  &:hover {
    color: #4ac6e1;
  }
`;

const List = styled.div``;

const QuickLink = styled.a`
  text-decoration: none;
  color: black;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    color: #4ac6e1;
  }
`;
