import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import API from "../../../../app/utils/api";
import Button from "@material-ui/core/Button";
import { Modal } from "../../../../app/components";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { useScheduleEvents } from "../../../../app/contexts/schedule.events.context";

/*
  This component is a modal that allows the user to clone a schedule of events.
  It uses the schedule events context to handle the cloning of the schedule of events.
  The parent component must pass a callback function to handle the schedule of events parameters.
*/
const CloneScheduleOfEvent = ({
  open = false,
  onClose = (noop) => noop,
  soe,
}) => {
  const { fetchSOEStudy } = useScheduleEvents();
  const history = useHistory();
  const classes = useStyles();
  const { id } = useParams();
  const [formValid, setFormValid] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [SOEEventParameters, setSOEEventParameters] = useState({
    scheduleOfEventsName: soe.scheduleOfEventsName + " - Clone",
    scheduleOfEventsDescr: soe?.scheduleOfEventsDescr ?? "",
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    handleChange("scheduleOfEventsName", soe.scheduleOfEventsName + " - Clone");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      SOEEventParameters["scheduleOfEventsDescr"] &&
      SOEEventParameters["scheduleOfEventsName"] &&
      !errors["scheduleOfEventsDescr"] &&
      !errors["scheduleOfEventsName"]
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [SOEEventParameters, errors]);

  const handleChange = (key, value) => {
    setSOEEventParameters({ ...SOEEventParameters, [key]: value });
    if (!value) {
      setErrors({ ...errors, [key]: "This field is required" });
    } else {
      setErrors({ ...errors, [key]: "" });
    }
    if (key === "scheduleOfEventsName") {
      soe.scheduleOfEvents.forEach((sp) => {
        if (
          sp?.scheduleOfEventsName.toLowerCase().trim() ===
          value.toLowerCase().trim()
        ) {
          setErrors({
            ...errors,
            [key]: "Schedule of Events name must be unique",
          });
        }
        if (!value.replace(/\s/g, "").length) {
          setErrors({
            ...errors,
            [key]: "SOE name must contain at least 1 character",
          });
        }
      });
    }
    if (key === "scheduleOfEventsDescr") {
      if (!value.replace(/\s/g, "").length) {
        setErrors({
          ...errors,
          [key]: "SOE description must contain at least 1 character",
        });
      }
    }
  };

  const handleCloneAndEdit = () => {
    setSaveLoading(true);
    const { scheduleOfEventsId } = soe;
    const new_soe = {
      scheduleOfEventsId,
      studyId: parseInt(id),
      scheduleOfEventsName: SOEEventParameters.scheduleOfEventsName,
      scheduleOfEventsDescr: SOEEventParameters.scheduleOfEventsDescr,
    };
    API.createNewSOEClone(new_soe, id)
      .then(async (res) => {
        setSaveLoading(false);
        if (res.data[0].Id) {
          history.push(`/ctpo/study-plans/${id}/soe/${res.data[0].Id}`);
          await fetchSOEStudy(id);
        }
      })
      .catch((err) => {
        console.error(err);
        setSaveLoading(false);
      });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="clone SOE"
      aria-describedby="clone a SOE"
      title="Clone SOE"
    >
      <Container>
        <TextField
          value={SOEEventParameters.scheduleOfEventsName}
          onChange={(e) => handleChange("scheduleOfEventsName", e.target.value)}
          label="SOE Name"
          variant="outlined"
          InputLabelProps={{ classes: classes }}
          fullWidth
          required
          error={!!errors.scheduleOfEventsName}
          inputProps={{
            maxLength: 50,
          }}
          helperText={
            <AlignBetween>
              <span>{errors.scheduleOfEventsName}</span>
              <span>{`${
                (SOEEventParameters.scheduleOfEventsName || "").length
              } / ${50}`}</span>
            </AlignBetween>
          }
        />
        <TextField
          value={SOEEventParameters.scheduleOfEventsDescr}
          onChange={(e) =>
            handleChange("scheduleOfEventsDescr", e.target.value)
          }
          label="SOE Description"
          variant="outlined"
          InputLabelProps={{ classes: classes }}
          multiline
          fullWidth
          required
          inputProps={{
            maxLength: 2000,
          }}
          rows={4}
          error={!!errors.scheduleOfEventsDescr}
          helperText={
            <AlignBetween>
              <span>{errors.scheduleOfEventsDescr}</span>
              <span>{`${
                (SOEEventParameters.scheduleOfEventsDescr || "").length
              } / ${2000}`}</span>
            </AlignBetween>
          }
        />
        <SideBySide>
          <Button variant="contained" onClick={onClose}>
            Cancel{" "}
          </Button>
          <Button
            onClick={handleCloneAndEdit}
            variant="contained"
            color="primary"
            disabled={saveLoading || !formValid}
          >
            Clone & Edit Details
          </Button>
        </SideBySide>
      </Container>
    </Modal>
  );
};

export default CloneScheduleOfEvent;

const Container = styled.div`
  display: grid;
  grid-gap: 2em;
`;
const SideBySide = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1em;
`;

const AlignBetween = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const useStyles = makeStyles((theme) => ({
  asterisk: {
    color: "red",
  },
}));
