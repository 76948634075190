import React, { useContext, useState } from "react";
import API from "../utils/api";

const FavoriteContext = React.createContext();

/**
 Hook to use Auth context.
 Includes current user and methods to login, logout, reset passwords.
 TODO: detect when user is idle and log them out.
 */

export function useFavorite() {
  return useContext(FavoriteContext);
}

export function FavoriteProvider({ children }) {
  const [favorites, setFavorites] = useState([]);
  const [loading, setLoading] = useState(true);

  /**
   * Returns the favorites for a particular user, but their email.
   * We then update the current favorites state by
   * adding the favorites to the
   * favorite list.
   *
   * @param {string} userId - The users id.
   */
  const fetchFavorites = (userId) => {
    setLoading(true);
    return API.getFavorites(userId).then((res) => {
      setFavorites(res.data);
      setLoading(false);
    });
  };

  /**
   * Removes a favorite from the db.
   * We then update the current favorites state by
   * removing the favorite from the
   * favorite list.
   *
   * @param {string} email - The users id.
   * @param {string} id - The id of the favorited study.
   */
  const removeFavorite = ({ studyId = null, userId = null }) => {
    setLoading(true);
    return API.deleteFavorite({ studyId, userId }).then((res) => {
      const newFavorites = favorites.filter(
        (favorite) => favorite.studyId !== studyId
      );
      setFavorites(newFavorites);
      setLoading(false);
    });
  };

  /**
   * Returns true if the id passed is in the favorite list,
   * else it returns false.
   *
   * @param {string} id - a plan id.
   */
  const isFavorite = ({ id = null }) =>
    favorites.map((fav) => fav.studyId).indexOf(id) > -1;

  /**
   * Creates a new favorite & stores it in the db.
   * We then update the current favorites state by
   * adding the new favorite to the top of the
   * favorite list.
   *
   * @param {string} email - The users id.
   * @param {string} id - The id of the favorited study.
   */
  const createFavorite = ({
    userId = null,
    studyId = null,
    studyName = null,
    projectId = null,
  }) => {
    const favorite = {
      studyId,
      userId,
      isFavorite: 1,
      studyName,
      projectId,
    };

    setLoading(true);
    API.postFavorite(favorite).then((res) => {
      setFavorites([favorite, ...favorites]);
      setLoading(false);
    });
  };

  const value = {
    favorites,
    loading,
    fetchFavorites,
    removeFavorite,
    createFavorite,
    isFavorite,
  };

  return (
    <FavoriteContext.Provider value={value}>
      {children}
    </FavoriteContext.Provider>
  );
}
