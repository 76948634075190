import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { SectionHeader } from "../../../../app/components";
import SpecialRadioButton from "../../../../app/components/special.radiobutton";
import moment from "moment";

export default function PatientEnrollmentDate({
  errors,
  touched,
  values,
  setValues,
}) {
  return (
    <Container>
      <SectionHeader hasStartAdornment={false}>
        Last Patient Enrollment Date
      </SectionHeader>
      <FormControl component="fieldset">
        <RadioGroup
          row
          aria-label="overallEnrollmentDurationSelection"
          name="overallEnrollmentDurationSelection"
          value={values.overallEnrollmentDurationSelection}
          onChange={(e) => {
            setValues({
              ...values,
              overallEnrollmentDurationSelection: parseFloat(e.target.value),
            });
          }}
        >
          <TableContainer>
            <Table>
              <Row>
                <Cell variant="header">
                  <Typography align="center">
                    <strong>Planned</strong>
                  </Typography>
                </Cell>
                <Cell>
                  <FormControlLabel
                    value={1}
                    control={<Radio color="primary" />}
                    label={moment(values?.lastPatientEnrolledDateMillisPlanned)
                      .utc()
                      .format("DD/MMM/YYYY")}
                  />
                </Cell>
              </Row>
            </Table>
            <Table>
              <Row>
                <Cell variant="header">
                  <Typography align="center">
                    <strong>Other</strong>
                  </Typography>
                </Cell>
                <Cell>
                  <SpecialRadioButton
                    type="date"
                    currentRadioValue={2}
                    onChange={(value) =>
                      setValues({
                        ...values,
                        lastPatientEnrolledDateMillisOther: value,
                      })
                    }
                    value={values?.lastPatientEnrolledDateMillisOther}
                    error={!!errors?.lastPatientEnrolledDateMillisOther}
                  />
                </Cell>
              </Row>
            </Table>
          </TableContainer>
        </RadioGroup>
        {errors.overallEnrollment && (
          <ErrorMessageContainer className="MuiFormHelperText-root">
            {errors.overallEnrollment}
          </ErrorMessageContainer>
        )}
      </FormControl>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TableContainer = styled.div`
  width: 100%;
  display: flex;
  column-gap: 1rem;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-bottom: 1px solid #ccc;
  height: 10em;
`;

const Cell = styled.div`
  background: ${(p) => (p.variant === "header" ? "#f3f4f4" : "white")};
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  border-bottom: 1px solid #ccc;
  padding: 0.5em;
`;

const Table = styled.div`
  display: flex;
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-width: 35%;
`;

const ErrorMessageContainer = styled.div`
  color: #f44336 !important;
  margin: 3px 14px !important;
`;
