import React, { useMemo, useState, useEffect } from "react";

import { Accordion } from "../../../../app/components";
import { MAPPING_TOTAL_HEADERS } from "./table.utils";
import TableHeader from "./table.header";
import TableRow from "./table.row";
import styled from "styled-components";
import { useScheduleEvents } from "../../../../app/contexts/schedule.events.context";

/*
  This component is responsible for rendering the table of schedule of events
  It uses the schedule events context to handle the schedule of events.
  It uses the study context to handle the study plan.
*/
export default function SOETable({
  fieldType,
  setHasChanges,
  updateContext,
  setSaveSOE,
  tableVisibility,
  setTableVisibility,
}) {
  const {
    scheduleOfEventsVisits,
    scheduleOfEventsAssessments,
    visitsAndAssessmentsMapping,
    setVisitsAndAssessmentsMapping,
    setVisitsAndAssessmentsMappingChangeLog,
    originalSOEMapping,
    setTotalMappings,
  } = useScheduleEvents();

  const [soeState, setSoeState] = useState([
    {
      assessmentKey: undefined,
      visitKey: undefined,
      value: undefined,
      key: "value",
    },
  ]);

  const visitToUse = useMemo(
    () => scheduleOfEventsVisits.study,
    [scheduleOfEventsVisits],
  );
  const mappingTotals = useMemo(() => {
    if (!scheduleOfEventsVisits.study) return new Array(4).fill([]);
    const totals = [[], [], [], [], [], []];
    const totalMappings = originalSOEMapping.filter(
      (vasm) => vasm.dataSet === "Total",
    );
    const visitNames = visitToUse.filter((visit) => visit.visitName);
    scheduleOfEventsVisits.study
      .sort((a, b) => {
        return (
          visitNames.indexOf(a.visitName) - visitNames.indexOf(b.visitName)
        );
      })
      .forEach((visit, index) => {
        const vasm =
          totalMappings.filter(
            (vasm) => vasm.visitName === visit.visitName,
          )[0] || {};
        totals[0].push(vasm.assessmentCount);
        totals[1].push(vasm.assessmentCost);
        totals[2].push(vasm.visitOverhead);
        totals[3].push(vasm.totalVisitCost);
        totals[4].push(vasm.uniqueCRFCount);
        totals[5].push(vasm.nonUniqueCRFCount);
      });
    setTotalMappings(totals);
    return totals;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalSOEMapping, visitToUse]);

  const assessmentToUse = useMemo(
    () =>
      scheduleOfEventsAssessments.study.filter(({ assessmentType }) =>
        ["Procedure", "Non Procedure"].includes(assessmentType),
      ),
    [scheduleOfEventsAssessments],
  );

  const visitsCount = useMemo(() => visitToUse?.length || 0, [visitToUse]);

  const activitiesPercentile = 30;

  const handleMappingChange = (
    assessmentKey,
    visitKey,
    value,
    key = "value",
  ) => {
    setHasChanges(true);
    setVisitsAndAssessmentsMapping((prevValue) => {
      const updatedState = { ...prevValue };

      if (updatedState[assessmentKey]) {
        const arrayIndex = updatedState[assessmentKey].findIndex(
          (item) => item.scheduleOfEventsVisitId === visitKey,
        );

        if (arrayIndex !== -1) {
          updatedState[assessmentKey][arrayIndex] = {
            ...updatedState[assessmentKey][arrayIndex],
            [key]: value,
          };
        }
      }
      return updatedState;
    });
    setSoeState((prevState) => {
      const index = prevState.findIndex(
        (item) =>
          item.assessmentKey === assessmentKey && item.visitKey === visitKey,
      );

      if (index !== -1) {
        const newState = [...prevState];
        newState[index] = { ...newState[index], [key]: value };
        return newState;
      } else {
        const updatedObj = [
          ...prevState,
          { assessmentKey, visitKey, [key]: value },
        ];
        return updatedObj;
      }
    });
  };

  useEffect(() => {
    if (updateContext) {
      setVisitsAndAssessmentsMapping((prevValue) => {
        let updatedValue = JSON.parse(JSON.stringify(prevValue));

        Object.keys(updatedValue).forEach((assessmentKey) => {
          const relevantChanges = soeState.filter(
            (change) =>
              change.assessmentKey?.toString() === assessmentKey?.toString(),
          );

          if (relevantChanges.length === 0) return;

          relevantChanges.forEach(({ visitKey, value, multiplier }) => {
            if (visitKey === "all") {
              updatedValue[assessmentKey] = updatedValue[assessmentKey].map(
                (vo) => ({ ...vo, value }),
              );
            } else {
              updatedValue[assessmentKey] = updatedValue[assessmentKey].map(
                (vo) => {
                  if ((vo.key || vo.scheduleOfEventsVisitId) === visitKey) {
                    return {
                      ...vo,
                      value: typeof value === "boolean" ? value : vo.value,
                      multiplier:
                        multiplier !== undefined ? multiplier : vo.multiplier,
                    };
                  }
                  return vo;
                },
              );
            }
          });
        });

        return updatedValue;
      });
      setVisitsAndAssessmentsMappingChangeLog({ someValue: "some change" });
      setSaveSOE(true);
    } else {
      setSaveSOE(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateContext]);

  const renderRows = () => {
    return assessmentToUse?.map((assessment) => {
      let assessmentRowValue =
        visitsAndAssessmentsMapping[
          assessment.key || assessment.scheduleOfEventsAssessmentId
        ];

      const uniqueVisitKeys = visitToUse.map(
        (visit) => visit.scheduleOfEventsVisitId || visit.key,
      );

      let sortedAssessmentRowValue = [];
      if (assessmentRowValue?.length) {
        sortedAssessmentRowValue = [...assessmentRowValue].sort((a, b) => {
          if (
            uniqueVisitKeys.indexOf(a.scheduleOfEventsVisitId || a.key) ===
              -1 ||
            uniqueVisitKeys.indexOf(b.scheduleOfEventsVisitId || b.key) === -1
          )
            return 0;
          return (
            uniqueVisitKeys.indexOf(a.scheduleOfEventsVisitId || a.key) -
            uniqueVisitKeys.indexOf(b.scheduleOfEventsVisitId || b.key)
          );
        });
      }

      return (
        <TableRow
          key={`${assessment.key || assessment.scheduleOfEventsAssessmentId}`}
          visitsCount={visitsCount}
          assessment={assessment}
          visitsAndAssessmentsMapping={visitsAndAssessmentsMapping}
          sortedAssessmentRowValue={sortedAssessmentRowValue}
          activitiesPercentile={activitiesPercentile}
          handleChange={handleMappingChange}
          fieldType={fieldType}
        />
      );
    });
  };

  const renderAdditionalRows = () => {
    return MAPPING_TOTAL_HEADERS.map((assessment, index) => {
      return (
        <TableRow
          type="total"
          totalTitle={assessment}
          totalValues={mappingTotals[index]}
          key={`${assessment}`}
          visitsCount={visitsCount}
          assessment={{ assessmentName: assessment }}
          visitsAndAssessmentsMapping={visitsAndAssessmentsMapping}
          activitiesPercentile={activitiesPercentile}
          handleChange={handleMappingChange}
          fieldType={fieldType}
        />
      );
    });
  };
  return (
    <Accordion
      scrollIntoView={true}
      width={"100%"}
      summary={"Schedule of Events"}
      defaultExpanded={tableVisibility.showVisitInformationTable}
      onClick={() => {
        setTableVisibility("showVisitInformationTable");
      }}
    >
      {tableVisibility.showVisitInformationTable && (
        <Table onClick={(event) => event.stopPropagation()}>
          <TableHeader
            visits={visitToUse}
            visitsCount={visitsCount}
            activitiesPercentile={activitiesPercentile}
          />

          {renderRows()}
          {renderAdditionalRows()}
        </Table>
      )}
    </Accordion>
  );
}

const Table = styled.div`
  max-width: calc(97vw - 30px);
  max-height: 1000px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  border: ${(p) => (p.noBorder ? "none" : "1px solid #ccc")};
  width: 100%;
`;
