import React, { useContext, useState } from "react";
import { Snackbar } from "../components";

const SnackbarContext = React.createContext();

export function useSnackbar() {
  return useContext(SnackbarContext);
}

export function SnackbarProvider({ children }) {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");

  const showSnackbar = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  };

  const handleCloseSnackbar = () => {
    setOpen(false);
    setMessage("");
    setSeverity("success");
  };

  const value = {
    showSnackbar,
  };
  return (
    <SnackbarContext.Provider value={value}>
      {children}
      <Snackbar
        open={open}
        severity={severity}
        message={message}
        onClose={handleCloseSnackbar}
      />
    </SnackbarContext.Provider>
  );
}
