import { SectionHeader, Tooltip } from "../../../../../app/components";

import React from "react";
import styled from "styled-components";
import { useStudyPlan } from "../../../../../app/contexts/study.context";

import CountryMilestoneTable from "./country-milestone-table";

const CountryGlobalMilestones = ({ country, siteGroup, onChange }) => {
  const { updateCountryMilestones } = useStudyPlan();
  const { milestones, siteGroupId } = siteGroup;
  const { countryId } = country;

  const filteredMilestones = milestones.filter(
    (m) =>
      m.milestoneId === 158 ||
      m.milestoneId === 135 ||
      m.milestoneId === 136 ||
      m.milestoneId === 139 ||
      m.milestoneId === 142 ||
      m.milestoneId === 145 ||
      m.milestoneId === 148 ||
      m.milestoneId === 151 ||
      m.milestoneId === 146 ||
      m.milestoneId === 149 ||
      m.milestoneId === 152,
  );

  const leftSide = filteredMilestones.slice(0, 6);
  const rightSide = filteredMilestones.slice(7, 11);

  const handleChange = (milestoneName, property, value, initialValue) => {
    const milestone_index = milestones
      .map((m) => m.milestoneName)
      .indexOf(milestoneName);
    const milestone = milestones[milestone_index];

    if (property === "milestoneDateMillis") {
      milestone[property] = value;

      const lowerBound = Math.round(
        (value - initialValue) / (1000 * 60 * 60 * 24),
      );
      milestone.lowerBound = lowerBound;
    } else {
      milestone[property] = value;
    }

    const new_milestones = [...milestones];
    new_milestones[milestone_index] = milestone;
    const update = { milestones: new_milestones };
    const changelog_update = [milestone];
    updateCountryMilestones(countryId, siteGroupId, update, changelog_update);
  };

  return (
    <Container id="country-milestones">
      <SectionHeader
        endAdornment={
          <Tooltip info="Certain dates below can be overridden. If you do edit a date, please include the reason for this in the corresponding comment field." />
        }
      >
        Country Milestones
      </SectionHeader>

      <div
        style={{ display: "flex", justifyContent: "space-between", gap: "8px" }}
      >
        <CountryMilestoneTable
          rows={leftSide}
          handleChange={handleChange}
        />
        <CountryMilestoneTable
          rows={rightSide}
          isRightOrLeft={"right"}
          handleChange={handleChange}
        />
      </div>
    </Container>
  );
};

export default CountryGlobalMilestones;

const Container = styled.div``;
