import { InputAdornment, TextField } from "@material-ui/core";
import React, { useState, useEffect } from "react";

import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import DatePicker from "react-datepicker";

export default function CustomDatePickerRange({
  error,
  onChange,
  style,
  label,
  required,
  small,
  placeholderText,
  value,
  ...props
}) {
  let [tempValue, setTempValue] = useState(
    value.some((x) => x !== null)
      ? value.map((date) => new Date(date))
      : [null, null],
  );

  useEffect(() => {
    if (value.every((x) => x === null) && tempValue.every((x) => x !== null)) {
      setTempValue([null, null]);
    }
  }, [value, tempValue]);

  const CustomDateInput = ({ value, onChange, onClick }) => (
    <TextField
      placeholder={placeholderText || "DD/MMM/YYYY"}
      style={{ width: "100%" }}
      value={value}
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment
            style={{ cursor: "pointer" }}
            position="start"
          >
            {" "}
            <CalendarTodayIcon />
          </InputAdornment>
        ),
      }}
      error={error}
      onClick={onClick}
      label={label}
      required={required}
      size={small ? "small" : "medium"}
    />
  );

  return (
    <div
      style={{
        ...style,
        overflow: "inherit",
      }}
    >
      <DatePicker
        {...props}
        selected={tempValue[0]}
        selectsRange
        startDate={tempValue[0]}
        endDate={tempValue[1]}
        onChange={(value) => {
          setTempValue(value);
          onChange(value.map((date) => (date ? new Date(date) : null)));
        }}
        customInput={<CustomDateInput />}
      />
    </div>
  );
}
