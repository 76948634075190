import React from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import { SectionHeader } from "../../../../../app/components";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import MilestoneItem from "./milestone.item";
import { useStudyPlan } from "../../../../../app/contexts/study.context";

const CountryGlobalMilestones = ({ siteGroup, tab }) => {
  const { canEditPlan } = useStudyPlan();
  const history = useHistory();
  const { id, planId } = useParams();
  const { milestones } = siteGroup;
  const filteredMilestones = milestones.filter(
    (m) =>
      m.milestoneId === 158 ||
      m.milestoneId === 135 ||
      m.milestoneId === 136 ||
      m.milestoneId === 139 ||
      m.milestoneId === 142 ||
      m.milestoneId === 145 ||
      m.milestoneId === 148 ||
      m.milestoneId === 151 ||
      m.milestoneId === 146 ||
      m.milestoneId === 149 ||
      m.milestoneId === 152,
  );

  const leftSide = filteredMilestones.slice(0, 6);
  const rightSide = filteredMilestones.slice(7, 11);

  return (
    <Container>
      <SideBySide>
        <SectionHeader>Country Milestones</SectionHeader>
        {canEditPlan && (
          <Button
            color="primary"
            onClick={() =>
              history.push(
                `/ctpo/study-plans/${id}/${planId}/countries-and-site-groups#country-milestones`,
                { tabIndex: tab },
              )
            }
          >
            <EditIcon style={{ marginRight: ".3em" }} /> Edit
          </Button>
        )}
      </SideBySide>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Content>
          {leftSide.map((item) => (
            <MilestoneItem
              key={item.milestoneId}
              {...item}
            />
          ))}
        </Content>
        <Content>
          {rightSide.map((item) => (
            <MilestoneItem
              key={item.milestoneId}
              {...item}
            />
          ))}
        </Content>
      </div>
    </Container>
  );
};

export default CountryGlobalMilestones;

const Container = styled.div`
  display: grid;
  grid-gap: 1em;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  margin-right: 1em;
  gap: 1em;
`;

const SideBySide = styled.div`
  display: flex;
  justify-content: space-between;
`;
