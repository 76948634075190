import { Cell, Row } from ".";

import React from "react";
import { TextField } from "@material-ui/core";
import { debounce } from "lodash";
import { useScheduleEvents } from "../../../../app/contexts/schedule.events.context";

export default function TableRow({ data }) {
  const [initialRender, setInitialRender] = React.useState(true);
  const { setInitialRiskAssessments, setGeneralChangeLog } =
    useScheduleEvents();
  const [localUpdates, setLocalUpdates] = React.useState({
    ...data,
  });

  const debouncedUpdate = React.useCallback(
    debounce((data) => {
      setInitialRiskAssessments((prev) => {
        const updated = prev.map((item) => {
          if (
            item.scheduleOfEventsRiskAssessmentId ===
            data.scheduleOfEventsRiskAssessmentId
          ) {
            return data;
          }
          return item;
        });
        return updated;
      });

      setGeneralChangeLog((prev) => ({
        ...prev,
        initialRiskAssessments: {
          type: "update",
          table: "initialRiskAssessments",
        },
      }));
    }, 500),
    []
  );

  React.useEffect(() => {
    if (!initialRender) {
      debouncedUpdate(localUpdates);
    } else {
      setInitialRender(false);
    }
    // eslint-disable-next-line
  }, [localUpdates, debouncedUpdate]);

  return (
    <Row>
      <Cell
        align="flex-start"
        justify="flex-start"
        textAlign="left"
        style={{ width: "10%", textAlign: "left" }}
      >
        {localUpdates.riskAssessmentCategory}
      </Cell>
      <Cell
        align="flex-start"
        justify="flex-start"
        textAlign="left"
        style={{ width: "22%", textAlign: "left" }}
      >
        <TextField
          multiline
          variant="outlined"
          value={localUpdates.riskAssessmentStudySpecifics}
          onChange={(e) => {
            const value = e.target.value;
            setLocalUpdates((prev) => ({
              ...prev,
              riskAssessmentStudySpecifics: value,
            }));
          }}
          style={{ width: "100%", maxHeight: "100%" }}
          size="small"
          textarea
          rows="4"
          inputProps={{ maxLength: 500 }}
        />
      </Cell>
      <Cell
        align="flex-start"
        justify="flex-start"
        textAlign="left"
        style={{ width: "8%", textAlign: "left" }}
      >
        <TextField
          variant="outlined"
          value={localUpdates.riskAssessmentRiskScore ?? ""}
          onChange={(e) => {
            // check if value is a positive number with 1 decimal place with max value of 3
            const regex =
              /^$|^(?:0(?:\.\d)?|1(?:\.[0-9]|\.0)?|2(?:\.[0-9])?|3(?:\.0)?)$/;
            if (!regex.test(e.target.value) || e.target.value >= 100) {
              return;
            }
            const value = e.target.value;
            setLocalUpdates((prev) => ({
              ...prev,
              riskAssessmentRiskScore: value,
            }));
          }}
          style={{ width: "100%" }}
          size="small"
          type="number"
        />
      </Cell>
      <Cell
        align="flex-start"
        justify="flex-start"
        textAlign="left"
        style={{
          width: "20%",
          "white-space": "pre-line",
          textAlign: "left",
        }}
      >
        {localUpdates.riskAssessmentLow?.trim()}
      </Cell>
      <Cell
        align="flex-start"
        justify="flex-start"
        textAlign="left"
        style={{
          width: "20%",
          "white-space": "pre-line",
          textAlign: "left",
        }}
      >
        {localUpdates.riskAssessmentModerate?.trim()}
      </Cell>
      <Cell
        align="flex-start"
        justify="flex-start"
        textAlign="left"
        style={{
          width: "20%",
          "white-space": "pre-line",
          textAlign: "left",
        }}
      >
        {localUpdates.riskAssessmentHigh?.trim()}
      </Cell>
    </Row>
  );
}
