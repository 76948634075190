import React from "react";
import { TextField } from "@material-ui/core";
import { debounce } from "lodash";

export default function VisitInputField({ onVisitsChange, sv }) {
  const [localValue, setLocalValue] = React.useState(sv.visitName);

  const debouncedUpdate = React.useCallback(
    debounce((name, value, key) => {
      onVisitsChange(name, value, key);
    }, 500),
    []
  );

  return (
    <TextField
      style={{ width: "100%" }}
      type="text"
      value={localValue}
      onChange={(e) => {
        setLocalValue(e.target.value);
        debouncedUpdate(
          "visitName",
          e.target.value,
          sv.scheduleOfEventsVisitId || sv.key
        );
      }}
      variant="outlined"
      size="small"
      inputProps={{
        maxLength: 50,
      }}
    />
  );
}
