import * as React from "react";
import { Button, List, ListItem, ListItemText } from "@material-ui/core";
import styled from "styled-components";
import { useMemo } from "react";

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

const STUDY_TYPES_NAMES = {
  1: "R",
  2: "D",
};

export default function TransferList({ items = [], setSelected }) {
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState(items);
  const [right, setRight] = React.useState([]);

  const checkedIds = useMemo(
    () => checked.map((ch) => ch.studyPlanId),
    [checked]
  );

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (index, value) => () => {
    const currentIndex = checkedIds.indexOf(index);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setSelected(right.concat(left));
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setSelected(right.concat(leftChecked));
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setSelected(not(right, rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setSelected([]);
    setRight([]);
  };

  const customList = (listItems) => (
    <ItemsContainer>
      <List
        dense
        component="div"
        role="list"
        style={{
          maxHeight: "16rem",
          minHeight: "16rem",
          paddingBottom: 0,
          paddingTop: 0,
          width: "100%",
        }}
      >
        {listItems.map((value) => {
          const labelId = `transfer-list-item-${value?.studyPlanId}-label`;

          return (
            <ListItem
              style={{
                marginBottom: ".1rem",
                backgroundColor:
                  checkedIds.indexOf(value?.studyPlanId) !== -1
                    ? "#002E6E"
                    : "",
                color:
                  checkedIds.indexOf(value?.studyPlanId) !== -1
                    ? "#FFF"
                    : "#000",
              }}
              key={value?.studyPlanId}
              role="listitem"
              button
              onClick={handleToggle(value?.studyPlanId, value)}
            >
              <ListItemText
                id={labelId}
                primary={`${value?.studyPlanName} - [${
                  STUDY_TYPES_NAMES[value.studyPlanType]
                }]`}
              />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </ItemsContainer>
  );

  return (
    <MainContainer>
      <HeaderContainer>
        <ItemsHeader>Available Study Plans</ItemsHeader>
        {customList(left)}
      </HeaderContainer>
      <ControlsContainer>
        <Button
          style={{ marginBottom: "5px" }}
          variant="outlined"
          size="small"
          onClick={handleAllRight}
          disabled={left.length === 0}
          aria-label="move all right"
        >
          ≫
        </Button>
        <Button
          style={{ marginBottom: "5px" }}
          variant="outlined"
          size="small"
          onClick={handleCheckedRight}
          disabled={leftChecked.length === 0}
          aria-label="move selected right"
        >
          &gt;
        </Button>
        <Button
          style={{ marginBottom: "5px" }}
          variant="outlined"
          size="small"
          onClick={handleCheckedLeft}
          disabled={rightChecked.length === 0}
          aria-label="move selected left"
        >
          &lt;
        </Button>
        <Button
          style={{ marginBottom: "5px" }}
          variant="outlined"
          size="small"
          onClick={handleAllLeft}
          disabled={right.length === 0}
          aria-label="move all left"
        >
          ≪
        </Button>
      </ControlsContainer>
      <HeaderContainer>
        <ItemsHeader>Selected Study Plans</ItemsHeader>
        {customList(right)}
      </HeaderContainer>
    </MainContainer>
  );
}

const ItemsHeader = styled.div`
  background: #00aeef;
  width: 100%;
  font-size: 1.2rem;
  text-align: center;
  padding: 0.3rem 0.5rem;
  border-bottom: 1px solid #555;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  align-self: center;
  border: 1px solid #555;
  border-radius: 5px;
  flex: 1;
  width: 100%;
`;

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 16rem;
  min-height: 16rem;
  overflow-y: auto;
  width: 100%;
`;

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 6rem;
  min-height: 16rem;
  align-self: center;
  max-height: 24rem;
  margin: 1rem;
  flex-gap: 1rem;
`;

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
