import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";
import MuiTooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

const ToolTip = ({ info = "No info added." }) => {
  const classes = useStyles();

  return (
    <MuiTooltip
      title={<Typography>{info}</Typography>}
      classes={{ tooltip: classes.tooltip }}
    >
      <InfoIcon className={classes.icon} />
    </MuiTooltip>
  );
};

export default ToolTip;

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "#002e6e",
    marginLeft: ".3em",
  },
  tooltip: {
    maxWidth: "600px"
  },
}));