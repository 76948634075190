import React from "react";
import styled from "styled-components";
import { useStudyPlan } from "../../../../../app/contexts/study.context";
import { useHistory, useParams } from "react-router-dom";
import { SectionHeader } from "../../../../../app/components";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import ScenarioA from "./scenario.a";
import ScenarioB from "./scenario.b";

const CountryInputs = (props) => {
  const { studyPlan, STUDY_PLAN_TYPES, canEditPlan } = useStudyPlan();
  const history = useHistory();
  const { id, planId } = useParams();

  const { studyPlanType } = studyPlan;
  const { SCENARIO_A, SCENARIO_B } = STUDY_PLAN_TYPES;
  let scenarioRender;
  if (studyPlanType === SCENARIO_A) {
    scenarioRender = <ScenarioA {...props} />;
  }

  if (studyPlanType === SCENARIO_B) {
    scenarioRender = <ScenarioB {...props} />;
  }

  return (
    <Container>
      <SideBySide>
        <SectionHeader>Country Inputs</SectionHeader>
        {canEditPlan && (
          <Button
            color="primary"
            onClick={() =>
              history.push(
                `/ctpo/study-plans/${id}/${planId}/countries-and-site-groups#country-inputs`,
                { tabIndex: props.tab }
              )
            }
          >
            <EditIcon style={{ marginRight: ".3em" }} /> Edit
          </Button>
        )}
      </SideBySide>
      <div>{scenarioRender}</div>
    </Container>
  );
};

export default CountryInputs;

const Container = styled.div`
  display: grid;
  grid-gap: 1em;
`;
const SideBySide = styled.div`
  display: flex;
  justify-content: space-between;
`;
