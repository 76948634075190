import { Cell, Container, Row } from "..";

import { Accordion } from "../../../../../app/components";
import EditIcon from "@material-ui/icons/Edit";
import { IconButton } from "@material-ui/core";
import React from "react";

const EDITABLE_FIELDS = [
  "Assessor Name",
  "CDA Review Factor of 100% SDR/SDV Time",
  "Additional Time on Site factor, based on initial risk score",
  "Estimated Activated Non-enrolling Sites",
  'Required "Administrative" IMV for Non-Enrolling Sites',
];

export default function VariablesTable({ data, setEditOpen }) {
  const renderTable = () => {
    return data.map((d, idx) => {
      return (
        <Row>
          <Cell
            variant="header"
            justify="flex-end"
            width="30%"
            textAlign="right"
            style={{
              borderBottom: "none",
              position: "relative",
              paddingLeft: "20px",
            }}
          >
            {d.rowLabel}
            {EDITABLE_FIELDS.includes(d.rowLabel) && (
              <IconButton
                variant="text"
                color="primary"
                onClick={() => setEditOpen(true)}
                style={{
                  position: "absolute",
                  left: "5px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  padding: "4px",
                }}
              >
                <EditIcon style={{ width: "1rem", height: "1rem" }} />
              </IconButton>
            )}
          </Cell>
          <Cell width="10%">{d.cellValue1}</Cell>
          <Cell textAlign="left" justify="flex-start" width="60%">
            {d.notes}
          </Cell>
        </Row>
      );
    });
  };

  return (
    <Accordion scrollIntoView={true} width={"100%"} summary={"Variables"}>
      <Container>{renderTable()}</Container>
    </Accordion>
  );
}
