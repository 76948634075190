import React, { useEffect, useMemo } from "react";
import {
  hasDuplicates,
  isIncreasing,
  nonNegative,
} from "../../../../../app/utils/array.functions";
import Alert from "@material-ui/lab/Alert";
import { SectionHeader } from "../../../../../app/components";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import ActivationProfilesTable from "./activationProfilesTable";

const TOTAL_COLUMNS = 11;

const createEmptyProfile = (index) => ({
  siteActivationProfileId: `empty-${index}`,
  siteActivationLabel: "",
  siteActivationProfilePct: null,
  siteActivationProfileDays: null,
});
const SiteGroupActivationProfiles = ({ country, siteGroup, setHasErrors }) => {
  const {
    siteGroupId,
    activationProfiles: originalProfiles,
    milestones,
  } = siteGroup;
  const { countryId } = country;

  const FSIV = milestones.find((m) => m.milestoneName === "DURATION_TO_FSIV");

  // Ensure we always have 11 profiles
  const activationProfiles = useMemo(() => {
    const profiles = [...originalProfiles];
    while (profiles.length < TOTAL_COLUMNS) {
      profiles.push(createEmptyProfile(profiles.length));
    }
    return profiles;
  }, [originalProfiles]);

  const filterNonEmptyValues = (arr) =>
    arr.filter(
      (value) => value !== undefined && value !== null && value !== "",
    );

  const percentagesArr = filterNonEmptyValues(
    activationProfiles?.map((item) => item.siteActivationProfilePct) || [],
  );
  const daysArr = filterNonEmptyValues(
    activationProfiles?.map((item) => item.siteActivationProfileDays) || [],
  );
  const isPercentageIncreasing = isIncreasing(percentagesArr);
  const percentagesHasDuplicates = hasDuplicates(percentagesArr);
  const isPercentageNonNegative = nonNegative(percentagesArr);
  const isPercentageIncreasingToOneHundred =
    percentagesArr[percentagesArr?.length - 1] === 1;
  const isDaysIncreasing = isIncreasing(daysArr);
  const isDaysNonNegative = nonNegative(daysArr);
  const daysHasDuplicates = hasDuplicates(daysArr);

  const errorsArray = useMemo(() => {
    const errors = [];
    if (!isPercentageIncreasing) errors.push("percentageIncreasing");
    if (percentagesHasDuplicates) errors.push("percentagesHasDuplicates");
    if (!isPercentageNonNegative) errors.push("percentageNonNegative");
    if (!isPercentageIncreasingToOneHundred)
      errors.push("percentageIncreasingToOneHundred");
    if (!isDaysIncreasing) errors.push("daysIncreasing");
    if (!isDaysNonNegative) errors.push("daysNonNegative");
    if (daysHasDuplicates) errors.push("daysHasDuplicates");
    return errors;
  }, [
    isPercentageIncreasing,
    percentagesHasDuplicates,
    isPercentageNonNegative,
    isPercentageIncreasingToOneHundred,
    isDaysIncreasing,
    isDaysNonNegative,
    daysHasDuplicates,
  ]);

  useEffect(() => {
    setHasErrors(errorsArray);
  }, [errorsArray, setHasErrors]);

  const alerts = [
    {
      condition: !isPercentageIncreasing,
      message: "Percentages should be increasing.",
    },
    {
      condition: !isPercentageNonNegative,
      message: "Percentages should be non-negative.",
    },
    {
      condition: percentagesHasDuplicates,
      message: "Percentages should not have duplicates.",
    },
    {
      condition: !isPercentageIncreasingToOneHundred,
      message: "Percentages should be approaching 1.",
    },
    { condition: !isDaysIncreasing, message: "Days should be increasing." },
    { condition: !isDaysNonNegative, message: "Days should be non-negative." },
    {
      condition: daysHasDuplicates,
      message: "Days should not have duplicates.",
    },
  ];

  return (
    <Container id="activation-profiles">
      <SectionHeader>Activation Profiles</SectionHeader>
      <Typography>
        Site ramp up is modeled using the below data. Both the percentage and
        days can be updated to match the site ramp up that is expected in this
        country for this specific study. The percentage is the cumulative
        percentage of sites activated. The days is the days since the First Site
        Activation. Ensure that the numbers (both percentage and days) increase
        from left to right with the rightmost values indicating when all sites
        are initiated (percentage equal to 1).
      </Typography>
      <div style={{ display: "grid", gridGap: "1em" }}>
        {alerts
          .filter((alert) => alert.condition)
          .map((alert, index) => (
            <Alert
              key={index}
              severity="error"
            >
              {alert.message}
            </Alert>
          ))}
      </div>
      <ActivationProfilesTable
        activationProfiles={activationProfiles}
        country={country}
        siteGroupId={siteGroupId}
        FSIV={FSIV}
        countryId={countryId}
      />
    </Container>
  );
};

export default SiteGroupActivationProfiles;

const Container = styled.div``;
