export const isNumberNotEmpty = (val) => {
  return !(
    (!isNaN(val) && val.toString().length === 0) ||
    val === null ||
    val === undefined
  );
};

export const isValidNumberStringValues = (val) => {
  return /^-?\d*\.?\d*$/.test(val);
};

export const checkNumberLength = (
  value,
  wholeLength,
  afterDecimal,
  maxValue,
  allowDecimal = false
) => {
  if (maxValue) {
    if (value.toString().split(".").length > 1 && !allowDecimal) return false;
    return parseFloat(value) > maxValue || parseFloat(value) < maxValue * -1
      ? false
      : allowDecimal && value.toString().length > wholeLength
      ? false
      : true;
  }
  if (value.toString().length > wholeLength) return false;
  const numValues = value.toString().split(".");
  if (numValues.length === 1) return true;
  if (afterDecimal === 0) return false;
  if (numValues[1].length > afterDecimal) return false;
  return true;
};
