import React from "react";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import { useStudyPlan } from "../../../../app/contexts/study.context";
import { makeStyles } from "@material-ui/styles";
import CustomDatePicker from "../../../../app/components/custom.date.picker";

const PrimaryDetails = ({ errors, setValues, values }) => {
  const { studyPlan, updatePlan } = useStudyPlan();
  const classes = useStyles();
  const handleChange = (target, value) => {
    updatePlan(target, value);
  };

  return (
    <Container id="details">
      <div
        style={{
          display: "flex",
          width: "100%",
          marginBlock: "1rem",
        }}
      >
        <TextField
          style={{ width: "75%", marginRight: "1rem" }}
          value={studyPlan.studyPlanName}
          onChange={(e) => {
            handleChange("studyPlanName", e.target.value);
            setTimeout(() => {
              // trigger formic form update so that we can validate fields
              setValues({ ...values, updated: new Date().getTime() });
            }, 500);
          }}
          variant="outlined"
          label="Study Plan Name"
          InputLabelProps={{ classes }}
          error={errors.studyPlanName}
          required
          inputProps={{
            maxLength: 50,
          }}
          helperText={
            <AlignBetween>
              <span>{errors.studyPlanName}</span>
              <span>{`${(studyPlan.studyPlanName || "").length} / ${50}`}</span>
            </AlignBetween>
          }
        />
        <CustomDatePicker
          style={{ width: "25%" }}
          label="CRO Start Date"
          value={studyPlan.croProjectStartDateMillis}
          onChange={(date) =>
            handleChange("croProjectStartDateMillis", date.getTime())
          }
          showYearDropdown
          showMonthDropdown
          useShortMonthInDropdown
          dateFormat={"dd/MMM/yyyy"}
          required
        />
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <TextField
          style={{ width: "75%", marginRight: "1rem" }}
          value={studyPlan.studyPlanDescr}
          onChange={(e) => {
            handleChange("studyPlanDescr", e.target.value);
            setTimeout(() => {
              // trigger formic form update so that we can validate fields
              setValues({ ...values, updated: new Date().getTime() });
            }, 500);
          }}
          variant="outlined"
          label="Description"
          InputLabelProps={{ classes }}
          error={errors.studyPlanDescr}
          helperText={errors.studyPlanDescr}
          inputProps={{
            maxLength: 2000,
          }}
          required
        />
        <CustomDatePicker
          style={{ width: "25%", overflow: "hidden" }}
          label="Protocol Date"
          value={studyPlan.finalProtocolStartDateMillis}
          onChange={(date) =>
            handleChange("finalProtocolStartDateMillis", date.getTime())
          }
          showYearDropdown
          showMonthDropdown
          useShortMonthInDropdown
          dateFormat={"dd/MMM/yyyy"}
          required
        />
      </div>
    </Container>
  );
};

export default PrimaryDetails;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const useStyles = makeStyles({
  asterisk: {
    color: "red",
  },
});

const AlignBetween = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
