export const COLUMN_HEADERS = {
  "Site Initiated": "Site Activated",
  Screened: "Screened",
  Enrolled: "Enrolled",
  "Completed Treatment": "Completed Treatment",
  "Completed Follow-up": "Completed Follow-up",
};

export const DEFAULT_DATE = "DD/MMM/YYYY";

export const COLUMS_MAP = {
  "Sites Initiated": "Sites Initiated",
  Screened: "Screened",
  Enrolled: "Enrolled",
  "Completed Treatment": "Completed Treatment",
  "Completed Follow-up": "Completed Follow-up",
};
