import React, { useState, useRef } from "react";
import MonthlyChart from "./monthly.chart";
import WeeklyChart from "./weekly.chart";
import CohortChartMonthly from "./cohort.chart.monthly";
import CohortTimelineChart from "./cohort.timeline.chart";
import ViewSelector from "./view.selector";
import ChartMetrics from "./chart.metrics";
import ChartControls from "./chart.controls";
import html2canvas from "html2canvas";
const Charts = () => {
  const [view, setView] = useState("region");
  const [chartIds, setChartIds] = useState([]);
  const canvasRefs = useRef({
    CohortsTimeline: React.createRef(),
    MonthlyEnrollment: React.createRef(),
    WeeklyEnrollment: React.createRef(),
    PatientsByGroup: React.createRef(),
  });

  const downloadImage = async () => {
    for (const chartId of chartIds) {
      const canvasRef = canvasRefs.current[chartId];
      if (canvasRef && canvasRef.current) {
        try {
          const canvas = await html2canvas(canvasRef.current);
          const image = canvas.toDataURL("image/png", 1.0);
          const link = document.createElement("a");
          link.href = image;
          link.download = `${chartId}.png`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          
          // Add a small delay between downloads
          await new Promise(resolve => setTimeout(resolve, 50));
        } catch (error) {
          console.error(`Error exporting ${chartId}:`, error);
        }
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "2em",
        minHeight: "20rem",
        background: `linear-gradient(to bottom, white 3em, #F7F8FA 3em)`,
        marginTop: "-3.5em",
      }}
    >
      <ChartControls
        setChartIds={setChartIds}
        downloadImage={() => downloadImage()}
      ></ChartControls>
      <div
        ref={canvasRefs.current.MonthlyEnrollment}
        style={{
          backgroundColor: "white",
          width: "80%",
          margin: "0 auto",
          marginTop: "2em",
        }}
      >
        <ChartMetrics />
        <MonthlyChart view={view} />
        <ViewSelector
          value={view}
          onChange={(newValue) => setView(newValue)}
        />
      </div>

      <div
        ref={canvasRefs.current.WeeklyEnrollment}
        style={{ backgroundColor: "white", width: "80%", margin: "0 auto" }}
      >
        <ChartMetrics />
        <WeeklyChart view={view} />
        <ViewSelector
          value={view}
          onChange={(newValue) => setView(newValue)}
        />
      </div>

      <div
        ref={canvasRefs.current.PatientsByGroup}
        style={{ backgroundColor: "white", width: "80%", margin: "0 auto" }}
      >
        <CohortChartMonthly />
      </div>
      <div ref={canvasRefs.current.CohortsTimeline}>
        <CohortTimelineChart />
      </div>
    </div>
  );
};

export default Charts;
