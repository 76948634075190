import { Cell, Container, Row } from "..";

import { Accordion } from "../../../../../app/components";
import { Edit as EditIcon } from "@material-ui/icons";
import EditNotesInput from "../edit.notes.input";
import { IconButton } from "@material-ui/core";
import React from "react";

const EDITABLE_FIELDS = [
  "CDA Study Level Review Report_Frequency",
  "Ad Hoc Reviews based on intra-study milestones (IA, IDMC, SDM)",
];

export default function CDAStudyTable({ data, setEditOpen }) {
  const [editState, setEditState] = React.useState({});

  const renderHeader = () => {
    return (
      <Row>
        <Cell
          variant="header"
          justify="flex-end"
          width="30%"
          style={{ borderBottom: "none" }}
          textAlign="right"
        />
        <Cell variant="header" width="10%">
          Number
        </Cell>
        <Cell variant="header" width="10%">
          Hours
        </Cell>
        <Cell variant="header" width="10%" />
        <Cell variant="header" width="40%">
          Notes
        </Cell>
      </Row>
    );
  };
  const renderRows = () => {
    return data.map((d, idx) => {
      return (
        <Row>
          <Cell
            variant="header"
            justify="flex-end"
            width="30%"
            style={{
              borderBottom: "none",
              position: "relative",
              paddingLeft: "20px",
            }}
            textAlign="right"
          >
            {d.rowLabel}
            {EDITABLE_FIELDS.includes(d.rowLabel) && (
              <IconButton
                variant="text"
                color="primary"
                onClick={() => setEditOpen(true)}
                style={{
                  position: "absolute",
                  left: "5px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  padding: "4px",
                }}
              >
                <EditIcon style={{ width: "1rem", height: "1rem" }} />
              </IconButton>
            )}
          </Cell>
          <Cell width="10%">{d.cellValue1}</Cell>
          <Cell width="10%">{d.cellValue2}</Cell>
          <Cell width="10%">{d.cellValue3}</Cell>
          <Cell
            style={{ position: "relative" }}
            textAlign="left"
            justify="flex-start"
            width="40%"
          >
            <EditNotesInput
              d={d}
              editState={editState}
              setEditState={setEditState}
            />
          </Cell>
        </Row>
      );
    });
  };

  return (
    <Accordion
      scrollIntoView={true}
      width={"100%"}
      summary={"CDA Study Review Time"}
    >
      <Container>
        {renderHeader()}
        {renderRows()}
      </Container>
    </Accordion>
  );
}
