import { Redirect, Route } from "react-router-dom";

import React from "react";
import { useStudyPlan } from "../../../../app/contexts/study.context";

export default function EditRoute({
  component: Component,
  additionalProps = {},
  ...rest
}) {
  const { canEditPlan, studyPlan } = useStudyPlan();
  const { studyPlanId, studyId } = studyPlan;
  return (
    <Route
      {...rest}
      render={(props) => {
        return canEditPlan ? (
          <Component {...props} {...additionalProps} />
        ) : (
          <Redirect to={`/ctpo/study-plans/${studyId}/${studyPlanId}/review`} />
        );
      }}
    />
  );
}
