import React, { useState } from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/styles";
import DeleteCountyModal from "./delete.country.modal";
import EditCountryModal from "./edit.country.modal";

const CountryHeader = ({
  title,
  countryId,
  siteGroup,
  variant = "primary",
  hasDelete = true,
  hasEdit = true,
  studyPlanType,
  currentEffort,
  currentTimeframe,
}) => {
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const classes = useStyles();
  return (
    <Container variant={variant}>
      <Typography classes={classes}>
        <strong>{title}</strong>
      </Typography>
      <div>
        {hasEdit && (
          <IconButton onClick={() => setOpenEdit(true)}>
            <EditIcon classes={classes} />
          </IconButton>
        )}
        {hasDelete && (
          <IconButton onClick={() => setOpen(true)}>
            <DeleteIcon classes={classes} />
          </IconButton>
        )}
      </div>
      {hasDelete && (
        <DeleteCountyModal
          countryId={countryId}
          siteGroup={siteGroup}
          open={open}
          onClose={() => setOpen(false)}
        />
      )}
      {hasEdit && openEdit && (
        <EditCountryModal
          countryId={countryId}
          siteGroup={siteGroup}
          studyPlanType={studyPlanType}
          open={openEdit}
          onClose={() => setOpenEdit(false)}
          currentEffort={currentEffort}
          currentTimeframe={currentTimeframe}
        />
      )}
    </Container>
  );
};

export default CountryHeader;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 1em;
  align-items: center;
  border-radius: 4px;
  background-color: ${(p) => (p.variant === "primary" ? "#002e6e" : "#808285")};
  height: 48px;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    color: "white",
  },
}));
