import React from "react";
import styled from "styled-components";
import { SectionHeader } from "../../../../../app/components";
import { useStudyPlan } from "../../../../../app/contexts/study.context";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import { useHistory, useParams } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import ScenarioA from "./scenario.a";
import ScenarioB from "./scenario.b";

const baseText =
  "Site Fatigue or a time-based erosion factor for enrollment can be applied using the below fields. This should be only applied when you expect the enrollment rate to decrease after a period of time. First, complete the number of days after site activation when site fatigue is expected to start.";

const scenarioSpecificTexts = {
  SCENARIO_A:
    "In the second field include the new lower enrollment rate that you expect.",
  SCENARIO_B:
    "In the second field include the percent (in decimals) of the former rate that is expected. Enter 0.75 if you expect the rate to be 75% of what it formally was.",
};

const SiteFatigue = (props) => {
  const { studyPlan, STUDY_PLAN_TYPES, canEditPlan } = useStudyPlan();
  const history = useHistory();
  const { id, planId } = useParams();
  const { studyPlanType } = studyPlan;
  const { SCENARIO_A, SCENARIO_B } = STUDY_PLAN_TYPES;

  let scenarioRender;
  let typographyText;

  if (studyPlanType === SCENARIO_A) {
    scenarioRender = <ScenarioA {...props} />;
    typographyText = `${baseText} ${scenarioSpecificTexts.SCENARIO_A}`;
  }

  if (studyPlanType === SCENARIO_B) {
    scenarioRender = <ScenarioB {...props} />;
    typographyText = `${baseText} ${scenarioSpecificTexts.SCENARIO_B}`;
  }

  return (
    <Container>
      <SideBySide>
        <SectionHeader>Site Fatigue</SectionHeader>
        {canEditPlan && (
          <Button
            color="primary"
            onClick={() =>
              history.push(
                `/ctpo/study-plans/${id}/${planId}/countries-and-site-groups#site-fatigue`,
                { tabIndex: props.tab },
              )
            }
          >
            <EditIcon style={{ marginRight: ".3em" }} /> Edit
          </Button>
        )}
      </SideBySide>
      <Typography>{typographyText}</Typography>
      <div>{scenarioRender}</div>
    </Container>
  );
};

export default SiteFatigue;

const Container = styled.div``;
const SideBySide = styled.div`
  display: flex;
  justify-content: space-between;
`;
