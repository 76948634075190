import { Divider } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";

const UserMenu = () => {
  const history = useHistory();
  const classes = useStyles();
  const { authState, oktaAuth } = useOktaAuth();
  const { idToken } = authState;

  if (!idToken) return null;

  const { name } = idToken.claims;

  return (
    <Container>
      <Select
        className={classes.root}
        disableUnderline
        value={name.toUpperCase()}
        inputProps={{ classes: { icon: classes.icon } }}
        renderValue={(selected) => (
          <div className={classes.selectLabel}>
            <span>{selected.charAt(0).toUpperCase() + selected.slice(1)}</span>
          </div>
        )}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          getContentAnchorEl: null,
        }}
      >
        <MenuItem
          onClick={(e) => {
            e.preventDefault();
            history.push(`/ctpo/admin-data/`);
          }}
          value="admin"
        >
          Admin Data
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() =>
            oktaAuth.signOut({
              postLogoutRedirectUri: window.location.origin + "/goodbye",
            })
          }
        >
          Sign Out
        </MenuItem>
      </Select>
    </Container>
  );
};

export default UserMenu;

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const useStyles = makeStyles((theme) => ({
  avatar: { color: "#cada2a", marginRight: theme.spacing(1) },
  root: {
    color: "#B7BFCF",
    fontSize: "15px",
    "&:hover": {
      color: theme.palette.common.white,
    },
    "& .MuiMenuItem-root": {
      fontFamily: "Roboto",
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.common.white,
      },
    },
  },
  icon: {
    color: "#B7BFCF",
  },
  selectLabel: {
    display: "flex",
    alignItems: "center",
    fontSize: "15px",
  },
}));