export const HEADERS = (visits) => {
  return visits.map(
    (visit) =>
      `${visit.visitCategory}-${visit.visitName}-${visit.visitIntervalType}-${
        visit.visitStudyDay
      } ${
        visit.visitVarianceType
          ? visit?.visitVarianceType + visit.visitVariance
          : ""
      }`
  );
};

export const MAPPING_TOTAL_HEADERS = [
  "Assessment Count",
  "Assessment Cost",
  "Visit Overhead",
  "Total Visit Cost",
  "Unique CRFs",
  "Non Unique CRFs",
];
