import React, { useMemo } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import { makeStyles } from "@material-ui/styles";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { useScheduleEvents } from "../../../../app/contexts/schedule.events.context";

const COLUMNS = {
  siteGroupName: "Country",
  "Number of Sites": "Number of Sites",
  "Screen Fails": "Screen Fails",
  "Completed Treatment": "Completed Treatment",
  "Drop-out of Treatment": "Drop-out of Treatment",
  "Patients Complete Follow-up": "Cost Patients Complete Follow-up",
  "Site Fees": "Site Fees",
};

export default function DetailRFPTable({ details }) {
  const classes = useStyles();
  const classes_rows = useRowStyles();
  const classes_cells = useCellStyles();
  const { setFormattedForecastRFPDetail } = useScheduleEvents();

  const data = useMemo(() => {
    let d = details.map((detail) => {
      if (detail.dataSet === "Total") {
        detail.siteGroupName = "Total";
      }
      return detail;
    });
    // make sure that total is at the bottom
    let total = d.find((detail) => detail.siteGroupName === "Total");
    if (total) {
      d = d.filter((detail) => detail.siteGroupName !== "Total");
      d.push(total);
    }
    setFormattedForecastRFPDetail({ columns: COLUMNS, data: d });
    return d;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details]);

  const renderRow = (row) => {
    return (
      <>
        <TableCell align="center">{row["siteGroupName"] ?? "##"}</TableCell>
        <TableCell align="center">{row["Number of Sites"] ?? "##"}</TableCell>
        <TableCell align="center">{row["Screen Fails"] ?? "##"}</TableCell>
        <TableCell align="center">
          {row["Completed Treatment"] ?? "##"}
        </TableCell>
        <TableCell align="center">
          {row["Drop-out of Treatment"] ?? "##"}
        </TableCell>
        <TableCell align="center">
          {row["Patients Complete Follow-up"] ?? "##"}
        </TableCell>
        <TableCell align="center">{row["Site Fees"] ?? "##"}</TableCell>
      </>
    );
  };
  return (
    <Container>
      <Typography className={classes.heading}>Investigator Grants</Typography>
      {data.length ? (
        <TableContainer style={{ width: "100%" }}>
          <Table aria-label="customized table" className="table-container">
            <TableHead>
              <TableRow variant="head" classes={classes_rows}>
                {Object.keys(COLUMNS).map((col) => (
                  <TableCell key={col} align="center" classes={classes_cells}>
                    <p>{COLUMNS[col]}</p>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow
                  classes={row["siteGroupName"] === "Total" ? classes_rows : {}}
                  key={index}
                >
                  {renderRow(row)}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        "No Results Found"
      )}
    </Container>
  );
}

const Container = styled.div`
  overflow-x: auto;
`;

const useRowStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#e6e7e8",
  },
}));

const useCellStyles = makeStyles((theme) => ({
  root: {
    fontWeight: 600,
  },
}));

const useStyles = makeStyles((theme) => ({
  heading: {
    color: "#002e6e",
    fontSize: "1.2rem",
    fontWeight: "600",
    marginBottom: "1rem",
  },
  root: {
    padding: 0,
  },
}));
