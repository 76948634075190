import styled from "styled-components";

export default styled.div`
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  background-color: #f3f4f4;
  padding: 1em;
  display: grid;
  grid-gap: 1em;
`;
