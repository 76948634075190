import React from "react";
import styled from "styled-components";
import { useStudyPlan } from "../../../../app/contexts/study.context";
import { useQuery } from "../../../../app/hooks/useQuery";

import ScenarioA from "./scenario.a";
import ScenarioB from "./scenario.b";

const OverallStudyInputs = ({
  values,
  errors,
  touched,
  setValues,
  setFieldErrors,
}) => {
  const { studyPlan, STUDY_PLAN_TYPES } = useStudyPlan();
  const query = useQuery();
  const { studyPlanType } = studyPlan;
  const { SCENARIO_A, SCENARIO_B } = STUDY_PLAN_TYPES;

  let renderScenario;
  if (studyPlanType === SCENARIO_A || query.get("s") === "scenario-a") {
    renderScenario = (
      <ScenarioA
        values={values}
        setValues={setValues}
        setFieldErrors={setFieldErrors}
      />
    );
  }

  if (studyPlanType === SCENARIO_B || query.get("s") === "scenario-b") {
    renderScenario = (
      <ScenarioB
        values={values}
        setValues={setValues}
        setFieldErrors={setFieldErrors}
      />
    );
  }

  return (
    <div
      key={studyPlan.studyPlanId}
      id="overall-inputs"
    >
      <Container>{renderScenario}</Container>
    </div>
  );
};

export default OverallStudyInputs;

const Container = styled.div``;
