import { useHistory, useParams } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import styled from "styled-components";
import { useSnackbar } from "../../../../app/contexts/snackbar.context";
import { useStudyPlan } from "../../../../app/contexts/study.context";

const BottomControls = ({ errors, fieldErrors }) => {
  const history = useHistory();
  const { id, planId } = useParams();
  const { pathname } = history.location;
  const { showSnackbar } = useSnackbar();
  const {
    studyPlan,
    savePlan,
    saveLoading,
    unsavedChange,
    validateSequentialCohort,
    validateSeasonalVariations,
    hideEnrollmentDuration,

    isSequential,
  } = useStudyPlan();
  const {
    studyPlanName,
    croProjectStartDateMillis,
    studyPlanDescr,
    finalProtocolStartDateMillis,
    treatmentDuration,
    screeningPeriodDuration,
    overallEnrollmentDuration,
  } = studyPlan;
  const [sites_to_activate, subjects_to_screen] = studyPlan?.parameters;

  const pushToNextPage = () => {
    const seqValid = isSequential
      ? validateSequentialCohort(history.location.pathname)
      : true;

    const seasonalValid = validateSeasonalVariations();
    if (
      (Object.keys(errors || {}).length !== 0 &&
        ["ALL"].includes(errors.BUTTON_TYPE)) ||
      !seqValid ||
      !seasonalValid
    ) {
      showSnackbar(
        <ul>
          {Object.keys(errors || {}).map((key) => {
            if (key === "ENROLLMENT_DURATION") return "";
            if (key !== "BUTTON_TYPE") return <li>{errors[key]}</li>;
            return "";
          })}
          {!seqValid && <li>Sequential cohorts setup is not valid</li>}
          {!seasonalValid && (
            <li>Country enrollment seasonal variations are not valid</li>
          )}
        </ul>,
        "error",
      );
      return;
    }
    if (pathname.includes("new-plan")) {
      savePlan({
        isNew: true,
        redirect: (newPlanId) => {
          history.push(
            `/ctpo/study-plans/${id}/${newPlanId}/countries-and-site-groups`,
          );
        },
      });
    } else if (unsavedChange) {
      savePlan({
        isNew: false,
        redirect: () =>
          history.push(
            `/ctpo/study-plans/${id}/${planId}/countries-and-site-groups`,
          ),
      });
    } else {
      history.push(
        `/ctpo/study-plans/${id}/${planId}/countries-and-site-groups`,
      );
    }
  };

  const handleDisabled = () => {
    const mustValidateFields = ["studyPlanName", "studyPlanDescr"];
    for (let i = 0; i < mustValidateFields.length; i++) {
      if (errors[mustValidateFields[i]]) {
        return true;
      }
    }

    const hasErrors = Object.values(fieldErrors).some(
      (field) => field.errors && field.errors.length > 0,
    );

    return (
      hasErrors ||
      !studyPlanName ||
      !croProjectStartDateMillis ||
      !studyPlanDescr ||
      !finalProtocolStartDateMillis ||
      !studyPlanName ||
      !croProjectStartDateMillis ||
      !studyPlanDescr ||
      !finalProtocolStartDateMillis ||
      sites_to_activate.lowerBound === null ||
      !(sites_to_activate.lowerBound > 0) ||
      subjects_to_screen.lowerBound === null ||
      !(subjects_to_screen.lowerBound > 0) ||
      treatmentDuration === null ||
      !(treatmentDuration > 0) ||
      screeningPeriodDuration === null ||
      !(screeningPeriodDuration >= 0) ||
      (!hideEnrollmentDuration &&
        (overallEnrollmentDuration === null ||
          !(overallEnrollmentDuration >= 0)))
    );
  };

  return (
    <Container>
      <Button disabled>Back</Button>
      <Button
        variant="contained"
        color="primary"
        onClick={pushToNextPage}
        disabled={handleDisabled() || saveLoading}
      >
        {saveLoading ? <CircularProgress size="1em" /> : "Next Step"}
      </Button>
    </Container>
  );
};

export default BottomControls;

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1em;
  margin-bottom: 1em;
`;
