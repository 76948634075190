import { Button, LinearProgress, TextField } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { Info as InfoIcon } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import API from "../../../../../src/app/utils/api";
import FileUploader from "../../../../app/components/file.uploader";
import CustomModal  from "../../../../app/components/modal.no.close";
import styled from "styled-components";
import { useSnackbar } from "../../../../app/contexts/snackbar.context";
import { useUser } from "../../../../app/contexts/user.context";

// Define custom styles for the tooltip
const tooltipStyles = {
  tooltip: {
    backgroundColor: "rgba(128, 128, 128, 0.95)", // Change the background color to 95% transparent grey
    color: "white",
    fontSize: "15px",
    padding: "10px",
    maxWidth: "500px",
  },
};

// Wrap the Tooltip component with the custom styles
const StyledTooltip = withStyles(tooltipStyles)(Tooltip);

// UPLOAD STEPS - UPLOAD_FILE, ENTER_FILE_ATTRS, UPLOAD_PROTOCOL
const UPLOAD_STEPS = {
  UPLOAD_FILE: "UPLOAD_FILE",
  ENTER_FILE_ATTRS: "ENTER_FILE_ATTRS",
  UPLOAD_PROTOCOL: "UPLOAD_PROTOCOL",
};

/*
  This component is used to upload a protocol for a schedule of events.
  It uses the user context to handle the upload of the protocol.
  The parent component must pass a callback function to handle the protocol upload.
*/
const UploadSOEProtocolModal = ({ soe, fetchSOEValues, ...props }) => {
  const { showSnackbar } = useSnackbar();
  const user = useUser();
  const [progress, setProgress] = useState(100 / 60);
  const [status, setStatus] = useState("");
  const [files, setFiles] = useState([]);
  const [step, setStep] = useState("UPLOAD_FILE");
  const [fileAttributes, setFileAttributes] = useState({
    pageNumbers: "",
    visitCategoryLabel: "",
    visitNameLabel: "",
    visitIntervalLabel: "",
    visitWindowLabel: "",
  });
  const [loading, setLoading] = useState(false);
  const handleChange = async (files) => {
    if (files.length === 0) return;
    setFiles(files);
  };

  const pageNumbersValid = useMemo(() => {
    const regex = /^(\d+,)*\d+$/;
    return (
      regex.test(fileAttributes.pageNumbers) ||
      fileAttributes.pageNumbers === ""
    );
  }, [fileAttributes.pageNumbers]);

  const handleUpload = async () => {
    if (files.length === 0) return;
  
    setLoading(true);
  
    try {
      const base64EncodedString = await files[0]
        .arrayBuffer()
        .then((buffer) => {
          const base64String = btoa(
            new Uint8Array(buffer).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          return base64String;
        });
  
      const attributeValues = Object.keys(fileAttributes).reduce((acc, key) => {
        if (key !== "pageNumbers") {
          acc += `${key}:${fileAttributes[key] || '""'},`;
        }
        if (key === "pageNumbers" && fileAttributes[key] !== "")
          acc += `pdfPageNumbers:[${fileAttributes[key]}],`;
        else if (key === "pageNumbers" && fileAttributes[key] === "")
          acc += `pdfPageNumbers:"",`;
        return acc;
      }, "");
  
      const payload = {
        FileType: "StudyProtocol",
        FileId: soe.scheduleOfEventsId,
        FileName: files[0].name,
        // base 64 encoded data
        encodedData: base64EncodedString,
        FileAttrs: `{FileIdKey: 'scheduleOfEventsId',scheduleOfEventsId:${soe.scheduleOfEventsId},studyId:${soe.studyId},${attributeValues}pdfFields:[Version,ProtocolName,Start]}`,
        Description: "",
        InsertedBy: user.username,
      };
  
      await API.uploadProtocol(payload);
  
      const progressInterval = setInterval(() => {
        setProgress((oldProgress) => {
          try {
            API.getSOE(soe.scheduleOfEventsId).then((res) => {
              if (res.data.length) {
                const uploadStatus = res.data[0].uploadStatus;
                setStatus(uploadStatus);
      
                if (uploadStatus.length > 40) {
                  if (uploadStatus.toLowerCase().includes("failed")) {
                    showSnackbar("Failed to upload", "error");
                    clearInterval(progressInterval);
                    setLoading(false);
                  } else {
                    showSnackbar("Uploaded successfully", "success");
                    clearInterval(progressInterval);
                    setLoading(false);
                  }
                }
              }
            });
          } catch (err) {
            console.log(err);
          }
      
          return oldProgress;
        });
      }, 10000);
    } catch (error) {
      console.log(error);
      showSnackbar("Failed to upload", "error");
      setLoading(false);
    }
  };

  const lastContinue = () => {
    fetchSOEValues();
    props.onClose();
  };

  const nextStep = () => {
    if (files.length === 0) return;
    const nextStepValue =
      step === UPLOAD_STEPS.UPLOAD_FILE
        ? UPLOAD_STEPS.ENTER_FILE_ATTRS
        : UPLOAD_STEPS.UPLOAD_PROTOCOL;
    setStep(nextStepValue);
    if (nextStepValue === UPLOAD_STEPS.UPLOAD_PROTOCOL) {
      handleUpload();
    }
  };

  const enableContinue =
    files.length === 0 ||
    (step === UPLOAD_STEPS.ENTER_FILE_ATTRS && !pageNumbersValid);

  return (
    <CustomModal
      size={700}
      {...props}
      onClose={step !== UPLOAD_STEPS.UPLOAD_PROTOCOL && props.onClose}
      aria-labelledby="Upload Protocol"
      aria-describedby="upload-protocol"
      title="Upload Protocol"
    >
      <Container>
        {step === UPLOAD_STEPS.UPLOAD_FILE && (
          <VContainer>
            <FileUploader
              // accepted files - pdf, doc, docx, xls, xlsx
              acceptedFiles={{
                "application/pdf": [],
                // "application/msword" : [],
                // "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [],
                // "application/vnd.ms-excel": [],
                // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
              }}
              onFileUpload={(event) => handleChange(event)}
            />
          </VContainer>
        )}
        {step === UPLOAD_STEPS.ENTER_FILE_ATTRS && (
          <VContainer>
            <InstructionTextContainer>
              <InstructionText>
                Note: The Upload action enables the process of creating the
                Schedule of Events. If the Protocol that is being uploaded does
                not follow the instructions for upload or is complex, the SOE must
                be entered directly into the SOE table.
              </InstructionText>
            </InstructionTextContainer>
            <TooltipContainer>
              <StyledTooltip
                title={
                  <div>
                    <strong>Good to Know… (tips to get the most accurate data extracted from the Protocol)</strong>
                    <ul>
                      <li>At this time only PDFs can be uploaded. If the Protocol is a Word document, print it to PDF first and then upload the PDF.</li>
                      <li>If the attributes listed above (4) are not available in the SOE table in the Protocol document, leave them blank. The extract process will extract as much data as it can find even without the additional attributes provided.</li>
                      <li>When uploading a Protocol, any Visits or Assessments previously entered into Premier Predict will be replaced with the data from the uploaded Protocol PDF (the 'default' Assessment records will remain).</li>
                      <li>The extraction of the mapping of Assessments to Visits will look for an "x" or a "+" in the table cell (any other text in the cell will be translated as an "x"). An "x" or "+" work best and is the most likely character to be extracted accurately. If there are no "x" or "+" values in the SOE mapping table, the extraction process may not be able to identify which Assessments map to Visits.</li>
                      <li>If you convert a Word or Excel table into a PDF, be sure you include formatted cells borders before converting to PDF as the extract process uses the black lines of the cells borders to identify the individual cells.</li>
                      <li>If the SOE table spans multiple pages, the visit names have to be consistent across all pages.</li>
                      <li>PDF Images can also be uploaded. If there are any watermarks in the image, that can impact the accuracy of the extraction. If possible, remove any watermarks before uploading.</li>
                      <li>If uploading a PDF Image, the Page Numbers where the SOE table is located is required.</li>
                    </ul>
                  </div>
                }
                placement="right"
              >
                <StyledInfoIcon />
              </StyledTooltip>
            </TooltipContainer>
            <TextField
              helperText={"Please enter comma separated numbers with no spaces"}
              id="page_numbers"
              label="Page Numbers"
              variant="outlined"
              value={fileAttributes.pageNumbers}
              onChange={(event) =>
                setFileAttributes({
                  ...fileAttributes,
                  pageNumbers: event.target.value,
                })
              }
            />
            <TextField
              helperText={
                "Please enter the row # from the SOE table where the Visit Category can be found"
              }
              id="visit_category_label"
              label="Visit Category Row #"
              variant="outlined"
              value={fileAttributes.visitCategoryLabel}
              onChange={(event) =>
                setFileAttributes({
                  ...fileAttributes,
                  visitCategoryLabel: event.target.value,
                })
              }
            />
            <TextField
              helperText={
                "Please enter the row # from the SOE table where the Visit Name can be found"
              }
              id="visit_name_label_row"
              label="Visit Name Row #"
              variant="outlined"
              value={fileAttributes.visitNameLabel}
              onChange={(event) =>
                setFileAttributes({
                  ...fileAttributes,
                  visitNameLabel: event.target.value,
                })
              }
            />
            <TextField
              helperText={
                "Please enter the row # from the SOE table where the Visit Interval can be found"
              }
              id="visit_interval_label"
              label="Visit Interval Row #"
              variant="outlined"
              value={fileAttributes.visitIntervalLabel}
              onChange={(event) =>
                setFileAttributes({
                  ...fileAttributes,
                  visitIntervalLabel: event.target.value,
                })
              }
            />
            <TextField
              helperText={
                "Please enter the row # from the SOE table where the Visit Window can be found"
              }
              id="visit_window_label"
              label="Visit Window Row #"
              variant="outlined"
              value={fileAttributes.visitWindowLabel}
              onChange={(event) =>
                setFileAttributes({
                  ...fileAttributes,
                  visitWindowLabel: event.target.value,
                })
              }
            />
          </VContainer>
        )}
        {step === UPLOAD_STEPS.UPLOAD_PROTOCOL && loading ? (
          <VContainer>
            <LinearProgress variant="indeterminate" />
            <div>{status || "Uploading and processing soe extracts..."}</div>
          </VContainer>
        ) : (
          step === UPLOAD_STEPS.UPLOAD_PROTOCOL &&
          !loading && (
            <VContainer>
              <div>{status || ""}</div>
              <ButtonContainer>
                <Button
                  onClick={lastContinue}
                  color="primary"
                  variant="contained"
                >
                  Continue
                </Button>
              </ButtonContainer>
            </VContainer>
          )
        )}
        {step !== UPLOAD_STEPS.UPLOAD_PROTOCOL && (
          <ButtonContainer>
            <Button variant="outlined" onClick={props.onClose}>
              Cancel
            </Button>
            <Button
              onClick={nextStep}
              disabled={enableContinue}
              color="primary"
              variant="contained"
            >
              Continue
            </Button>
          </ButtonContainer>
        )}
      </Container>
    </CustomModal>
  );
};

export default UploadSOEProtocolModal;

const VContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding-top: 0; // Remove the top padding
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding: 1em;
  padding-bottom: 0em;
  padding-top: 0;
  max-height: 75vh;
  overflow-y: auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1em;
`;

const InstructionTextContainer = styled.div`
  margin-top: 25px;
`;

const InstructionText = styled.p`
  margin: 0;
`;

const TooltipContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 10px;
  cursor: pointer;
`;

const StyledInfoIcon = styled(InfoIcon)`
  color: #002e6e;
`;
