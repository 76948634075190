import React, { useState } from "react";
import { Button } from "@material-ui/core";
import ImportCostModal from "./import.cost.modal";
import styled from "styled-components";

export default function ImportCost({ studyId, scheduleId, siteGroupId })  {
  const [open, setOpen] = useState(false);
  return (
    <Container>
      <Button variant="outlined" onClick={() => setOpen(true)}>
        IMPORT COUNTRY COSTS
      </Button>
      <ImportCostModal
        open={open}
        handleClose={() => setOpen(false)}
        studyId={studyId}
        scheduleId={scheduleId}
        siteGroupId={siteGroupId}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;