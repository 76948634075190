import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import Controls from "../common/controls";
import CountryPanel from "./country.panel";
import CountryTabs from "./country.tabs";
import Stepper from "../../common/stepper";
import styled from "styled-components";
import { useStudyPlan } from "../../../app/contexts/study.context";
import { useUser } from "../../../app/contexts/user.context";

const CountriesAndSiteGroups = ({ values, errors, setValues, step }) => {
  const history = useHistory();
  const location = useLocation();
  const country = new URLSearchParams(location?.search).get("country");
  const { id, planId } = useParams();
  const [value, setValue] = useState(country ? parseInt(country) : 0);
  const [hasErrors, setHasErrors] = useState([]);
  const { studyPlan } = useStudyPlan();
  const { countries } = studyPlan;
  const { setURLStudyId } = useUser();
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // listed to country param change in url and update country
  useEffect(() => {
    const country = new URLSearchParams(location?.search).get("country");
    setValue(country ? parseInt(country) : 0);
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    setURLStudyId(id);
    studyPlan["ActualStep"] = step;

    // eslint-disable-next-line
  }, []);

  // update updated field when study plan changes
  // this is used to trigger formic form update so that we can validate fields
  useEffect(() => {
    if (studyPlan) setValues({ ...values, updated: new Date().getTime() });
    // eslint-disable-next-line
  }, [studyPlan]);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!planId) {
      history.push(`/ctpo/study-plans/${id}/${planId}`);
    }
    // trigger formic form update so that we can validate fields
    setValues({ ...values, updated: new Date().getTime() });
    // eslint-disable-next-line
  }, []);

  const sorted_countries = countries?.sort((a, b) =>
    a.countryCodeISO3.localeCompare(b.countryCodeISO3),
  );

  return (
    <Container>
      <ControlsContainer className={scrolled ? "scrolled" : ""}>
        <Controls
          hasErrors={hasErrors}
          errors={errors}
        />
      </ControlsContainer>
      <Stepper errors={errors} />
      <CountryTabs
        value={value}
        onChange={(event, tab) => setValue(tab)}
      />
      {sorted_countries?.map((country, index) => (
        <CountryPanel
          country={country}
          id={index}
          key={country.countryId}
          value={value}
          setValues={setValues}
          errors={errors}
          hasErrors={hasErrors}
          setHasErrors={setHasErrors}
        />
      ))}
    </Container>
  );
};

export default CountriesAndSiteGroups;

const Container = styled.div`
  display: grid;
  grid-gap: 2em;
`;
const ControlsContainer = styled.div`
  position: sticky;
  top: 11em;
  left: 0;

  background: white;
  z-index: 105;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1em;
  box-shadow: none;

  transition: box-shadow 0.4s ease-in-out;

  &.scrolled {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.25);
  }
`;
