import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { useLocation, useParams } from "react-router-dom";

import LinearProgress from "@material-ui/core/LinearProgress";
import ScheduleStudyPlan from "./study.plan/schedule";
import StudyPlan from "./study.plan";
import TablesContainer from "./tables.container";
import styled from "styled-components";
import { useScheduleEvents } from "../app/contexts/schedule.events.context";
import { useStudyPlan } from "../app/contexts/study.context";

// import { useScheduleEvents } from "../app/contexts/schedule.events.context";

const StudyPlans = () => {
  const { id, planId } = useParams();
  const location = useLocation();
  const { fetchStudy, loadingStudy } = useStudyPlan();
  const { fetchSOEStudy } = useScheduleEvents();
  useEffect(() => {
    /**
     * This "shouldFetch" is the only way we can detect that the user landed
     * on the table without fetching on every page navigation nested under
     * /ctpo/study-plans/:id/:planId
     *
     * This allows the page to refetch in the list, but also allows for a
     * smooth experience when navigating once we have a planId.
     *
     * This would have been done by checking when planId is null, but useParam
     * sets planId to "undefined" on every history.push.
     */
    const shouldRefetch = (location.pathname.match(/\//g) || []).length === 3;
    if (shouldRefetch) {
      fetchStudy(id);
    }
    // eslint-disable-next-line
  }, [id, location, planId]);

  //Fetch on mount
  useEffect(() => {
    fetchStudy(id);
    fetchSOEStudy(id);
    // eslint-disable-next-line
  }, [id]);

  if (loadingStudy) {
    return <LinearProgress />;
  }

  return (
    <Container path={location.pathname}>
      <Switch>
        <Route
          exact
          path="/ctpo/study-plans/:id"
          component={TablesContainer}
        />
        <Route
          path="/ctpo/study-plans/:id/soe/:scheduleId"
          component={ScheduleStudyPlan}
        />
        <Route
          path="/ctpo/study-plans/:id/:planId/"
          component={StudyPlan}
        />
      </Switch>
    </Container>
  );
};

export default StudyPlans;

const Container = styled.div`
  padding: 0.9em;
  display: ${(props) =>
    props.path === "/ctpo/study-plans/:id" ? "grid" : "flex"};
  grid-gap: 1em;
  grid-template-columns: 350px 1fr;
  width: 100%;
  min-width: calc(100vw - 30px);
  height: calc(100vh - 112px);
`;
