import React from "react";
import Typography from "@material-ui/core/Typography";

const Label = ({ children, value = "- -" }) => {
  return (
    <Typography>
      <strong>{value ? value + " " : "- - "}</strong>
      {children}
    </Typography>
  );
};

export default Label;
