export const headCells = [
  {
    id: "studyPlanName",
    type: "text",
    label: "Study Plan Name",
    minWidth: "10rem",
  },
  {
    id: "studyPlanDescr",
    type: "text",
    label: "Description",
    minWidth: "5rem",
  },
  { id: "scenarioStatus", type: "text", label: "Status" },
  { id: "studyPlanTypeDetail", type: "text", label: "Type", numeric: true },
  { id: "insertBy", type: "text", label: "Created By", minWidth: "10rem" },
  { id: "insertDate", type: "date", label: "Created Date", minWidth: "6rem" },
  { id: "updateBy", type: "text", label: "Last Updated By" },
  { id: "updateDate", type: "date", label: "Last Updated Date" },
  { id: "controls", label: "", numeric: false },
];
