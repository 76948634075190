import React from "react";
import { AccordionTable } from "../../../../../app/components";
import { useStudyPlan } from "../../../../../app/contexts/study.context";
import { COLUMN_HEADERS } from "./table.utils";
import TableCell from "@material-ui/core/TableCell";

const SummaryTable = ({ type }) => {
  const { simulationResults } = useStudyPlan();
  const {
    studyPlan: { studyPlanTypeDetail },
  } = useStudyPlan();
  const { summaryTable, summaryTableCohort } = simulationResults;

  const isRemodel = ["Remodel (R)", "Remodel (D)"].includes(
    studyPlanTypeDetail,
  );

  const getColumnHeaders = (studyPlanTypeDetail) => {
    const baseHeaders = { ...COLUMN_HEADERS };
    if (
      studyPlanTypeDetail !== "Remodel (R)" &&
      studyPlanTypeDetail !== "Remodel (D)"
    ) {
      delete baseHeaders["Screen Rate Go Forward"];
      delete baseHeaders["Enrollment Rate Go Forward"];
    }
    return baseHeaders;
  };

  const renderCell = (value, defaultValue = "##", style = {}) => (
    <TableCell
      align="center"
      style={style}
    >
      {value ?? defaultValue}
    </TableCell>
  );

  const renderRow = (item) => (
    <>
      <TableCell
        component="th"
        scope="row"
      >
        <strong>
          {type === "cohort" ? item.cohortName : item.siteGroupName}
        </strong>
      </TableCell>
      {renderCell(item["Enrollment Start"], "DD/MMM/YYYY", {
        whiteSpace: "nowrap",
      })}
      {renderCell(item["Number of Activated Sites"])}
      {renderCell(item["Screen Rate"])}
      {isRemodel && renderCell(item["Screen Rate Go Forward"])}
      {renderCell(item["Enrollment Rate"])}
      {isRemodel && renderCell(item["Enrollment Rate Go Forward"])}
      {renderCell(item["Number of Patients Screened"])}
      {renderCell(item["Number of Patients Enrolled"])}
      {renderCell(item["Number of Patients Completing Treatment"])}
      {renderCell(Number(item["Enrollment Duration"]).toFixed(1))}
      {renderCell(calculateScreenFailRate(item))}
      {renderCell(calculateDropOutRate(item))}
      {renderCell(item["Site Months"])}
    </>
  );

  return (
    <AccordionTable
      width={"100%"}
      summary={type === "cohort" ? "Summary - Cohort" : "Summary"}
      columns={getColumnHeaders(studyPlanTypeDetail)}
      defaultExpanded
      data={type === "cohort" ? summaryTableCohort : summaryTable}
      renderRow={renderRow}
    />
  );
};

export default SummaryTable;

export const calculateScreenFailRate = (item) => {
  return formatRate(
    (item["Number of Patients Screened"] -
      item["Number of Patients Enrolled"]) /
      item["Number of Patients Screened"],
  );
};

export const calculateDropOutRate = (item) => {
  return formatRate(
    (item["Number of Patients Enrolled"] -
      item["Number of Patients Completing Treatment"]) /
      item["Number of Patients Enrolled"],
  );
};

const formatRate = (rate) => Number(rate.toFixed(2));
