import React from "react";
import styled from "styled-components";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Divider from "@material-ui/core/Divider";
import { SectionHeader } from "../../../../app/components";
import { useStudyPlan } from "../../../../app/contexts/study.context";
import { makeStyles } from "@material-ui/styles";

const USA_CODE = "USA";

const CountryTabs = (props) => {
  const { studyPlan } = useStudyPlan();
  const { countries } = studyPlan;
  const tabLabels = makeTabLabels(countries);
  const classes = useStyles();
  const tabClasses = useTabStyles();
  const tabsClasses = useTabsStyles();

  return (
    <Container>
      <SectionHeader>Countries</SectionHeader>
      <Content>
        <AppBar
          position="static"
          color="transparent"
          classes={classes}
        >
          <Tabs
            {...props}
            classes={tabsClasses}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            indicatorColor="primary"
          >
            {tabLabels.map((tab, index) => (
              <Tab
                classes={tabClasses}
                label={tab}
                key={tab}
                id={`scrollable-auto-tabpanel-${index}`}
              />
            ))}
          </Tabs>
        </AppBar>
        <Divider />
      </Content>
    </Container>
  );
};

export default CountryTabs;

const Content = styled.div`
  flex-grow: 1;
  width: 100%;
`;
const Container = styled.div``;

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: "none",
    width: "900px",
  },
}));

const useTabStyles = makeStyles(() => ({
  root: {
    width: "78px",
    minWidth: "78px",
  },
}));

const useTabsStyles = makeStyles(() => ({
  indicator: {
    width: "60px",
    minWidth: "60px",
  },
}));

const makeTabLabels = (countries) =>
  countries
    .sort((a, b) => a.countryCodeISO3.localeCompare(b.countryCodeISO3))
    .map((country) => {
      if (country.countryCodeISO3 === USA_CODE) {
        if (country.siteGroups && country.siteGroups.length > 0) {
          const { siteGroupName } = country.siteGroups[0];
          const labelArr = siteGroupName.replace(/[{()}]/g, "").split(" ");

          return `${labelArr[0]} - ${labelArr[1][0]}`;
        }
      } else {
        return country.countryCodeISO3;
      }
    });
