import React from "react";
import MuiPaper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/styles";

const Paper = ({ children }) => {
  const classes = useStyles();

  return <MuiPaper classes={classes}>{children}</MuiPaper>;
};

export default Paper;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "fit-content",
    padding: "1em",
    borderTop: "4px solid #cbdb2a",
  },
}));
