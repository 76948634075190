import React from "react";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";

const Label = ({
  label = "No Label",
  value = "No Value",
  color = "#002e6e",
}) => {
  const boldClasses = useBoldStyles(color);
  const classes = useStyles(color);

  return (
    <span style={{ display: "flex" }}>
      <Typography classes={boldClasses}>{label}:</Typography>
      <Typography classes={classes}>{value}</Typography>
    </span>
  );
};

export default Label;

const useBoldStyles = (color) =>
  makeStyles((theme) => ({
    root: {
      fontSize: "1rem",
      fontWeight: "600",
      color: color,
    },
  }))();

const useStyles = (color) =>
  makeStyles((theme) => ({
    root: {
      fontSize: "1rem",
      color: color,
      marginLeft: ".3em",
    },
  }))();
