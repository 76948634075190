import React from "react";
import styled from "styled-components";
import { SectionHeader } from "../../../../../app/components";
import { useStudyPlan } from "../../../../../app/contexts/study.context";
import ScenarioA from "./scenario.a";
import ScenarioB from "./scenario.b";

const CountryInputs = (props) => {
  const { studyPlan, STUDY_PLAN_TYPES } = useStudyPlan();
  const { studyPlanType } = studyPlan;
  const { SCENARIO_A, SCENARIO_B } = STUDY_PLAN_TYPES;

  let renderScenario;
  if (studyPlanType === SCENARIO_A) {
    renderScenario = <ScenarioA {...props} />;
  }

  if (studyPlanType === SCENARIO_B) {
    renderScenario = <ScenarioB {...props} />;
  }

  return (
    <Container id="country-inputs">
      <SectionHeader>Country Inputs</SectionHeader>
      <Content>{renderScenario}</Content>
    </Container>
  );
};

export default CountryInputs;

const Container = styled.div``;

const Content = styled.div``;
