import React from "react";
import styled from "styled-components";
import MuiTypography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const Title = ({ icon, children, size = "large", dense = false }) => {
  const classes = useStyles({ size });

  if (icon) {
    return (
      <Container dense={dense}>
        <IconContainer>{icon}</IconContainer>
        <MuiTypography className={classes.root}>{children}</MuiTypography>
      </Container>
    );
  }

  return (
    <Container dense={dense}>
      <MuiTypography className={classes.root}>{children}</MuiTypography>
    </Container>
  );
};

export default Title;

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: (props) => (props.size === "large" ? "1.2rem" : "1rem"),
    fontWeight: "600",
    color: "#002e6e",
  },
}));

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ dense }) => (dense ? "0em" : "0.5em")};
`;

const IconContainer = styled.div`
  margin-right: 0.5em;
  color: ${({ theme }) => theme.palette.primary.main};
`;