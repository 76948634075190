import React, { createContext, useContext, useEffect, useState } from "react";

import API from "../utils/api";
import getCookie from "../utils/getCookie";
import useLocalStorage from "../hooks/useLocalStorage";
import { useOktaAuth } from "@okta/okta-react";

const UserContext = createContext();

export function useUser() {
  return useContext(UserContext);
}

export function UserProvider({ children }) {
  const [user, setUser] = useLocalStorage("user", null);
  const [urlStudyId, setURLStudyId] = useState(null);
  const [updateNavigation, setUpdateNavigation] = useState("");
  const { authState } = useOktaAuth();

  const { accessToken } = authState;
  useEffect(() => {
    if (
      accessToken?.accessToken &&
      (accessToken?.accessToken !== getCookie("access_token") || !user)
    ) {
      document.cookie = `access_token=[${accessToken?.accessToken}]`;
      (async () => {
        let res = await API.signIn().catch((err) => {
          console.log("LOGIN ERROR", err);
        });
        if (res?.data?.user) {
          setUser(res.data.user);
        }
      })();
    }
    // eslint-disable-next-line
  }, [accessToken?.accessToken, authState]);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        setURLStudyId,
        urlStudyId,
        updateNavigation,
        setUpdateNavigation,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
