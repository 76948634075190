import { ClickAwayListener, MenuList } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";

import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useStudyPlan } from "../../../../app/contexts/study.context";

const OptionsMenu = ({
  anchor,
  open,
  studyPlanId,
  simOptRunId,
  scenarioStatus,
  studyPlanTypeDetail,
  onClone,
  onClose,
  onDelete,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const { runSimulation } = useStudyPlan();

  const handleViewStudyPlan = () => {
    history.push(`/ctpo/study-plans/${id}/${studyPlanId}/review`);
    onClose();
  };

  const handleClone = () => {
    onClone();
    onClose();
  };

  const handleViewSimulation = () => {
    history.push(`/ctpo/study-plans/${id}/${studyPlanId}/simulation`);
    onClose();
  };

  const handleRunSimulation = () => {
    if (scenarioStatus === "Draft") {
      runSimulation(studyPlanId);
    }
    history.push(`/ctpo/study-plans/${id}/${studyPlanId}/simulation`);
  };

  const IsValidToCloneEnrollmentPlanType = () => {
    const noAvaliableToClone = ["Combination (R)", "Combination (D)"];
    return !noAvaliableToClone.includes(studyPlanTypeDetail);
  }

  return (
    <Popper open={open} anchorEl={anchor} placement="bottom-end">
      <Paper classes={classes}>
        <ClickAwayListener onClickAway={onClose}>
          <MenuList
            autoFocusItem={open}
            id="composition-menu"
            aria-labelledby="composition-button"
            // onKeyDown={handleListKeyDown}
          >
            <MenuItem onClick={handleViewStudyPlan}>View Study Plan</MenuItem>
            {simOptRunId === null && (
              <MenuItem onClick={handleRunSimulation}>
                Start Simulation
              </MenuItem>
            )}
            {simOptRunId !== null && (
              <MenuItem onClick={handleViewSimulation}>
                View Simulation
              </MenuItem>
            )}
            { IsValidToCloneEnrollmentPlanType() && <MenuItem onClick={handleClone}>Clone</MenuItem>}
            <MenuItem onClick={onDelete}>Delete</MenuItem>
          </MenuList>
        </ClickAwayListener>
      </Paper>
    </Popper>
  );
};

export default OptionsMenu;

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#e6e7e8",
    padding: "0",
  },
}));
