import React, { useRef } from "react";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";

const Accordion = ({
  scrollIntoView,
  defaultExpanded = undefined,
  ...props
}) => {
  const history = useHistory();
  const classes = useStyles();
  const ref = useRef();
  return (
    <>
      {scrollIntoView ? (
        <MuiAccordion
          {...(defaultExpanded !== undefined && { expanded: defaultExpanded })}
          ref={ref}
          onChange={(e, expanded) => {
            if (expanded) {
              setTimeout(() => {
                ref.current.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
              }, 200);
            } else {
              history.push(`${history.location.pathname}`);
            }
          }}
          {...props}
        >
          <MuiAccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>{props.summary}</Typography>
          </MuiAccordionSummary>
          <MuiAccordionDetails className={classes.root}>
            {props.children}
          </MuiAccordionDetails>
        </MuiAccordion>
      ) : (
        <MuiAccordion {...props}>
          <MuiAccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>{props.summary}</Typography>
          </MuiAccordionSummary>
          <MuiAccordionDetails className={classes.root}>
            {props.children}
          </MuiAccordionDetails>
        </MuiAccordion>
      )}
    </>
  );
};

export default Accordion;

const useStyles = makeStyles((theme) => ({
  heading: {
    color: "#002e6e",
    fontSize: "1.2rem",
    fontWeight: "600",
  },
  root: {
    padding: 0,
  },
}));
