import { Button, Radio } from "@material-ui/core";
import React, { useEffect, useMemo } from "react";

import CustomDatePicker from "../../../../../../app/components/custom.date.picker";
import { Modal } from "../../../../../../app/components";
import api from "../../../../../../app/utils/api";
import moment from "moment";
import styled from "styled-components";
import { useSnackbar } from "../../../../../../app/contexts/snackbar.context";
import { useStudyPlan } from "../../../../../../app/contexts/study.context";

// Component description:
// This component represents a modal used to add a CTIS submission to a study plan
// Users can select the mode of calculating the latest Regulatory Submission date and include/exclude countries accordingly.
const AddCtisSubmissionModal = ({ open = false, onClose, onSave }) => {
  const { showSnackbar } = useSnackbar();
  const [form, setForm] = React.useState({
    regSubmissionDateUserProvided: "",
    regSubmissionDateType: "old",
    countries: [],
  });
  const { studyPlan } = useStudyPlan();
  const countries = studyPlan.countries;

  // Filter countries based on allowed site groups
  const filteredCountries = useMemo(() => {
    const allowedCountries = [
      575, 577, 580, 585, 586, 587, 589, 590, 591, 593, 594, 596, 597, 600, 602,
      604, 605, 606, 612, 614, 619, 620, 621, 625, 626, 629, 630,
    ];
    return countries.filter((c) =>
      allowedCountries.includes(c.siteGroups[0].siteGroupId)
    );
  }, [countries]);

  // Calculate disabled state based on form data
  const disabled = React.useMemo(() => {
    if (
      form.regSubmissionDateType === "new" &&
      !form.regSubmissionDateUserProvided
    ) {
      return true;
    }
    return false;
  }, [form.regSubmissionDateUserProvided, form.regSubmissionDateType]);

  useEffect(() => {
    // Update form data with site groups and their submission dates
    const lCountries = {};
    filteredCountries.forEach((country) => {
      lCountries[country.siteGroups[0].siteGroupId] = {
        includeExclude: "i",
        regSubmissionDate: getDate(country, "YYYY-MM-DD"),
      };
    });
    setForm({ ...form, siteGroups: lCountries });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredCountries]);

  // Get submission date for a country
  const getDate = (country, format = "DD/MMM/yyyy") => {
    const dateMillis = country.siteGroups[0].milestones.find(
      (milestone) => milestone.milestoneId === 135
    )?.milestoneDateMillis;
    if (!dateMillis) return "";
    return moment(dateMillis).format(format);
  };

  // Update include/exclude status for a country
  const update = (e, value) => {
    const siteGroupId = e.target.name.split("-")[1];
    const siteGroups = { ...form.siteGroups };
    siteGroups[siteGroupId]["includeExclude"] = value;
    setForm({ ...form, siteGroups });
  };

  // Save CTIS submission data
  const saveCTIS = () => {
    const payload = [];
    Object.keys(form.siteGroups).forEach((siteGroupId) => {
      const siteGroup = form.siteGroups[siteGroupId];
      payload.push({
        studyId: studyPlan.studyId,
        studyPlanId: studyPlan.studyPlanId,
        siteGroupId: siteGroupId ? parseInt(siteGroupId) : null,
        includeExclude: siteGroup.includeExclude,
        regSubmissionDateUserProvided:
          form.regSubmissionDateType === "new"
            ? moment(form.regSubmissionDateUserProvided).format("YYYY-MM-DD")
            : null,
        regSubmissionDate: siteGroup.regSubmissionDate,
        useLatestRegSubmissionsDate: form.regSubmissionDateType === "old",
        enterNewDate: form.regSubmissionDateType === "new",
      });
    });
    api
      .syncSubmissionDates(studyPlan.studyId, payload)
      .then((res) => {
        showSnackbar("CTIS Submission saved successfully", "success");
        onClose();
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        showSnackbar("CTIS Submission failed", "error");
      });
  };

  return (
    <Modal
      size="xl"
      open={open}
      onClose={onClose}
      aria-labelledby="ctis submission"
      aria-describedby="ctis submission"
      title="CTIS Submission"
    >
      <div style={{ minHeight: "450px" }}>
        <p>
          How would you like to calculate the latest Regulatory Submission date?
        </p>
        <div style={{ display: "flex", gap: "1rem", marginTop: "2rem" }}>
          <FlexContainer>
            <Radio
              checked={form.regSubmissionDateType === "old"}
              onChange={() =>
                setForm({ ...form, regSubmissionDateType: "old" })
              }
              name="reg-submission-date"
            />
            <label>Use Latest Reg Submission Date</label>
          </FlexContainer>
          <FlexContainer>
            <Radio
              checked={form.regSubmissionDateType === "new"}
              onChange={() =>
                setForm({ ...form, regSubmissionDateType: "new" })
              }
              name="reg-submission-date"
            />
            <label>Enter New Date</label>
          </FlexContainer>
          <FlexContainer>
            <CustomDatePicker
              selected={form.regSubmissionDateUserProvided}
              onChange={(date) =>
                setForm({ ...form, regSubmissionDateUserProvided: date })
              }
              showYearDropdown
              showMonthDropdown
              useShortMonthInDropdown
              dateFormat={"dd/MMM/yyyy"}
              disabled={form.regSubmissionDateType === "old"}
              placeholderText="DD/MMM/YYYY"
            />
          </FlexContainer>
        </div>
        <Table style={{ marginTop: "2rem" }}>
          <Row>
            <Cell variant="header">Country</Cell>
            <Cell variant="header">Reg Submission Date</Cell>
            <Cell variant="header">Include</Cell>
            <Cell variant="header">Exclude</Cell>
          </Row>
          {filteredCountries.map((country) => {
            return (
              <Row>
                <Cell>{country.countryName}</Cell>
                <Cell>{getDate(country)}</Cell>
                <Cell>
                  <Radio
                    name={`include-${country.siteGroups[0].siteGroupId}`}
                    checked={
                      form.siteGroups &&
                      form.siteGroups[country.siteGroups[0].siteGroupId]
                        ?.includeExclude === "i"
                    }
                    onChange={(e) => update(e, "i")}
                  />
                </Cell>
                <Cell>
                  <Radio
                    name={`include-${country.siteGroups[0].siteGroupId}`}
                    checked={
                      form.siteGroups &&
                      form.siteGroups[country.siteGroups[0].siteGroupId]
                        ?.includeExclude === "e"
                    }
                    onChange={(e) => update(e, "e")}
                  />
                </Cell>
              </Row>
            );
          })}
        </Table>

        <ButtonContainer>
          <Button variant="outlined" color="primary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={disabled}
            variant="contained"
            color="primary"
            onClick={saveCTIS}
          >
            Continue
          </Button>
        </ButtonContainer>
      </div>
    </Modal>
  );
};
export default AddCtisSubmissionModal;

const FlexContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const Row = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  height: 50px;
  border-bottom: ${(p) => (p.noBorder ? "none" : "1px solid #ccc")};
  &:last-child {
    border: none;
  }
`;

const Cell = styled.div`
  background: ${(p) => (p.variant === "header" ? "#f3f4f4" : "white")};
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  width: ${(p) => (p.width ? p.width : "100%")};
  border-right: 1px solid #ccc;
  padding: 5px;
  text-align: center;
  border-left: ${(p) => (p.leftBorder ? "1px solid #ccc" : "none")};
  &:last-child {
    border: ${(p) => (p.rightBorder ? "1px solid #ccc" : "none")};
  }
  border: ${(p) => (p.fullBorder ? "1px solid #ccc" : "")};
`;

const Table = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  border: ${(p) => (p.noBorder ? "none" : "1px solid #ccc")};
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 5rem;
`;
