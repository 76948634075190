import React from "react";
import styled from "styled-components";
import { useStudyPlan } from "../../../../app/contexts/study.context";
import { CountryHeader } from "../../../../app/components";
import Divider from "@material-ui/core/Divider";
import CountryInputs from "./country.inputs";
import CountryGlobalMilestones from "./country.global.milestones";
import CountryActivationProfiles from "./country.activation.profiles";
import CountryEnrollmentSeasonalVariations from "./country.enrollment.seasonal.variations";
import SiteFatigue from "./site.fatigue";

const CountryPanel = ({ value, id, country }) => {
  const { updatePlan, studyPlan } = useStudyPlan();

  const handleDelete = () => {
    const { countries } = studyPlan;

    const index = countries
      .map((country) => country.countryId)
      .indexOf(country.countryId);

    countries.splice(index, 1);

    updatePlan("countries", countries);
  };

  const siteGroup = country.siteGroups[0];
  return (
    <div
      role="tabpanel"
      hidden={value !== id}
      id={`scrollable-auto-tabpanel-${id}`}
    >
      {value === id && (
        <Container>
          <CountryHeader
            title={`${country.countryName} - ${country.countryCodeISO3}`}
            onClick={handleDelete}
            countryId={country.countryId}
            studyPlanType={studyPlan.studyPlanType}
            siteGroup={siteGroup}
            hasDelete={false}
            hasEdit={false}
          />
          <CountryInputs
            country={country}
            siteGroup={siteGroup}
            tab={value}
          />
          <Divider />
          <CountryGlobalMilestones
            country={country}
            siteGroup={siteGroup}
            tab={value}
          />
          <Divider />
          <CountryActivationProfiles
            country={country}
            siteGroup={siteGroup}
            tab={value}
          />
          <Divider />
          <CountryEnrollmentSeasonalVariations
            tab={value}
            country={country}
            siteGroup={siteGroup}
          />
          <Divider />
          <SiteFatigue
            country={country}
            siteGroup={siteGroup}
            tab={value}
          />
        </Container>
      )}
    </div>
  );
};

export default CountryPanel;

const Container = styled.div`
  padding: 1em 0;
  display: grid;
  gap: 2em;
`;
