import { FormControl, MenuItem, TextField } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/styles";
import styled from "styled-components";
import { useStudyPlan } from "../../../../app/contexts/study.context";
import CustomDatePicker from "../../../../app/components/custom.date.picker";
import {
  VISIT_INTERVAL_TYPE_OPTIONS,
  CDR_REVIEW_OPTIONS,
  ADDITIONAL_TIME_ON_SITE_FACTOR,
  ESTIMATED_ACTIVATED_NON_ENROLLING_SITES,
  REQUIRED_ADMINISTRATIVE_IMV_NON_ENROLLING_SITES,
  SUBJECT_REPORTING_AVERAGE_FREQUENCY,
  SUBJECT_REPORTING_HOURS_TO_GENERATE,
  STUDY_REPORTING_HOURS_TO_GENERATE,
} from "./form.constants";

export const EditScheduleEventForm = ({
  errors,
  SOEEventParameters,
  handleChange,
}) => {
  const classes = useStyles();
  const { study } = useStudyPlan();

  return (
    <>
      <VContainer>
        <h5>General</h5>
        <FormControl variant="outlined">
          <TextField
            style={{ width: "100%" }}
            value={SOEEventParameters.scheduleOfEventsName}
            onChange={(e) =>
              handleChange("scheduleOfEventsName", e.target.value)
            }
            variant="outlined"
            label="SOE Name"
            InputLabelProps={{ classes }}
            required
            error={!!errors.scheduleOfEventsName}
            inputProps={{
              maxLength: 50,
            }}
            helperText={
              <AlignBetween>
                <span>{errors.scheduleOfEventsName}</span>
                <span>{`${
                  (SOEEventParameters.scheduleOfEventsName || "").length
                } / ${50}`}</span>
              </AlignBetween>
            }
            size="small"
          />
        </FormControl>
        <FormControl variant="outlined">
          <TextField
            style={{ width: "100%" }}
            value={SOEEventParameters.scheduleOfEventsDescr}
            error={errors.scheduleOfEventsDescr}
            onChange={(e) =>
              handleChange("scheduleOfEventsDescr", e.target.value)
            }
            variant="outlined"
            label="SOE Description"
            InputLabelProps={{ classes }}
            required
            multiline
            inputProps={{
              maxLength: 2000,
            }}
            rows={4}
            helperText={
              <AlignBetween>
                <span>{errors.scheduleOfEventsDescr}</span>
                <span>{`${
                  (SOEEventParameters.scheduleOfEventsDescr || "").length
                } / ${2000}`}</span>
              </AlignBetween>
            }
            size="small"
          />
        </FormControl>
        <FormControl variant="outlined">
          <TextField
            style={{ width: "100%" }}
            value={SOEEventParameters.protocolVersion}
            onChange={(e) => handleChange("protocolVersion", e.target.value)}
            variant="outlined"
            label="Protocol Version"
            InputLabelProps={{ classes }}
            required
            error={!!errors.protocolVersion}
            inputProps={{
              maxLength: 25,
            }}
            helperText={
              <AlignBetween>
                <span>{errors.protocolVersion}</span>
                <span>{`${
                  (SOEEventParameters.protocolVersion || "").length
                } / ${25}`}</span>
              </AlignBetween>
            }
          />
        </FormControl>
        <FormControl variant="outlined">
          <CustomDatePicker
            label="Date"
            onChange={(date) => {
              if (date) {
                const adjustedDate = new Date(
                  date.getTime() - date.getTimezoneOffset() * 60000,
                );
                handleChange(
                  "protocolDate",
                  adjustedDate.toISOString().split("T")[0],
                );
              }
            }}
            showYearDropdown
            showMonthDropdown
            useShortMonthInDropdown
            required={true}
            error={errors.protocolDate}
            dateFormat={"dd/MMM/yyyy"}
            value={
              SOEEventParameters.protocolDate
                ? new Date(
                    new Date(SOEEventParameters.protocolDate).getTime() +
                      new Date(
                        SOEEventParameters.protocolDate,
                      ).getTimezoneOffset() *
                        60000,
                  )
                : null
            }
          />
        </FormControl>
        <FormControl variant="outlined">
          <TextField
            label="SOE Status"
            id="soe-status"
            variant="outlined"
            fullWidth
            select
            value={SOEEventParameters.scheduleOfEventsStatus}
            onChange={(event) => {
              handleChange("scheduleOfEventsStatus", event.target.value);
            }}
            size="small"
          >
            <MenuItem value="Draft">Draft</MenuItem>
            <MenuItem value="Final">Final</MenuItem>
          </TextField>
        </FormControl>
        <FormControl
          style={{ width: "100%" }}
          variant="outlined"
        >
          <TextField
            variant="outlined"
            fullWidth
            select
            size="small"
            error={errors.visitIntervalType}
            required
            id="visit-interval-type-id"
            value={SOEEventParameters.visitIntervalType}
            onChange={(event) =>
              handleChange("visitIntervalType", event.target.value)
            }
            label="Visit Interval Type"
          >
            {VISIT_INTERVAL_TYPE_OPTIONS?.map((type) => (
              <MenuItem value={type}>{type}</MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl
          style={{ width: "100%" }}
          variant="outlined"
        >
          <TextField
            variant="outlined"
            fullWidth
            select
            size="small"
            error={errors.visitWindowIntervalType}
            required
            id="visit-window-interval-type-id"
            value={SOEEventParameters.visitWindowIntervalType}
            onChange={(event) =>
              handleChange("visitWindowIntervalType", event.target.value)
            }
            label="Visit Window Interval Type"
          >
            {VISIT_INTERVAL_TYPE_OPTIONS?.map((type) => (
              <MenuItem value={type}>{type}</MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl variant="outlined">
          <TextField
            variant="outlined"
            fullWidth
            select
            size="small"
            id="study-plan"
            value={SOEEventParameters.studyPlanId}
            onChange={(event) =>
              handleChange("studyPlanId", event.target.value)
            }
            label="Study Plan"
          >
            {study?.studyPlans?.map((sp) => (
              <MenuItem value={sp.studyPlanId}>{sp.studyPlanName}</MenuItem>
            ))}
          </TextField>
        </FormControl>
      </VContainer>
      <VContainer>
        <h5>Clinical Strategy</h5>
        <FormControl
          style={{ width: "100%" }}
          variant="outlined"
        >
          <TextField
            variant="outlined"
            fullWidth
            select
            size="small"
            error={errors.CDAReviewPctTime}
            required
            id="cdareviewprctime"
            value={SOEEventParameters.CDAReviewPctTime}
            onChange={(event) =>
              handleChange("CDAReviewPctTime", event.target.value)
            }
            label="CDA Review % Time"
          >
            {CDR_REVIEW_OPTIONS?.map((type) => (
              <MenuItem value={type}>{type}</MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl variant="outlined">
          <TextField
            style={{ width: "100%" }}
            value={SOEEventParameters.assessorName}
            onChange={(e) => handleChange("assessorName", e.target.value)}
            variant="outlined"
            label="Risk Strategy Assessor Name"
            InputLabelProps={{ classes }}
            multiline
            inputProps={{
              maxLength: 100,
            }}
            helperText={
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <span>{`${
                  (SOEEventParameters.assessorName || "").length
                } / ${100}`}</span>
              </div>
            }
            size="small"
          />
        </FormControl>
        <FormControl
          style={{ width: "100%" }}
          variant="outlined"
        >
          <TextField
            variant="outlined"
            fullWidth
            select
            size="small"
            id="additional-time-on-site-factor-id"
            value={SOEEventParameters.AddlTimeOnSitePct}
            onChange={(event) =>
              handleChange("AddlTimeOnSitePct", event.target.value)
            }
            label="Additional Time on Site factor"
          >
            {ADDITIONAL_TIME_ON_SITE_FACTOR?.map((type) => (
              <MenuItem value={type}>{type}</MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl
          style={{ width: "100%" }}
          variant="outlined"
        >
          <TextField
            variant="outlined"
            fullWidth
            select
            size="small"
            id="estimated-activated-non-enrolling-id"
            value={SOEEventParameters.EstActivatedNonEnrollingSitesPct}
            onChange={(event) =>
              handleChange(
                "EstActivatedNonEnrollingSitesPct",
                event.target.value,
              )
            }
            label="Estimated Activated Non-enrolling Sites"
          >
            {ESTIMATED_ACTIVATED_NON_ENROLLING_SITES?.map((type) => (
              <MenuItem value={type}>{type}</MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl
          style={{ width: "100%" }}
          variant="outlined"
        >
          <TextField
            variant="outlined"
            fullWidth
            select
            size="small"
            id="required-administrative-imv-id"
            value={SOEEventParameters.ReqAdminIMVNonEnrollingSites}
            onChange={(event) =>
              handleChange("ReqAdminIMVNonEnrollingSites", event.target.value)
            }
            label='Required "Administrative" IMV for Non-Enrolling Sites '
          >
            {REQUIRED_ADMINISTRATIVE_IMV_NON_ENROLLING_SITES?.map((type) => (
              <MenuItem value={type}>{type}</MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl
          style={{ width: "100%" }}
          variant="outlined"
        >
          <TextField
            variant="outlined"
            fullWidth
            select
            size="small"
            id="subject-reporting-average-frequency-id"
            value={SOEEventParameters.SubjectReportingAvgFrequency}
            onChange={(event) =>
              handleChange("SubjectReportingAvgFrequency", event.target.value)
            }
            label="Subject Reporting, Average Frequency "
          >
            {SUBJECT_REPORTING_AVERAGE_FREQUENCY?.map((type) => (
              <MenuItem value={type === "<blank value>" ? "" : type}>
                {type}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl
          style={{ width: "100%" }}
          variant="outlined"
        >
          <TextField
            variant="outlined"
            fullWidth
            select
            size="small"
            id="subject-reporting-hours-to-generate-id"
            value={SOEEventParameters.SubjectReportingHoursToGenerate}
            onChange={(event) =>
              handleChange(
                "SubjectReportingHoursToGenerate",
                event.target.value,
              )
            }
            label="Subject Reporting, Hours to Generate "
          >
            {SUBJECT_REPORTING_HOURS_TO_GENERATE?.map((type) => (
              <MenuItem value={type}>{type}</MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl
          style={{ width: "100%" }}
          variant="outlined"
        >
          <TextField
            variant="outlined"
            fullWidth
            select
            size="small"
            id="subject-reporting-average-frequency-id"
            value={SOEEventParameters.StudyReportingAvgFrequency}
            onChange={(event) =>
              handleChange("StudyReportingAvgFrequency", event.target.value)
            }
            label="Study Reporting, Average Frequency "
          >
            {SUBJECT_REPORTING_AVERAGE_FREQUENCY?.map((type) => (
              <MenuItem value={type}>{type}</MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl
          style={{ width: "100%" }}
          variant="outlined"
        >
          <TextField
            variant="outlined"
            fullWidth
            select
            size="small"
            id="study-reporting-hours-to-generate-id"
            value={SOEEventParameters.StudyReportingHoursToGenerate}
            onChange={(event) =>
              handleChange("StudyReportingHoursToGenerate", event.target.value)
            }
            label="Study Reporting, Hours to Generate "
          >
            {STUDY_REPORTING_HOURS_TO_GENERATE?.map((type) => (
              <MenuItem value={type}>{type}</MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl variant="outlined">
          <TextField
            style={{ width: "100%" }}
            value={SOEEventParameters.StudyAdhocReview}
            onChange={(e) => {
              // only allow positive numeric values with two decimal places from 0.00 to 999.99
              if (
                e.target.value &&
                (!/^\d{1,3}(\.\d{1,2})?$/.test(e.target.value) ||
                  e.target.value >= 1000)
              ) {
                return;
              }

              handleChange("StudyAdhocReview", e.target.value);
            }}
            variant="outlined"
            label="Ad Hoc Review"
            InputLabelProps={{ classes }}
            type="number"
            size="small"
          />
        </FormControl>
      </VContainer>
    </>
  );
};

const useStyles = makeStyles({
  asterisk: {
    color: "red",
  },
});

const AlignBetween = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const VContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2em;
  flex: 1;
`;
