import React from "react";
import styled from "styled-components";
import { SectionHeader } from "../../../../../app/components";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import { makeStyles } from "@material-ui/styles";
import { useHistory, useParams } from "react-router-dom";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import moment from "moment";
import { useStudyPlan } from "../../../../../app/contexts/study.context";

const fields = [
  { label: "Label", key: "siteActivationLabel" },
  { label: "Percentage", key: "siteActivationProfilePct" },
  { label: "Days", key: "siteActivationProfileDays" },
  {
    label: "Date",
    key: "siteActivationProfileDateMillis",
    format: "DD/MMM/YYYY",
  },
];

const renderTableRows = (activationProfiles, inputClasses, fields) =>
  fields.map((field) => (
    <Row
      key={field.label}
      repeat={activationProfiles.length}
    >
      <Cell variant="header">
        <Typography align="right">{field.label}</Typography>
      </Cell>
      {activationProfiles.map((profile) => (
        <Cell key={profile[`${field.key}Id`] || profile.temp_key}>
          <InputBase
            classes={inputClasses}
            value={
              field.format
                ? moment(profile[field.key]).utc().format(field.format)
                : profile[field.key]
            }
            disabled
          />
        </Cell>
      ))}
    </Row>
  ));

const CountryActivationProfiles = ({ country, siteGroup, tab }) => {
  const { canEditPlan } = useStudyPlan();
  const inputClasses = useInputStyles();
  const history = useHistory();
  const { id, planId } = useParams();
  const { activationProfiles } = siteGroup;

  return (
    <Container>
      <SideBySide>
        <SectionHeader>Country Activation Profiles</SectionHeader>
        {canEditPlan && (
          <Button
            color="primary"
            onClick={() =>
              history.push(
                `/ctpo/study-plans/${id}/${planId}/countries-and-site-groups#activation-profiles`,
                { tabIndex: tab },
              )
            }
          >
            <EditIcon style={{ marginRight: ".3em" }} /> Edit
          </Button>
        )}
      </SideBySide>
      <Table>{renderTableRows(activationProfiles, inputClasses, fields)}</Table>
    </Container>
  );
};

export default CountryActivationProfiles;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 120px repeat(${(p) => p.repeat}, 1fr);

  height: 50px;
  border-bottom: 1px solid #ccc;
  &:last-child {
    border: none;
  }
`;

const Cell = styled.div`
  background: ${(p) => (p.variant === "header" ? "#f3f4f4" : "white")};
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  border-right: 1px solid #ccc;
  padding: 0.5em;
  &:last-child {
    border: none;
  }
  &:first-child {
    justify-content: flex-end;
    padding-right: 1em;
  }
`;

const Table = styled.div`
  display: grid;
  width: fit-content;
  grid-template-rows: auto auto;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const useInputStyles = makeStyles(() => ({
  input: {
    textAlign: "center",
  },
}));

const SideBySide = styled.div`
  display: flex;
  justify-content: space-between;
`;
