import { Cell, Row } from ".";

import { INITIAL_RISK_ASSESSMENT_COLORS } from "../constants";
import React from "react";

export default function TableHeader() {
  return (
    <Row variant="header">
      <Cell style={{ width: "10%" }} variant="header">
        <strong>Category</strong>
      </Cell>
      <Cell style={{ width: "22%" }} variant="header">
        <strong>Study Specifics</strong>
      </Cell>
      <Cell style={{ width: "8%" }} variant="header">
        <strong>Risk Score</strong>
      </Cell>
      <Cell
        style={{
          width: "20%",
          backgroundColor: INITIAL_RISK_ASSESSMENT_COLORS.GREEN,
        }}
        variant="header"
      >
        <strong>Low</strong>
      </Cell>
      <Cell
        style={{
          width: "20%",
          backgroundColor: INITIAL_RISK_ASSESSMENT_COLORS.YELLOW,
        }}
        variant="header"
      >
        <strong>Moderate</strong>
      </Cell>
      <Cell
        style={{
          width: "20%",
          backgroundColor: INITIAL_RISK_ASSESSMENT_COLORS.RED,
        }}
        variant="header"
      >
        <strong>High</strong>
      </Cell>
    </Row>
  );
}
