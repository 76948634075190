import { Cell, Container, Row } from "..";

import { Accordion } from "../../../../../app/components";
import EditNotesInput from "../edit.notes.input";
import React from "react";

export default function IMVTable({ data }) {
  const [editState, setEditState] = React.useState({});

  const renderTable = () => {
    return data.map((d, idx) => {
      return (
        <Row>
          <Cell
            variant="header"
            justify="flex-end"
            width="30%"
            style={{ borderBottom: "none" }}
            textAlign="right"
          >
            {d.rowLabel}
          </Cell>
          <Cell width="10%">{d.cellValue1}</Cell>
          <Cell width="10%">{d.cellValue2}</Cell>
          <Cell width="10%">{d.cellValue3}</Cell>
          <Cell
            style={{ position: "relative" }}
            textAlign="left"
            justify="flex-start"
            width="40%"
          >
            <EditNotesInput
              d={d}
              editState={editState}
              setEditState={setEditState}
            />
          </Cell>
        </Row>
      );
    });
  };

  return (
    <Accordion scrollIntoView={true} width={"100%"} summary={"IMV Budgeting"}>
      <Container>{renderTable()}</Container>
    </Accordion>
  );
}
