import MuiTableRow from "@material-ui/core/TableRow";
import MuiTooltip from "@material-ui/core/Tooltip";
import React from "react";
import TableCell from "@material-ui/core/TableCell";
import { otherStyles } from "../../app/helpers/utils.js";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core";

// create a styled table cell with no horizontal padding
const StyledTableCell = withStyles((theme) => ({
  root: {
    paddingLeft: "5px",
    paddingRight: "5px",

    "&:first-child": {
      paddingLeft: "10px",
    },
  },
}))(TableCell);

const TableRow = (props) => {
  const {
    studyId,
    studyName,
    sponsorName,
    projectId,
    opportunityStage,
    soeExists,
    soeCosts,
    soeClinicalInformatics,
    soeDataManagement,
    enrollmentPlanLastUpdate,
    soeLastUpdate,
    enrollmentPlanExists,
  } = props;
  const otherClasses = otherStyles();

  const history = useHistory();

  const handleClickStudy = () => {
    history.push(`/ctpo/study-plans/${studyId}`);
  };

  const formatDate = (date) => {
    if (!date) return "";
    // format date to MM/DD/YYYY using moment
    return moment(date).format("DD/MMM/YYYY");
  };

  return (
    <MuiTableRow>
      <StyledTableCell align="center"></StyledTableCell>
      <StyledTableCell
        className={otherClasses.link}
        onClick={handleClickStudy}
        align="left"
      >
        <span style={{ fontSize: "14px" }}>{projectId}</span>
      </StyledTableCell>
      <StyledTableCell
        align="left"
        style={{ minWidth: "7rem" }}
      >
        <MuiTooltip title={<p style={{ fontSize: "14px" }}>{studyName}</p>}>
          <span>{studyName}</span>
        </MuiTooltip>
      </StyledTableCell>
      <StyledTableCell
        align="left"
        style={{ minWidth: "7rem" }}
      >
        <MuiTooltip title={<p style={{ fontSize: "14px" }}>{sponsorName}</p>}>
          <span>{sponsorName}</span>
        </MuiTooltip>
      </StyledTableCell>
      <StyledTableCell align="left">
        <MuiTooltip
          title={<p style={{ fontSize: "14px" }}>{opportunityStage}</p>}
        >
          <span>{opportunityStage}</span>
        </MuiTooltip>
      </StyledTableCell>
      <StyledTableCell align="center">
        <span style={{ fontSize: "14px" }}>{enrollmentPlanExists}</span>
      </StyledTableCell>
      <StyledTableCell align="center">
        <span style={{ fontSize: "14px" }}>{soeExists}</span>
      </StyledTableCell>
      <StyledTableCell align="center">
        <span style={{ fontSize: "14px" }}>{soeCosts}</span>
      </StyledTableCell>
      <StyledTableCell align="center">
        <span style={{ fontSize: "14px" }}>{soeClinicalInformatics}</span>
      </StyledTableCell>
      <StyledTableCell align="center">
        <span style={{ fontSize: "14px" }}>{soeDataManagement}</span>
      </StyledTableCell>
      <StyledTableCell
        style={{ minWidth: "10rem" }}
        align="center"
      >
        <span style={{ fontSize: "14px" }}>
          {formatDate(enrollmentPlanLastUpdate)}
        </span>
      </StyledTableCell>
      <StyledTableCell
        style={{ minWidth: "7rem" }}
        align="center"
      >
        <span style={{ fontSize: "14px" }}>{formatDate(soeLastUpdate)}</span>
      </StyledTableCell>
    </MuiTableRow>
  );
};

export default TableRow;
