import React from "react";
import styled from "styled-components";
import { SpecialTextField } from "../../../../../../app/components";
import { useStudyPlan } from "../../../../../../app/contexts/study.context";

const ScenarioB = ({ country, siteGroup }) => {
  const { updateCountries } = useStudyPlan();
  const {
    siteGroupId,
    eventualSiteEnrollmentRate,
    eventualSiteEnrollmentDays,
  } = siteGroup;
  const { countryId } = country;

  const handleUpdateSiteGroup = (property, value) => {
    const update = { [property]: value };
    updateCountries(countryId, siteGroupId, update);
  };

  return (
    <Container>
      <Content>
        <SpecialTextField
          showArrows={false}
          label="Number of Days to Site Fatigue"
          value={eventualSiteEnrollmentDays}
          onChange={(value) =>
            handleUpdateSiteGroup("eventualSiteEnrollmentDays", value)
          }
        />
        <SpecialTextField
          showArrows={false}
          label="Reduced Enrollment Rate Percentage (in decimals)"
          value={eventualSiteEnrollmentRate}
          onChange={(value) =>
            handleUpdateSiteGroup("eventualSiteEnrollmentRate", value)
          }
          inputProps={{ step: 0.01 }}
          min={0.01}
        />
      </Content>
    </Container>
  );
};

export default ScenarioB;

const Container = styled.div``;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 1em;
`;
