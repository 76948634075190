import React, { useCallback } from "react";
import styled from "styled-components";
import { SectionHeader } from "../../../../app/components";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import { makeStyles, withStyles } from '@material-ui/core/styles';

const StyledInputBase = withStyles((theme) => ({
  root: {
    borderRadius: '0px',
    border: '1px solid #4ac6e1',
    padding: '0px 0px',
    width: '90%',
    '& .MuiInputBase-input': {
      textAlign: 'center',
      '-moz-appearance': 'textfield', // For Firefox
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        // For Chrome and Safari
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
  },
}))(InputBase);

const EditSiteGroupActivationProfiles = ({ sitegroup, setForm }) => {
  const inputClasses = useInputStyles();
  const { enrollmentVariations } = sitegroup;

  const handleChange = useCallback(
    (value, index) => {
      const newEnrollmentVariations = [...enrollmentVariations];
      newEnrollmentVariations[index] = {
        ...newEnrollmentVariations[index],
        enrollmentSeasonalVariation: value,
        hasUpdate: true,
      };

      setForm((prev) => ({
        ...prev,
        enrollmentVariations: newEnrollmentVariations,
      }));
    },
    [setForm, enrollmentVariations]
  );

  return (
    <Container>
      <SectionHeader hasStartAdornment={false}>
        Site Group Enrollment Seasonal Variations
      </SectionHeader>
      <Table>
        <Row>
          <Cell variant="header">
            <Typography>
              <strong>Jan</strong>
            </Typography>
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>Feb</strong>
            </Typography>
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>Mar</strong>
            </Typography>{" "}
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>Apr</strong>
            </Typography>
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>May</strong>
            </Typography>
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>Jun</strong>
            </Typography>
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>Jul</strong>
            </Typography>
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>Aug</strong>
            </Typography>
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>Sep</strong>
            </Typography>
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>Oct</strong>
            </Typography>
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>Nov</strong>
            </Typography>
          </Cell>
          <Cell variant="header">
            <Typography>
              <strong>Dec</strong>
            </Typography>
          </Cell>
        </Row>
        <Row>
          {enrollmentVariations.map((item, index) => (
            <Cell key={item.seasonalEnrollmentVariationId}>
              <StyledInputBase
                type="number"
                classes={inputClasses}
                value={item.enrollmentSeasonalVariation}
                onChange={(event) => handleChange(event.target.value, index)}
              />
            </Cell>
          ))}
        </Row>
      </Table>
    </Container>
  );
};

export default EditSiteGroupActivationProfiles;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  height: 50px;
  border-bottom: 1px solid #ccc;
  &:last-child {
    border: none;
  }
`;

const Cell = styled.div`
  background: ${(p) => (p.variant === "header" ? "#f3f4f4" : "white")};
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  border-right: 0px solid #ccc;
  &:last-child {
    border: none;
  }
`;

const Table = styled.div`
  display: grid;
  gridtemplaterows: auto auto;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const useInputStyles = makeStyles(() => ({
  input: {
    textAlign: "center",
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "-moz-appearance": "textfield",
  },
}));