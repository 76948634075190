import { Cell, Container, Row } from "..";

import { Accordion } from "../../../../../app/components";
// import { useScheduleEvents } from "../../../../../app/contexts/schedule.events.context";
import EditNotesInput from "../edit.notes.input";
import React from "react";

// import { Close as CloseIcon, Edit as EditIcon } from "@material-ui/icons";
// import { IconButton, TextField } from "@material-ui/core";

export default function CRATable({ data }) {
  // const { setUpdatedSamplingStrategyValues } = useScheduleEvents();
  const [editState, setEditState] = React.useState({});
  // const [localState, setLocalState] = React.useState({ ...data });
  const renderHeader = () => {
    return (
      <Row>
        <Cell variant="header" width="30%" style={{ borderBottom: "none" }} />
        <Cell variant="header" width="10%">
          100% (traditional)
        </Cell>
        <Cell variant="header" width="10%">
          Visit Sampling Modeling
        </Cell>
        <Cell variant="header" width="10%">
          Difference from Traditional
        </Cell>
        <Cell variant="header" width="40%">
          Notes
        </Cell>
      </Row>
    );
  };

  const renderRows = () => {
    return data.map((d, idx) => {
      return (
        <Row>
          <Cell
            variant="header"
            justify="flex-end"
            width="30%"
            style={{ borderBottom: "none" }}
            textAlign="right"
          >
            {d.rowLabel}
          </Cell>
          <Cell width="10%">{d.cellValue1}</Cell>
          <Cell width="10%">{d.cellValue2}</Cell>
          <Cell width="10%">{d.cellValue3}</Cell>
          <Cell
            style={{ position: "relative" }}
            textAlign="left"
            justify="flex-start"
            width="40%"
          >
            <EditNotesInput
              d={d}
              editState={editState}
              setEditState={setEditState}
            />
          </Cell>
        </Row>
      );
    });
  };

  return (
    <Accordion
      scrollIntoView={true}
      width={"100%"}
      summary={"CRA Time - SDR/SDV Time"}
    >
      <Container>
        {renderHeader()}
        {renderRows()}
      </Container>
    </Accordion>
  );
}
