import React from "react";
import styled from "styled-components";
import { Modal } from "../../../../../../app/components";
import FormGroup from "@material-ui/core/FormGroup";
import Button from "@material-ui/core/Button";
import { CheckBoxesExportTable } from "./CheckBoxesExportTable";
import {
  DETAILS_OPTIONS,
  COHORT_DETAILS_OPTIONS,
  SUMMARIES_OPTIONS,
  DURATIONS_OPTIONS,
} from "./constants";

export const ExportModal = ({
  open,
  onClose,
  setIsChecked,
  isChecked,
  exportAsCSV,
  type,
}) => {
  const handleClose = () => {
    setIsChecked({});
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="Export Tables"
      aria-describedby="Export Tables"
      title="Export Tables"
      sx={{ minWidth: "30rem", maxWidth: "100%", width: "70rem" }}
    >
      <FormGroup>
        <Container>
          <SubContainerRow>
            <SubContainer>
              <SubContainerTitle>Details</SubContainerTitle>
              <SubContainerContent>
                <CheckBoxesExportTable
                  options={DETAILS_OPTIONS}
                  isChecked={isChecked}
                  setIsChecked={setIsChecked}
                  section={"Details"}
                />
              </SubContainerContent>
            </SubContainer>
            {type === "cohort" && (
              <SubContainer>
                <SubContainerTitle>Cohort Details</SubContainerTitle>
                <SubContainerContent>
                  <CheckBoxesExportTable
                    options={COHORT_DETAILS_OPTIONS}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    section={"Cohort Details"}
                  />
                </SubContainerContent>
              </SubContainer>
            )}
          </SubContainerRow>

          <SubContainerRow>
            <SubContainer>
              <SubContainerTitle>Summaries</SubContainerTitle>
              <SubContainerContent>
                <CheckBoxesExportTable
                  options={SUMMARIES_OPTIONS}
                  isChecked={isChecked}
                  type={type}
                  setIsChecked={setIsChecked}
                  section={"Summaries"}
                />
              </SubContainerContent>
            </SubContainer>
            <SubContainer>
              <SubContainerTitle>Durations</SubContainerTitle>
              <SubContainerContent>
                <CheckBoxesExportTable
                  options={DURATIONS_OPTIONS}
                  isChecked={isChecked}
                  setIsChecked={setIsChecked}
                  section={"Durations"}
                />
              </SubContainerContent>
            </SubContainer>
          </SubContainerRow>
        </Container>

        <ButtonContainer>
          <Button
            onClick={handleClose}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={exportAsCSV}
            color="primary"
            variant="contained"
            disabled={Object.values(isChecked).every((value) => !value)}
          >
            Export
          </Button>
        </ButtonContainer>
      </FormGroup>
    </Modal>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const SubContainerRow = styled.div`
  display: flex;
  gap: 0.5em;
`;

const SubContainer = styled.div`
  flex: 1;
`;

const SubContainerTitle = styled.div`
  font-weight: bold;
  margin-bottom: 0.25em;
`;

const SubContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25em;
  border-top: 1px solid #ccc;
  padding-top: 1em;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5em;
`;
