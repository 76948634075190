import { Close as CloseIcon, Edit as EditIcon } from "@material-ui/icons";
import { IconButton, TextField } from "@material-ui/core";

import React from "react";
import { debounce } from "lodash";
import { useScheduleEvents } from "../../../../app/contexts/schedule.events.context";

export default function EditNotesInput({ editState, setEditState, d }) {
  const { setUpdatedSamplingStrategyValues } = useScheduleEvents();
  const [notes, setNotes] = React.useState(d.notes);

  const updateNote = (value) =>
    setUpdatedSamplingStrategyValues((prev) => {
      const newSet = { ...prev };
      newSet[`${d.scheduleOfEventsId}-${d.tableSort}-${d.rowSort}`] = {
        notes: value,
      };
      return newSet;
    });

  // add a debounce to the note state and call updateNote with that
  const debouncedUpdateNote = React.useCallback(debounce(updateNote, 500), []);

  return (
    <>
      {!editState[d.rowLabel] ? (
        <p style={{ paddingRight: "30px" }}>{notes}</p>
      ) : (
        <>
          <TextField
            inputProps={{
              maxLength: 500,
            }}
            variant="outlined"
            label="Notes"
            multiline
            rows={4}
            fullWidth
            value={notes}
            onChange={(e) => {
              const value = e.target.value;
              setNotes(value);
              debouncedUpdateNote(value);
            }}
          />
        </>
      )}
      <IconButton
        variant="text"
        color="primary"
        onClick={() =>
          setEditState((prev) => ({
            ...prev,
            [d.rowLabel]: !editState[d.rowLabel],
          }))
        }
        style={{
          position: "absolute",
          right: "10px",
          top: !editState[d.rowLabel] ? "5px" : "10px",
          padding: "4px",
        }}
      >
        {!editState[d.rowLabel] ? (
          <EditIcon style={{ width: "1rem", height: "1rem" }} />
        ) : (
          <CloseIcon style={{ width: "1rem", height: "1rem" }} />
        )}
      </IconButton>
    </>
  );
}
