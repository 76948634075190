import React, { useMemo, useState, useEffect } from "react";
import AssessmentsTable from "../assessments.table";
import { FIELD_TYPES } from "../constants";
import InitialRiskAssessmentTable from "../initial.risk.assessment.table";
import VisitSampleStrategyTables from "../visit.sample.strategy.tables";
import VisitsInformationTable from "../soe.table";
import VisitsTable from "../visits.table";
import styled from "styled-components";

export default function Content({
  tabs,
  value,
  editOpen,
  setEditOpen,
  siteId,
  fieldType,
  setFieldType,
  setHasChanges,
  updateContext,
  setSaveSOE,
}) {
  // initial a boolean called showInitialRiskAssessment using the useMemo hook and the value of the fieldType
  const showInitialRiskAssessment = useMemo(() => {
    return fieldType === FIELD_TYPES.MEDICAL;
  }, [fieldType]);

  const showVisitSampleStrategy = useMemo(() => {
    return fieldType === FIELD_TYPES.MEDICAL;
  }, [fieldType]);

  const [tableVisibility, setTableVisibilityState] = useState({
    showVisitTable: true,
    showAssessmentsTable: false,
    showVisitInformationTable: false,
  });

  const setTableVisibility = (tableName) => {
    setTableVisibilityState((prevState) => {
      console.log("Value TEST");
      if (value === 0) {
        const resetState = {
          showVisitTable: false,
          showAssessmentsTable: false,
          showVisitInformationTable: false,
        };
        return { ...resetState, [tableName]: true };
      }

      return {
        ...prevState,
        [tableName]: !prevState[tableName],
      };
    });
  };

  useEffect(() => {
    const isVisible = value !== 0;
    setTableVisibilityState({
      showVisitTable: true,
      showAssessmentsTable: isVisible,
      showVisitInformationTable: isVisible,
    });
  }, [value, setTableVisibilityState]);

  return tabs?.map(
    (tab) =>
      tabs[value]?.siteGroupId === tab?.siteGroupId && (
        <TabContainer
          key={tab?.countryCodeISO3}
          value={value}
          tab={tab?.countryCodeISO3}
        >
          <VisitsTable
            editOpen={editOpen}
            setEditOpen={setEditOpen}
            value={value}
            country={tab}
            fieldType={fieldType}
            setFieldType={setFieldType}
            siteId={siteId}
            setTableVisibility={setTableVisibility}
            tableVisibility={tableVisibility}
          />
          <AssessmentsTable
            fieldType={fieldType}
            setFieldType={setFieldType}
            value={value}
            country={tab}
            siteId={siteId}
            setTableVisibility={setTableVisibility}
            tableVisibility={tableVisibility}
          />
          {value === 0 && (
            <VisitsInformationTable
              fieldType={fieldType}
              value={value}
              setHasChanges={setHasChanges}
              updateContext={updateContext}
              setSaveSOE={setSaveSOE}
              setTableVisibility={setTableVisibility}
              tableVisibility={tableVisibility}
            />
          )}
          {value === 0 && showInitialRiskAssessment && (
            <InitialRiskAssessmentTable />
          )}
          {value === 0 && showVisitSampleStrategy && (
            <VisitSampleStrategyTables
              editOpen={editOpen}
              setEditOpen={setEditOpen}
            />
          )}
        </TabContainer>
      ),
  );
}

const TabContainer = styled.div`
  grid-gap: 2em;
  display: grid;
`;
