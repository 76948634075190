import React, { useState } from "react";
import styled from "styled-components";

import ExportTablesModal from "./export.tables.modal";
import Button from "@material-ui/core/Button";

const TableControls = ({ detailsView, type }) => {
  const [open, setOpen] = useState(false);

  return (
    <Container>
      <Button variant="outlined" onClick={() => setOpen(true)}>
        Export Tables
      </Button>

      <ExportTablesModal
        open={open}
        detailsView={detailsView}
        onClose={() => setOpen(false)}
        type={type}
      />
    </Container>
  );
};

export default TableControls;

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  height: fit-content;
  width: 100%;
`;
