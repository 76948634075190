import { Button, LinearProgress } from "@material-ui/core";
import React, { useState } from "react";
import API from "../../../../../../app/utils/api";
import FileUploader from "../../../../../../app/components/file.uploader.excel";
import CustomModal from "../../../../../../app/components/modal.no.close";
import styled from "styled-components";
import { useSnackbar } from "../../../../../../app/contexts/snackbar.context";
import { useStudyPlan } from "../../../../../../app/contexts/study.context";
import * as XLSX from "xlsx";

const UploadPlanModal = ({ onContinue, ...props }) => {
  const { studyPlan } = useStudyPlan();

  const { showSnackbar } = useSnackbar();
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [apiStatus, setApiStatus] = useState(null);
  const [sheetData, setSheetData] = useState([]);

  const handleChange = async (files) => {
    if (files.length === 0) return;
    handleUpload(files);
  };

  const handleUpload = async (files) => {
    if (files.length === 0) return;

    setLoading(true);
    setProgress(0);
    setStatus("Uploading plan...");

    try {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];

        const worksheet = workbook.Sheets[sheetName];
        const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        setSheetData(sheetData);

        const planTypeRow = sheetData.find((row) => row[0] === "Plan Type");
        const uploadedStudyType = planTypeRow ? planTypeRow[1] : "";

        // Create JSON structure from "Country Inputs" table (rows 7 to 36)
        const countryInputsData = sheetData
          .slice(6, 36)
          .filter((row) =>
            row.some(
              (cell) => cell !== undefined && cell !== null && cell !== "",
            ),
          )
          .map((row) => {
            const [
              countryName,
              siteIdEffort,
              countryStartUpTimeFrame,
              enrollmentRate,
              NUM_SITES_ACTIVATED_LOWER,
              NUM_SITES_ACTIVATED_UPPER,
              NUM_SUBJECTS_SCREENED_LOWER,
              NUM_SUBJECTS_SCREENED_UPPER,
              screenFailRate,
              NUM_SUBJECTS_ENROLLED,
              dropOutRate,
              NUM_SUBJECTS_TREATED,
              NUM_SUBJECTS_COMPLETED,
            ] = row.slice(1);

            return {
              countryName,
              siteIdEffort,
              countryStartUpTimeFrame,
              enrollmentRate,
              NUM_SITES_ACTIVATED_LOWER,
              NUM_SITES_ACTIVATED_UPPER,
              NUM_SUBJECTS_SCREENED_LOWER,
              NUM_SUBJECTS_SCREENED_UPPER,
              screenFailRate,
              NUM_SUBJECTS_ENROLLED,
              dropOutRate,
              NUM_SUBJECTS_TREATED,
              NUM_SUBJECTS_COMPLETED,
            };
          });

        // Create an array of country names from countryInputsData
        const countryNames = countryInputsData.map(
          (country) => country.countryName,
        );

        // Create JSON structure from "Milestones" table (rows 42 to 71)
        const milestonesData = sheetData
          .slice(41, 71)
          .filter((row) =>
            row.some(
              (cell) => cell !== undefined && cell !== null && cell !== "",
            ),
          )
          .map((row, index) => {
            const [
              _, // Ignore the original countryName value
              SITE_ID_DATE,
              SITE_ID_COMMENT,
              SUBMISSION_DATE,
              SUBMISSION_COMMENT,
              FIRST_REGULATORY_APPROVAL_DATE,
              FIRST_REGULATORY_APPROVAL_COMMENT,
              FSIV_DATE,
              FSIV_COMMENT,
              FPS_DATE,
              FPS_COMMENT,
              FPI_DATE,
              FPI_COMMENT,
              LPI_DATE,
              LPI_COMMENT
            ] = row.slice(1);

            const formatDate = (value) => {
              if (typeof value === "number" && value > 1000) {
                return XLSX.SSF.format("dd-mmm-yy", value);
              }
              return value;
            };

            return {
              countryName: countryNames[index], // Use the corresponding country name from countryNames array
              SITE_ID_DATE: formatDate(SITE_ID_DATE),
              SITE_ID_COMMENT,
              SUBMISSION_DATE: formatDate(SUBMISSION_DATE),
              SUBMISSION_COMMENT,
              FIRST_REGULATORY_APPROVAL_DATE: formatDate(
                FIRST_REGULATORY_APPROVAL_DATE,
              ),
              FIRST_REGULATORY_APPROVAL_COMMENT,
              FSIV_DATE: formatDate(FSIV_DATE),
              FSIV_COMMENT,
              FPS_DATE: formatDate(FPS_DATE),
              FPS_COMMENT,
              FPI_DATE: formatDate(FPI_DATE),
              FPI_COMMENT,
              LPI_DATE: formatDate(LPI_DATE),
              LPI_COMMENT
            };
          });

        // Create JSON structure from "Activation Profiles" table (rows 77 to 106)
        const activationProfilesData = sheetData
          .slice(76, 106)
          .filter((row) =>
            row.some(
              (cell) => cell !== undefined && cell !== null && cell !== "",
            ),
          )
          .map((row, index) => {
            const [
              _, // Ignore the original countryName value
              CARD_1_PERCENT,
              CARD_1_DATE,
              CARD_2_PERCENT,
              CARD_2_DATE,
              CARD_3_PERCENT,
              CARD_3_DATE,
              CARD_4_PERCENT,
              CARD_4_DATE,
              CARD_5_PERCENT,
              CARD_5_DATE,
              CARD_6_PERCENT,
              CARD_6_DATE,
              CARD_7_PERCENT,
              CARD_7_DATE,
              CARD_8_PERCENT,
              CARD_8_DATE,
            ] = row.slice(1);
            const formatDate = (value) => {
              if (typeof value === "number" && value > 1000) {
                return XLSX.SSF.format("dd-mmm-yy", value);
              }
              return value;
            };
            return {
              countryName: countryNames[index], // Use the corresponding country name from countryNames array
              CARD_1_PERCENT,
              CARD_1_DATE: formatDate(CARD_1_DATE),
              CARD_2_PERCENT,
              CARD_2_DATE: formatDate(CARD_2_DATE),
              CARD_3_PERCENT,
              CARD_3_DATE: formatDate(CARD_3_DATE),
              CARD_4_PERCENT,
              CARD_4_DATE: formatDate(CARD_4_DATE),
              CARD_5_PERCENT,
              CARD_5_DATE: formatDate(CARD_5_DATE),
              CARD_6_PERCENT,
              CARD_6_DATE: formatDate(CARD_6_DATE),
              CARD_7_PERCENT,
              CARD_7_DATE: formatDate(CARD_7_DATE),
              CARD_8_PERCENT,
              CARD_8_DATE: formatDate(CARD_8_DATE),
            };
          });

        // Create JSON structure from "Enrollment Seasonal Variations" table (rows 110 to 139)
        const enrollmentSeasonalVariations = sheetData
          .slice(109, 139)
          .filter((row) =>
            row.some(
              (cell) => cell !== undefined && cell !== null && cell !== "",
            ),
          )
          .map((row, index) => {
            const [
              _, // Ignore the original countryName value
              MONTH_1,
              MONTH_2,
              MONTH_3,
              MONTH_4,
              MONTH_5,
              MONTH_6,
              MONTH_7,
              MONTH_8,
              MONTH_9,
              MONTH_10,
              MONTH_11,
              MONTH_12,
            ] = row.slice(1);

            return {
              countryName: countryNames[index], // Use the corresponding country name from countryNames array
              MONTH_1,
              MONTH_2,
              MONTH_3,
              MONTH_4,
              MONTH_5,
              MONTH_6,
              MONTH_7,
              MONTH_8,
              MONTH_9,
              MONTH_10,
              MONTH_11,
              MONTH_12,
            };
          });

        // Create JSON structure from "Site Fatigue" table (rows 143 to 172)
        const siteFatigue = sheetData
          .slice(142, 172)
          .filter((row) =>
            row.some(
              (cell) => cell !== undefined && cell !== null && cell !== "",
            ),
          )
          .map((row, index) => {
            const [
              _, // Ignore the original countryName value
              SITE_FATIGUE_DAYS,
              SITE_FATIGUE_PERCENT,
            ] = row.slice(1);

            return {
              countryName: countryNames[index], // Use the corresponding country name from countryNames array
              SITE_FATIGUE_DAYS,
              SITE_FATIGUE_PERCENT,
            };
          });

        const payload = {
          studyId: studyPlan.studyId,
          studyPlanId: studyPlan.studyPlanId,
          studyPlanType: uploadedStudyType,
          tables: [
            {
              tableName: "Country Inputs",
              countries: countryInputsData,
            },
            {
              tableName: "Milestones",
              countries: milestonesData,
            },
            {
              tableName: "Activation Profiles",
              countries: activationProfilesData,
            },
            {
              tableName: "Enrollment Seasonal Variations",
              countries: enrollmentSeasonalVariations,
            },
            {
              tableName: "Site Fatigue",
              countries: siteFatigue,
            },
          ],
        };

        try {
          const response = await API.uploadPlan(studyPlan.studyId, payload);
          if (response.status === 200) {
            if (response.data[0].returnCode === "200") {
              showSnackbar("Uploaded successfully", "success");
              setProgress(100);
              setStatus("Upload completed.");
              setApiStatus(response.status);
              setSheetData(response.text);
            } else {
              showSnackbar("Failed to upload", "error");
              setProgress(100);
              setStatus("Upload failed.");
              setApiStatus(response.data[0].returnCode);
              setSheetData(response.data[0].returnDesc);
            }
          } else {
            showSnackbar("Failed to upload", "error");
            setProgress(100);
            setStatus("Upload failed.");
            setApiStatus(response.status);
            setSheetData(response.text);
          }
        } catch (error) {
          console.log(error);
          showSnackbar("Failed to upload", "error");
          setLoading(false);
        }
      };
      reader.readAsArrayBuffer(files[0]);
    } catch (error) {
      console.log(error);
      showSnackbar("Failed to upload", "error");
      setLoading(false);
    }
  };

  const lastContinue = () => {
    props.onClose();
    onContinue();
  };

  return (
    <CustomModal
      size={700}
      {...props}
      onClose={!loading && props.onClose}
      aria-labelledby="Upload Plan"
      aria-describedby="upload-plan"
      title="Upload Plan"
    >
      <Container>
        {!loading && (
          <VContainer>
            <FileUploader
              acceptedFiles={{
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                  [],
              }}
              onFileUpload={(event) => handleChange(event)}
            />
          </VContainer>
        )}
        {loading && (
          <VContainer>
            <LinearProgress
              variant="determinate"
              value={progress}
            />
            <div>{status}</div>
            {apiStatus !== 200 && progress === 100 && (
              <>
                <div>
                  System Response:
                  <br />
                  {sheetData}
                </div>
              </>
            )}
            <ButtonContainer>
              {apiStatus === 200 ? (
                <Button
                  onClick={lastContinue}
                  color="primary"
                  variant="contained"
                >
                  Continue
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  onClick={props.onClose}
                >
                  Cancel
                </Button>
              )}
            </ButtonContainer>
          </VContainer>
        )}
        {!loading && (
          <ButtonContainer>
            <Button
              variant="outlined"
              onClick={props.onClose}
            >
              Cancel
            </Button>
          </ButtonContainer>
        )}
      </Container>
    </CustomModal>
  );
};

export default UploadPlanModal;

const VContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding-top: 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding: 1em;
  padding-bottom: 0em;
  padding-top: 0;
  max-height: 75vh;
  overflow-y: auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1em;
`;
