export const HEADERS = [
  "Site Id",
  "Site Count",
  "Subject Count",
  "Visit Count",
  "Assessment Count",
  "Visit Overhead",
  "Assessment Cost",
  "Exclude Cost",
  "Site Cost",
  "Total Cost",
];
