import React from "react";
import MuiAppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core/styles";

const AppBar = ({ children }) => {
  const classes = useStyles();

  return (
    <MuiAppBar position="fixed" className={classes.appBar}>
      <div className={classes.root}>{children}</div>
    </MuiAppBar>
  );
};

export default AppBar;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    height: "64px",
    padding: "0 2em",
    margin: ".5em 0",
  },
  appBar: {
    background: "#002e6e",
  },
}));
