import React from "react";
import styled from "styled-components";
import Label from "../label";

const ScenarioA = ({ siteGroup }) => {
  const {
    expectedScreenFailureRate,
    expectedTreatmentDropoutRate,
    parameters,
    milestones,
    timeCountryStartUpSize,
    timeSiteIdSize,
  } = siteGroup;

  const [sites_to_activate, subjects_to_screen] = parameters;
  const [
    number_patients_enrolled,
    number_patients_treatment,
    number_patients_follow_up,
  ] = milestones.filter(
    ({ milestoneId }) =>
      milestoneId === 144 || milestoneId === 147 || milestoneId === 150,
  );

  const labelsData = [
    {
      value: timeSiteIdSize ?? "- - ",
      variant: "secondary",
      text: `Site ID Effort ${timeSiteIdSize && " - "}`,
    },
    {
      value: sites_to_activate.lowerBound ?? "- - ",
      text: sites_to_activate.parameterDescr,
    },
    {
      value: expectedScreenFailureRate ?? "- - ",
      text: "Screen Fail Rate (in decimals)",
    },
    {
      value: number_patients_enrolled.lowerBound ?? "- - ",
      text: number_patients_enrolled.milestoneDescr,
    },
    {
      value: number_patients_follow_up.lowerBound ?? "- - ",
      text: number_patients_follow_up.milestoneDescr,
    },
    {
      value: timeCountryStartUpSize ?? "- - ",
      variant: "secondary",
      text: `Country Start-Up Time Frame ${timeCountryStartUpSize && " - "}`,
    },
    {
      value: subjects_to_screen.lowerBound ?? "- - ",
      text: subjects_to_screen.parameterDescr,
    },
    {
      value: expectedTreatmentDropoutRate ?? "- - ",
      text: "Drop Out Rate (in decimals)",
    },
    {
      value: number_patients_treatment.lowerBound ?? "- - ",
      text: number_patients_treatment.milestoneDescr,
    },
  ];

  return (
    <Container>
      <Column>
        {labelsData.slice(0, 5).map((label, index) => (
          <Label
            key={index}
            value={label.value}
            variant={label.variant}
          >
            {label.text}
          </Label>
        ))}
      </Column>
      <Column>
        {labelsData.slice(5).map((label, index) => (
          <Label
            key={index}
            value={label.value}
            variant={label.variant}
          >
            {label.text}
          </Label>
        ))}
      </Column>
    </Container>
  );
};

export default ScenarioA;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
`;

const Column = styled.div`
  display: grid;
  grid-gap: 1em;
  height: min-content;
`;
