import React, { useMemo } from "react";
import styled from "styled-components";
import { useStudyPlan } from "../../../../../app/contexts/study.context";
import Label from "../label";

const LabelSection = ({ value, description }) => (
  <Label value={value ?? "- - "}>{description}</Label>
);

const FlexLabelSection = ({ value1, description1, value2, description2 }) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <Label value={value1 ?? "- - "}>{description1}</Label>
    <span style={{ margin: "0 10px" }}>/</span>
    <Label value={value2 ?? "- - "}>{description2}</Label>
  </div>
);

const ScenarioB = () => {
  const { studyPlan } = useStudyPlan();
  const {
    screenFailRate,
    dropOutRate,
    overallEnrollmentDuration,
    screeningPeriodDuration,
    treatmentDuration,
    followUpDuration,
    parameters,
    milestones,
    pauseEnrollmentAfterPatient,
    pauseEnrollmentDays,
    cohortType,
    studyPlanTypeDetail,
  } = studyPlan;
  const [sites_to_activate, subjects_to_screen] = parameters;
  const [
    number_patients_enrolled,
    number_patients_treatment,
    number_patients_follow_up,
  ] = milestones.filter(
    ({ milestoneId }) =>
      milestoneId === 144 || milestoneId === 147 || milestoneId === 150,
  );

  const isNotSequentialCohort = useMemo(
    () => cohortType !== 2 && studyPlanTypeDetail !== "Cohort (D)",
    [cohortType, studyPlanTypeDetail],
  );

  return (
    <Container>
      <Content>
        <div>
          <LabelSection
            value={overallEnrollmentDuration}
            description="Overall Enrollment Duration (days)"
          />
          <LabelSection
            value={sites_to_activate.lowerBound}
            description={sites_to_activate.parameterDescr}
          />
          {isNotSequentialCohort && (
            <>
              <LabelSection
                value={pauseEnrollmentAfterPatient}
                description="Pause Enrollment After Patient #"
              />
              <LabelSection
                value={pauseEnrollmentDays}
                description="Resume Screening After (days)"
              />
            </>
          )}
        </div>
        <div>
          <LabelSection
            value={subjects_to_screen.lowerBound}
            description={subjects_to_screen.parameterDescr}
          />
          <FlexLabelSection
            value1={number_patients_enrolled.lowerBound}
            description1={number_patients_enrolled.milestoneDescr}
            value2={screenFailRate}
            description2="Screen Fail Rate (in decimals)"
          />
          <FlexLabelSection
            value1={number_patients_treatment.lowerBound}
            description1={number_patients_treatment.milestoneDescr}
            value2={dropOutRate}
            description2="Drop Out Rate (in decimals)"
          />
          <LabelSection
            value={number_patients_follow_up.lowerBound}
            description={number_patients_follow_up.milestoneDescr}
          />
        </div>
        <div>
          <LabelSection
            value={screeningPeriodDuration}
            description="Screening Period Per Patient (days)"
          />
          <LabelSection
            value={treatmentDuration}
            description="Treatment Period Per Patient (days)"
          />
          <LabelSection
            value={followUpDuration}
            description="Follow-Up Period Per Patient (days)"
          />
        </div>
      </Content>
    </Container>
  );
};

export default ScenarioB;

const Container = styled.div``;

const Content = styled.div`
  display: flex;
  width: 100%;

  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5em;
  }
`;
