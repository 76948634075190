import { Cell, Container, Row } from "..";

import { Accordion } from "../../../../../app/components";
import React from "react";

export default function EPriceTable({ data }) {
  const renderTable = () => {
    return data.map((d, idx) => {
      return (
        <Row style={{ width: "40%" }}>
          <Cell
            variant="header"
            justify="flex-end"
            width="75%"
            textAlign="right"
            style={{ borderBottom: "none" }}
          >
            {d.rowLabel}
          </Cell>
          <Cell width="25%">{d.cellValue1}</Cell>
        </Row>
      );
    });
  };

  return (
    <Accordion scrollIntoView={true} width={"100%"} summary={"Study Specs"}>
      <Container>{renderTable()}</Container>
    </Accordion>
  );
}
