export const FIELD_TYPES = {
  COST: "COST",
  MEDICAL: "MEDICAL",
  DATA_MANAGEMENT: "DATA_MANAGEMENT",
  VISIT: "VISIT",
};

export const FIELD_TYPE_OPTIONS = [
  { value: FIELD_TYPES.VISIT, label: "Visit" },
  { value: FIELD_TYPES.COST, label: "Cost" },
  { value: FIELD_TYPES.MEDICAL, label: "Clinical Strategy" },
  { value: FIELD_TYPES.DATA_MANAGEMENT, label: "Data Management" },
];

export const VISIT_REQUIRED_FIELDS = {
  visitCategory: true,
  visitName: true,
  visitStudyDay: true,
};

export const ASSESSMENT_REQUIRED_FIELDS = {
  assessmentType: true,
  assessmentName: true,
  assessmentCost: false,
};

export const REQUIRED_FIELDS_ERROR_MESSAGES = {
  visitCategory: "Visit Category is required for each visit entry.",
  visitName:
    "Visit Name is required and need to be unique for each visit entry.",
  // visitCost: "Visit cost is required for each visit entry.",
  visitStudyDay: (type) => `Visit study ${type} is required.`,
  assessmentType: "Assessment Type is required for each assessment entry.",
  assessmentName:
    "Assessment Name is required and need to be unique for each assessment entry.",
  assessmentCost: "Assessment cost is required for each assessment entry.",
  assessmentNameType:
    "Assessment Name Mapping should be empty or be selected from the list.",
};

export const NUMBER_FIELDS = {
  visitCost: true,
  assessmentCost: true,
  visitStudyDay: true,
};

export const UNIQUE_FIELDS = { assessmentName: true, visitName: true };

export const INITIAL_RISK_ASSESSMENT_COLORS = {
  GREEN: "#70AD47",
  RED: "#FF0000",
  YELLOW: "#FFC000",
};

export const VSS_HEADER_COLORS = [
  "5B9BD5",
  "1F4E79",
  "F4B183",
  "F4B183",
  "FFC000",
  "70AD47",
  "00539B",
];

export const VISIT_VARIANCE_TYPE_OPTIONS = [
  { value: "", label: "None" },
  { value: "+", label: "+" },
  { value: "-", label: "-" },
  { value: "+/-", label: "+/-" },
];
export const VISIT_CATEGORY_TYPES = [
  "Screening",
  "Treatment",
  "Follow Up",
  "Close Out",
  "Unscheduled",
];
