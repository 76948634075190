import React, { useState } from "react";

import { Button } from "@material-ui/core";
import ExcelExportsModal from "./excel.exports.modal";
import styled from "styled-components";

export default function ExcelExports() {
  const [open, setOpen] = useState(false);
  return (
    <Container>
      <Button variant="outlined" onClick={() => setOpen(true)}>
        Export Tables
      </Button>
      <ExcelExportsModal open={open} handleClose={() => setOpen(false)} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  // height: fit-content;
`;
