import React, { useState } from "react";
import API from "../../app/utils/api";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory, useLocation } from "react-router-dom";
import { debounce } from "lodash";
import { useSnackbar } from "../../app/contexts/snackbar.context";

const Search = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [options, setOptions] = useState([]);
  const location = useLocation();
  // paramaterizing 'study plans', 'study actuals' part of URL - TODO: useParams() to replace this?
  const currTab = location.pathname.split("/").slice(2, 3);
  const { showSnackbar } = useSnackbar();

  const handleSearch = debounce((text) => {
    API.getStudies(text).then((res) => setOptions(res.data));
  }, 300);

  const handleChange = (event, selected) => {
    if (!selected || (!selected?.studyId && selected?.studyId !== 0)) {
      showSnackbar("Select Project from search results", "info");
      return;
    }
    selected && history.push(`/ctpo/${currTab}/${selected.studyId}`);
  };

  return (
    <Autocomplete
      id="autocomplete"
      freeSolo
      classes={classes}
      options={options}
      onChange={handleChange}
      getOptionLabel={(option) => {
        if (!option?.projectId || !option.studyName) {
          return option;
        }
        return `${option.projectId} - ${option.studyName}`;
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={(e) =>
            handleSearch(e.target.value.replace(/(\/|#|\\|&)/g, ""))
          }
          variant="outlined"
          placeholder="Search..."
          InputProps={{ ...params.InputProps }}
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
    />
  );
};

export default Search;

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    backgroundColor: "white",
  },
}));
