import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useScheduleEvents } from "../../../app/contexts/schedule.events.context";
import { LinearProgress } from "@material-ui/core";
import Header from "./header";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Controls from "./controls";
import SummaryTable from "./summary.table";
import DetailsTable from "./details.table";
import ExcelExports from "./excel.exports";
import DetailRFPTable from "./details.rfp.table";
import SummaryRFPTable from "./summary.rfp.table";

/*
  This component is responsible for rendering the schedule of events forecast table.
  It uses the schedule events context to handle the schedule of events.
*/
export default function SOEForecast() {
  const [starting, setStarting] = useState(true);
  const { scheduleId } = useParams();
  const {
    SOEForecastLoading,
    fetchSOEForecastDetail,
    fetchSOEForecastSummary,
    fetchSOEForecastRFPDetail,
    fetchSOEForecastRFPSummary,
    SOEForecastSummary,
    SOEForecastDetail,
    SOEForecastRFPSummary,
    SOEForecastRFPDetail,
    fetchASingleSOE,
  } = useScheduleEvents();
  useEffect(() => {
    (async () => {
      if (scheduleId) {
        await fetchASingleSOE(scheduleId);
        await fetchSOEForecastDetail(scheduleId);
        await fetchSOEForecastSummary(scheduleId);
        await fetchSOEForecastRFPDetail(scheduleId);
        await fetchSOEForecastRFPSummary(scheduleId);
      }
    })();
    // eslint-disable-next-line
  }, [scheduleId]);

  useEffect(() => {
    if (SOEForecastLoading) setStarting(false);
  }, [SOEForecastLoading]);
  return (
    <Container>
      {SOEForecastLoading || starting ? (
        <LinearProgress />
      ) : (
        <>
          <Controls />
          <Header />
          <ExcelExports />
          <SummaryTable summary={SOEForecastSummary} />
          <DetailsTable details={SOEForecastDetail} />
          <SummaryRFPTable summary={SOEForecastRFPSummary} />
          <DetailRFPTable details={SOEForecastRFPDetail} />
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  grid-gap: 2em;
`;
