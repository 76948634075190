import React from "react";
import styled from "styled-components";
import { ReactComponent as ReactLogo } from "./logo.svg";

const Logo = () => <StyledImage />;

export default Logo;

const StyledImage = styled(ReactLogo)`
  height: 70px;
`;
