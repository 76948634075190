import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import InsertDriveFile from "@material-ui/icons/InsertDriveFile";
import styled from "styled-components";

/*
  This component is a file uploader that allows the user to upload a file
  and returns the file to the parent component.
  It uses react-dropzone to handle the file upload.
  The parent component must pass a callback function to handle the file upload.
  The parent component must also pass an array of accepted file types.
*/
const FileUploader = ({ onFileUpload, acceptedFiles }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const onDrop = useCallback(
    (uploadedFiles) => {
      if (!uploadedFiles?.length) return;
      setSelectedFiles([uploadedFiles.at(-1)]);
      onFileUpload([uploadedFiles.at(-1)]);
    },
    [onFileUpload]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedFiles,
  });

  return (
    <FileUploadContainer className="file-uploader">
      <Dropzone {...getRootProps()} className="dropzone">
        <input {...getInputProps()} />
        {!selectedFiles?.length ? (
          <div>
            <p style={{ margin: 0 }}>
              Drag & drop files here, or click to select files
            </p>
            <p
              style={{
                margin: 0,
                fontSize: "0.8em",
                color: "#555",
                marginTop: "0.4rem",
              }}
            >
              Only *.pdf files will be accepted
            </p>
          </div>
        ) : (
          <UploadedContainer key={selectedFiles[0]?.name} style={{ margin: 0 }}>
            <span style={{ margin: "10px" }}>{selectedFiles[0]?.name}</span>
            <InsertDriveFile style={{ width: "2rem", height: "2rem" }} />
          </UploadedContainer>
        )}
      </Dropzone>
    </FileUploadContainer>
  );
};

const FileUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 15rem;
  width: 100%;
`;

const Dropzone = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border: 2px dashed #cccccc;
  border-radius: 4px;
  background-color: #fafafa;
  cursor: pointer;
  padding: 20px;
  text-align: center;
`;

const UploadedContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export default FileUploader;
