import moment from "moment";
import { nanoid } from "nanoid";

/*
 * @param {string} studyPlanId - the id of the study plan
 * @returns {object} - the default values for the study plan
 */
export const getSequentialTableDefaults = (studyPlanId) => ({
  studyPlanId,
  cohortName: "",
  predecessorCohortName: "",
  predecessorActivity: "",
  patients: "",
  lagDays: "",
  cohortStartDate: "",
  cohortStartDay: "",
  screeningDurationDays: "",
  screeningEndDate: "",
  screeningEndDay: "",
  enrollingDurationDays: "",
  enrollingEndDate: "",
  enrollingEndDay: "",
  dtlDurationDays: "",
  dtlEndDate: "",
  dtlEndDay: "",
  srcDurationDays: "",
  srcEndDate: "",
  srcEndDay: "",
  key: nanoid(),
});

/*
 * @param {string} studyPlanId - the id of the study plan
 * @returns {object} - the default values for the study plan
 * @param {object} defaults - the default values for the study plan
 */
export const getScheduleOfEventsVisitTableDefaults = (
  scheduleId,
  studyId,
  defaults = {}
) => ({
  scheduleOfEventsId: scheduleId,
  studyId: parseInt(studyId || "0"),
  visitName: "",
  visitStudyDay: "",
  visitCost: null,
  visitVarianceType: "",
  visitVariance: "",
  visitCategory: "",
  miEstSDRSDV: null,
  miSampleVisit: 0,
  miSuggestedOnSiteSDRSDV: null,
  miSuggestedCDAReview: null,
  miEstCDAReportFrequency: null,
  dmUniqueCRFs: null,
  dmNonUniqueCRFs: null,
  dmIntegratedEpro: null,
  dmPaperEpro: null,
  dmCentralLab: null,
  dmLocalLab: null,
  key: nanoid(),
  ...defaults,
});

/*
 * @param {string} studyPlanId - the id of the study plan
 * @returns {object} - the default values for the study plan
 * @param {object} defaults - the default values for the study plan
 */
export const getScheduleOfEventsAssessmentTableDefaults = (
  scheduleId,
  studyId,
  additional = {}
) => ({
  scheduleOfEventsId: scheduleId,
  studyId: parseInt(studyId || "0"),
  assessmentName: "",
  assessmentNameMapping: "",
  assessmentType: "",
  assessmentCost: "",
  overhead: 0,
  exclude: 0,
  key: nanoid(),
  ...additional,
});

/*
  The object below is used to define the styles for the excel files export header .
*/
export const HeaderStyle1 = {
  font: { bold: true, color: { rgb: "1B356A" }, sz: 12 },
  fill: { fgColor: { rgb: "EEF8FD" } },
  innerWidth: 20,
  alignment: { wrapText: true, horizontal: "center" },
  border: {
    top: { style: "thin", color: { rgb: "5A5A5A" } },
    right: { style: "thin", color: { rgb: "5A5A5A" } },
    bottom: { style: "thin", color: { rgb: "5A5A5A" } },
    left: { style: "thin", color: { rgb: "5A5A5A" } },
  },
};
/*
  The object below is used to define the styles for the excel files export sub header .
*/
export const HeaderStyle2 = {
  font: { bold: true, color: { rgb: "FFFFFF" }, sz: 11 },
  fill: { fgColor: { rgb: "102860" } },
  alignment: { wrapText: true, horizontal: "center" },
  border: {
    top: { style: "thin", color: { rgb: "5A5A5A" } },
    right: { style: "thin", color: { rgb: "5A5A5A" } },
    bottom: { style: "thin", color: { rgb: "5A5A5A" } },
    left: { style: "thin", color: { rgb: "5A5A5A" } },
  },
};

/*
  The object below is used to define the styles for the excel files export body .
*/
export const BodyStyle = {
  alignment: { wrapText: true, horizontal: "center" },
  border: {
    top: { style: "thin", color: { rgb: "5A5A5A" } },
    right: { style: "thin", color: { rgb: "5A5A5A" } },
    bottom: { style: "thin", color: { rgb: "5A5A5A" } },
    left: { style: "thin", color: { rgb: "5A5A5A" } },
  },
};

/*
 * @param {string} value - the value to be converted
 * @returns {string} - the converted value
 * The function determines the type of the value and converts it to the appropriate type.
 */
export const getCellType = (value) => {
  if (!value && value !== 0) return "s";
  if (value instanceof Date) return "d";
  if (!isNaN(value)) return "n";
  return "s";
};

/*
  The object below is used to define the configuration for the excel files export .
*/
export const DEFAULT_DATE_CONFIG = {
  cellDates: true,
  dateNF: "DD MMM YYYY",
};

/*
 * @param {string} str - the value to be converted
 * @returns {string} - the converted value
 * The function adjusts the offset of the date object to the current timezone.
 * and returns the date object. if the value is not a valid date,
 * it returns the value.
 */
export const getDateObject = (str) => {
  if (!str) return null;
  const offset = new Date().getTimezoneOffset();
  let dateValue =
    offset > 0
      ? moment(str).add(offset, "minutes").toDate()
      : moment(str).subtract(offset, "minutes").toDate();
  return dateValue === "Invalid Date" ? str : dateValue;
};
