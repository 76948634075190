const validateStudyPlan = (
  studyPlan,
  studyPlanType,
  SCENARIO_A,
  SCENARIO_B,
  study
) => {
  let errors = {};

  if (!studyPlan?.studyPlanName) {
    errors.studyPlanName = "This field is required.";
  }

  let studyPlanNameNotUnique = false;
  // eslint-disable-next-line
  study?.studyPlans?.forEach((sp) => {
    const { studyPlanId } = studyPlan;
    if (
      studyPlanId !== sp.studyPlanId &&
      sp?.studyPlanName?.toLowerCase().trim() ===
        studyPlan.studyPlanName?.toLowerCase().trim()
    ) {
      studyPlanNameNotUnique = true;
      return false;
    }
    return true;
  });
  if (studyPlanNameNotUnique) {
    errors.studyPlanName = "Study Plan Name must be unique";
  }

  if (!studyPlan?.studyPlanDescr) {
    errors.studyPlanDescr = "This field is required.";
  }

  if (studyPlanType === SCENARIO_A) {
    let overallActivatedSites = 0;
    let countryEnrollmentRateSum = 0;

    studyPlan.parameters.forEach((parameter) => {
      if (parameter.parameterName === "SITES_TO_ACTIVATE") {
        overallActivatedSites = parameter.lowerBound;
      }
    });

    studyPlan.countries.forEach((country) => {
      country.siteGroups.forEach((siteGroup) => {
        siteGroup.parameters.forEach((sp) => {
          if (sp.parameterName === "SITES_TO_ACTIVATE") {
            countryEnrollmentRateSum += sp.lowerBound;
          }
        });
      });
    });

    if (overallActivatedSites < countryEnrollmentRateSum) {
      errors = {
        ...errors,
        ENROLLMENT_RATE: `The sum of each country activated sites(lower bound) shouldn't exceed overall activated sites.`,
        BUTTON_TYPE: "ALL",
      };
    }
  } else if (studyPlanType === SCENARIO_B) {
    let overallActivatedSites = 0;
    studyPlan.parameters.forEach((parameter) => {
      if (parameter.parameterName === "SITES_TO_ACTIVATE") {
        overallActivatedSites = parameter.lowerBound;
      }
    });
    let countryActivatedSitesSum = 0;
    studyPlan.countries.forEach((country) => {
      country.siteGroups.forEach((siteGroup) => {
        siteGroup.parameters.forEach((sp) => {
          if (sp.parameterName === "SITES_TO_ACTIVATE") {
            countryActivatedSitesSum += sp.lowerBound;
          }
        });
      });
    });

    if (overallActivatedSites !== countryActivatedSitesSum) {
      errors = {
        ...errors,
        ENROLLMENT_DURATION: `The sum of each country activated sites should be equal to overall activated sites.`,
        BUTTON_TYPE: "NEXT2",
      };
    }
  }

  let numberOfPatientsScreened = 0;
  let numberOfPatientsEnrolled = 0;
  let numberOfPatientsCompletedTreatment = 0;
  let numberOfPatientsCompletedFollowUp = 0;

  studyPlan.milestones.forEach((milestone) => {
    switch (milestone.milestoneName) {
      case "NUM_SUBJECTS_ENROLLED":
        numberOfPatientsEnrolled = milestone.lowerBound;
        break;
      case "NUM_SUBJECTS_TREATED":
        numberOfPatientsCompletedTreatment = milestone.lowerBound;
        break;
      case "NUM_SUBJECTS_COMPLETED":
        numberOfPatientsCompletedFollowUp = milestone.lowerBound;
        break;
      default:
        break;
    }
  });

  studyPlan.parameters.forEach((parameter) => {
    if (parameter.parameterName === "SUBJECTS_TO_SCREEN") {
      numberOfPatientsScreened = parameter.lowerBound;
    }
  });

  if (numberOfPatientsScreened <= 0) {
    errors = {
      ...errors,
      PATIENT_NUM_VALID1: `Number of patients screened must be greater than 0`,
      BUTTON_TYPE: "ALL",
    };
  }

  if (numberOfPatientsEnrolled || numberOfPatientsEnrolled === 0) {
    if (
      numberOfPatientsEnrolled > numberOfPatientsScreened &&
      numberOfPatientsScreened
    ) {
      errors = {
        ...errors,
        PATIENT_COUNT1: `Number of patients enrolled shouldn't be greater than the number of patients screened`,
        BUTTON_TYPE: "ALL",
      };
    }

    if (numberOfPatientsEnrolled <= 0) {
      errors = {
        ...errors,
        PATIENT_NUM_VALID2: `Number of patients enrolled must be greater than 0`,
        BUTTON_TYPE: "ALL",
      };
    }
  }

  if (
    numberOfPatientsCompletedTreatment ||
    numberOfPatientsCompletedTreatment === 0
  ) {
    if (
      (numberOfPatientsCompletedTreatment > numberOfPatientsEnrolled &&
        numberOfPatientsEnrolled) ||
      (numberOfPatientsCompletedTreatment > numberOfPatientsScreened &&
        numberOfPatientsScreened)
    ) {
      errors = {
        ...errors,
        PATIENT_COUNT2: `Number of patients completing treatment shouldn't be greater than the number of patients screened or enrolled`,
        BUTTON_TYPE: "ALL",
      };
    }

    if (numberOfPatientsCompletedTreatment <= 0) {
      errors = {
        ...errors,
        PATIENT_NUM_VALID3: `Number of patients completing treatment must be greater than 0`,
        BUTTON_TYPE: "ALL",
      };
    }
  }

  if (
    numberOfPatientsCompletedFollowUp ||
    numberOfPatientsCompletedFollowUp === 0
  ) {
    if (
      (numberOfPatientsCompletedFollowUp > numberOfPatientsCompletedTreatment &&
        numberOfPatientsCompletedTreatment) ||
      (numberOfPatientsCompletedFollowUp > numberOfPatientsEnrolled &&
        numberOfPatientsEnrolled) ||
      (numberOfPatientsCompletedFollowUp > numberOfPatientsScreened &&
        numberOfPatientsScreened)
    ) {
      errors = {
        ...errors,
        PATIENT_COUNT3: `Number of patients completing follow up shouldn't be greater than the number of patients completing treatment, screened or enrolled`,
        BUTTON_TYPE: "ALL",
      };
    }
    if (numberOfPatientsCompletedFollowUp <= 0) {
      errors = {
        ...errors,
        PATIENT_NUM_VALID3: `Number of patients completing follow up must be greater than 0`,
        BUTTON_TYPE: "ALL",
      };
    }
  }
  return errors;
};

export default validateStudyPlan;
