import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useStudyPlan } from "../../../app/contexts/study.context";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";

import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";

import TopControls from "./top.controls";
import SummaryOfOverallParameters from "./summary.of.overall.parameters";
import Tables from "./tables";
import Charts from "./charts";
import { useUser } from "../../../app/contexts/user.context";
import { useMemo } from "react";
import { Box } from "@material-ui/core";
import TableControls from "./tables/table.controls";
const REFRESH_INTERVAL = 15000;
const NOT_STARTED = "NOT_STARTED";
const COMPLETED_OK = "COMPLETED_OK";
const REQUESTING = "REQUESTING";
const RUNNING = "RUNNING";
const PROCESSING = "PROCESSING";
const BAD_REQUEST = "BAD_REQUEST";
const INTERNAL_ERROR = "INTERNAL_ERROR";
const TEMPORARY_PROBLEM = "TEMPORARY_PROBLEM";
const ERROR_CLIENT_BAD_REQ = "ERROR_CLIENT_BAD_REQ";
const DEFAULT_STATUS = NOT_STARTED;

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          variant="determinate"
          {...props}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const Simulation = () => {
  const history = useHistory();
  const [status, setStatus] = useState(DEFAULT_STATUS);
  const [results, setResults] = useState(null);
  const [tab, setTab] = useState(0);
  const { id, planId } = useParams();
  const type = new URLSearchParams(history.location.search).get("type");
  const [progress, setProgress] = useState(0);
  const [madeAllRequests, setMadeAllRequests] = useState(false);
  const {
    fetchSimulationStartupTable,
    fetchSimulationEnrollmentTable,
    fetchSimulationSummaryTable,
    fetchSimulationTimelineTable,
    fetchRegionChart,
    fetchSiteGroupChart,
    fetchCohortChartMonth,
    fetchCohortTimelineChart,
    // fetchCohortChartWeek,
    fetchSimulationDetailsByWeek,
    fetchSimulationDetailsByMonth,
    fetchSimulationDetailsByMonthNonCumulative,
    fetchSimulationDetailsByWeekNonCumulative,
    fetchSimulationDurationTable,
    studyPlan,
    checkSimulationStatus,
    clearSimData,
    simulationResults,
  } = useStudyPlan();
  const { studyPlanId, cohortType } = studyPlan;
  const classes = useStyles();
  const { setURLStudyId } = useUser();
  const [intervalId, setIntervalId] = useState(null);
  const [scrolled, setScrolled] = useState(false);

  const studyType = cohortType !== 0 ? "cohort" : "results";

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const tableGraphDataStatus = useMemo(() => {
    const {
      detailsByMonth,
      detailsByMonthNonCumulative,
      detailsByWeek,
      detailsByWeekNonCumulative,
      detailsByMonthCohort,
      detailsByMonthNonCumulativeCohort,
      detailsByWeekCohort,
      detailsByWeekNonCumulativeCohort,
      durationTable = [],
      enrollmentTable,
      startupTable,
      summaryTable,
      summaryTableCohort,
      timelineTable,
      cohortChartMonthly,
      cohortTimelineChart,
      regionChartMonthly,
      siteGroupChartMonthly,
      regionChartWeekly,
      siteGroupChartWeekly,
      // cohortChartWeekly,
    } = simulationResults || {};
    const totalRequests = studyType === "cohort" ? 20 : 13;
    const completedRequests = (
      studyType === "cohort"
        ? [
            !!detailsByMonth,
            !!detailsByMonthNonCumulative,
            !!detailsByWeek,
            !!detailsByWeekNonCumulative,
            !!detailsByMonthCohort,
            !!detailsByMonthNonCumulativeCohort,
            !!detailsByWeekCohort,
            !!detailsByWeekNonCumulativeCohort,
            !!durationTable,
            !!enrollmentTable,
            !!startupTable,
            !!summaryTable,
            !!summaryTableCohort,
            !!timelineTable,
            !!cohortChartMonthly,
            !!cohortTimelineChart,
            !!regionChartMonthly,
            !!siteGroupChartMonthly,
            !!regionChartWeekly,
            !!siteGroupChartWeekly,
            // !!cohortChartWeekly,
          ]
        : [
            !!detailsByMonth,
            !!detailsByMonthNonCumulative,
            !!detailsByWeek,
            !!detailsByWeekNonCumulative,
            !!durationTable,
            !!enrollmentTable,
            !!startupTable,
            !!summaryTable,
            !!timelineTable,
            !!regionChartMonthly,
            !!siteGroupChartMonthly,
            !!regionChartWeekly,
            !!siteGroupChartWeekly,
          ]
    ).filter((v) => v).length;
    setProgress((completedRequests / totalRequests) * 100);
    if (madeAllRequests) return "resolved";
    if (studyType === "cohort") {
      if (
        detailsByMonth &&
        detailsByMonthNonCumulative &&
        detailsByWeek &&
        detailsByWeekNonCumulative &&
        detailsByMonthCohort &&
        detailsByMonthNonCumulativeCohort &&
        detailsByWeekCohort &&
        detailsByWeekNonCumulativeCohort &&
        durationTable &&
        enrollmentTable &&
        startupTable &&
        summaryTable &&
        summaryTableCohort &&
        timelineTable &&
        regionChartMonthly &&
        siteGroupChartMonthly &&
        cohortChartMonthly &&
        cohortTimelineChart &&
        regionChartWeekly &&
        siteGroupChartWeekly
      )
        return "resolved";
    } else {
      if (
        detailsByMonth &&
        detailsByMonthNonCumulative &&
        detailsByWeek &&
        detailsByWeekNonCumulative &&
        durationTable &&
        enrollmentTable &&
        startupTable &&
        summaryTable &&
        timelineTable &&
        regionChartMonthly &&
        siteGroupChartMonthly &&
        regionChartWeekly &&
        siteGroupChartWeekly
      )
        return "resolved";
    }
    return "loading";
    // eslint-disable-next-line
  }, [simulationResults, madeAllRequests]);

  useEffect(() => {
    setURLStudyId(id);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    clearSimData();
    if (!studyPlanId) {
      history.push(`/ctpo/study-plans/${id}/${planId}`);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (status !== DEFAULT_STATUS) {
      //get results;
    }
  }, [status]);

  useEffect(() => {
    const iID = setInterval(async () => {
      if (
        status === COMPLETED_OK ||
        type === "view" ||
        status === BAD_REQUEST ||
        status === INTERNAL_ERROR ||
        status === TEMPORARY_PROBLEM ||
        status === ERROR_CLIENT_BAD_REQ
      ) {
        return;
      }
      const simulation_response = await checkSimulationStatus();
      setStatus(simulation_response.simOptRunStatus);
      setResults(simulation_response);
    }, REFRESH_INTERVAL);

    setIntervalId(iID);

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      if (
        status === COMPLETED_OK ||
        type === "view" ||
        status === BAD_REQUEST ||
        status === INTERNAL_ERROR ||
        status === TEMPORARY_PROBLEM ||
        status === ERROR_CLIENT_BAD_REQ
      ) {
        if (intervalId) clearInterval(intervalId);
      }
      if (status === COMPLETED_OK || type === "view") {
        if (intervalId) clearInterval(intervalId);

        if (intervalId) {
          await fetchSimulationDurationTable();
          await fetchSimulationStartupTable();
          await fetchSimulationEnrollmentTable();
          await fetchSimulationSummaryTable();
          await fetchSimulationTimelineTable();
          await fetchRegionChart();
          await fetchSiteGroupChart();
          await fetchSimulationDetailsByMonth();
          await fetchSimulationDetailsByMonthNonCumulative();
          await fetchSimulationDetailsByWeek();
          await fetchSimulationDetailsByWeekNonCumulative();
          if (studyType === "cohort") {
            await fetchSimulationSummaryTable(studyType);
            await fetchCohortChartMonth();
            await fetchCohortTimelineChart();
            await fetchSimulationDetailsByMonth(studyType);
            await fetchSimulationDetailsByMonthNonCumulative(studyType);
            await fetchSimulationDetailsByWeek(studyType);
            await fetchSimulationDetailsByWeekNonCumulative(studyType);
          }
          setMadeAllRequests(true);
        }
        return;
      }
    })();
    // eslint-disable-next-line
  }, [intervalId, status]);

  const handleBackToReviewPage = () =>
    history.push(`/ctpo/study-plans/${id}/${planId}/review`);

  return (
    <Container>
      {status === DEFAULT_STATUS && !(type === "view") && (
        <>
          <LinearProgress />
          <Typography>Simulation has not started.</Typography>
        </>
      )}
      {status === RUNNING && (
        <>
          <LinearProgress />
          <Typography>Simulation is running...</Typography>
        </>
      )}
      {status === PROCESSING && (
        <>
          <LinearProgress />
          <Typography>
            <div>Status: {results?.simOptRunStatus}</div>
            <div>{results?.simOptRunMsg || "Simulation is processing..."}</div>
            {results?.simOptRunDetail && <div>{results?.simOptRunDetail}</div>}
          </Typography>
        </>
      )}
      {status === REQUESTING && (
        <>
          <LinearProgress />
          <Typography>Requesting...</Typography>
        </>
      )}
      {(status === BAD_REQUEST ||
        status === INTERNAL_ERROR ||
        status === TEMPORARY_PROBLEM ||
        status === ERROR_CLIENT_BAD_REQ) && (
        <>
          <ButtonContainer>
            <Typography style={{ marginBottom: "1em" }}>
              {status === INTERNAL_ERROR ? (
                <>
                  <div>Status: {results?.simOptRunStatus}</div>
                  <div>{results?.simOptRunMsg || "Simulation failed."}</div>
                  {results?.simOptRunDetail && (
                    <div>{results?.simOptRunDetail}</div>
                  )}
                </>
              ) : (
                "Simulation failed."
              )}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleBackToReviewPage}
            >
              <ChevronLeftIcon />
              Back to Review Page
            </Button>
          </ButtonContainer>
        </>
      )}
      {(status === COMPLETED_OK || type === "view") && (
        <>
          <ControlsContainer className={scrolled ? "scrolled" : ""}>
            <TopControls />
          </ControlsContainer>
          <SummaryOfOverallParameters />
          {tableGraphDataStatus === "resolved" && (
            <>
              <Content>
                <div>
                  <AppBar
                    position="static"
                    color="transparent"
                    classes={classes}
                  >
                    <Tabs
                      value={tab}
                      onChange={(event, tab) => setTab(tab)}
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="scrollable auto tabs example"
                      indicatorColor="primary"
                    >
                      <Tab label="Tables" />
                      <Tab label="Charts" />
                    </Tabs>
                  </AppBar>
                </div>
                <div>{tab === 0 && <TableControls type={studyType} />}</div>
              </Content>
              <div
                style={{ display: "grid", gridGap: "2em" }}
                role="tabpanel"
                hidden={tab !== 0}
                id={`scrollable-auto-tabpanel-${0}`}
              >
                {tab === 0 && <Tables />}
              </div>
              <div
                style={{ display: "grid", gridGap: "2em" }}
                role="tabpanel"
                hidden={tab !== 1}
                id={`scrollable-auto-tabpanel-${1}`}
              >
                {tab === 1 && <Charts />}
              </div>
            </>
          )}

          {tableGraphDataStatus === "loading" && (
            <ProgressContainer>
              <Typography variant="body1">
                Fetching tables and chart data...
              </Typography>
              <LinearProgressWithLabel
                variant="determinate"
                value={progress}
              />
            </ProgressContainer>
          )}
        </>
      )}
    </Container>
  );
};

export default Simulation;

const Container = styled.div`
  display: grid;
  grid-gap: 0.5em;
`;

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: "none",
    width: "900px",
  },
}));

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ButtonContainer = styled.div`
  gap: 1em;
  margin: 1em;
`;

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

const ControlsContainer = styled.div`
  position: sticky;
  top: 11em;
  left: 0;

  background: white;
  z-index: 5;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1em;
  box-shadow: none;

  transition: box-shadow 0.4s ease-in-out;

  &.scrolled {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.25);
  }
`;
