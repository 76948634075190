import React from "react";
import styled from "styled-components";
import { SectionHeader } from "../../../../app/components";
import { useHistory, useParams } from "react-router-dom";
import { useStudyPlan } from "../../../../app/contexts/study.context";

import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import ScenarioA from "./scenario.a";
import ScenarioB from "./scenario.b";

const OverallParameters = () => {
  const history = useHistory();
  const { id, planId } = useParams();
  const { studyPlan, STUDY_PLAN_TYPES, canEditPlan } = useStudyPlan();

  const { studyPlanType } = studyPlan;
  const { SCENARIO_A, SCENARIO_B } = STUDY_PLAN_TYPES;
  let scenarioRender;
  if (studyPlanType === SCENARIO_A) {
    scenarioRender = <ScenarioA />;
  }

  if (studyPlanType === SCENARIO_B) {
    scenarioRender = <ScenarioB />;
  }

  return (
    <Container>
      <Header>
        <SectionHeader>Overall Study Inputs</SectionHeader>
        {canEditPlan && (
          <Button
            color="primary"
            onClick={() =>
              history.push(`/ctpo/study-plans/${id}/${planId}#overall-inputs`)
            }
          >
            <EditIcon style={{ marginRight: ".3em" }} /> Edit
          </Button>
        )}
      </Header>
      <Content>{scenarioRender}</Content>
    </Container>
  );
};

export default OverallParameters;

const Container = styled.div``;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Content = styled.div``;
