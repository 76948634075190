import React from "react";
import { useStudyPlan } from "../../../../../app/contexts/study.context";
import {
  calculateDropOutRate,
  calculateScreenFailRate,
} from "../../tables/summary.table";

const ChartMetrics = () => {
  const { simulationResults } = useStudyPlan();
  const { summaryTable } = simulationResults;
  const overallRowArr = summaryTable.filter(
    (obj) => obj.siteGroupName === "Overall"
  );
  const overallData = overallRowArr[0];
  const enrollmentRate = overallData["Enrollment Rate"];
  const dropOutRate = (calculateDropOutRate(overallData) * 100 || 0).toFixed(1);
  const screenFailRate = (
    calculateScreenFailRate(overallData) * 100 || 0
  ).toFixed(1);

  return (
    <div style={{ paddingTop: "20px", marginBottom: "15px" }}>
      <p style={{ fontFamily: "Arial", textAlign: "center" }}>
        Enrollment Rate: {enrollmentRate} p/s/m | Screen Fail Rate:{" "}
        {screenFailRate}% | Drop Out Rate: {dropOutRate}%
      </p>
    </div>
  );
};

export default ChartMetrics;