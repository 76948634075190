export const isIncreasing = (arr) => {
  if (arr.length === 0 || arr.length === 1) {
    return true;
  }

  return arr.every(_sameOrGreater);
};

function _sameOrGreater(elt, idx, arr) {
  var prev = arr[idx - 1];
  if (prev === undefined) {
    return true;
  }
  return elt >= prev;
}

export const nonNegative = (arr) => arr.every((elt) => elt >= 0);

export function hasDuplicates(array) {
  return new Set(array).size !== array.length;
}
