export const PRIMARY_COLOR = "#1a73e8";
export const PRIMARY_COLOR_DARK = "#002E6E";

export const LINKS = [
  {
    href: "https://analytics.premier-research.com/okta/sense/app/a6d024f1-ed67-4094-8db2-6366dc8341ca/sheet/7cc51354-ef05-4036-b435-1027cb2367dd/state/analysis",
    text: "Project Health Dashboard",
  },
  {
    href: "https://premier-research.okta.com/home/premierresearch_remarquerbm_1/0oa6b79srhDfYejZF1t7/aln6b7hthwvh8dWSD1t7",
    text: "Remarque",
  },
  {
    href: "https://premierresearch.sharepoint.com/sites/PremierPredict",
    text: "Help / More Information",
  },
];
