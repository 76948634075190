import React from "react";
import { MenuItem, TextField } from "@material-ui/core";
import styled from "styled-components";
import { RemoveCircle } from "@material-ui/icons";

export default function TableRow({
  sp,
  seqCohorts,
  onSeqChange,
  index,
  PREDECESSOR_ACTIVITY_OPTIONS,
  removeRow,
}) {
  return (
    <Row>
      <Cell width={`${(8 / 70) * 100}%`}>
        <TextField
          value={sp.cohortName}
          onChange={(e) =>
            onSeqChange(
              "cohortName",
              e.target.value,
              sp.studyPlanCohortId || sp.key,
              "string"
            )
          }
          variant="outlined"
          size="small"
        />
      </Cell>
      <Cell width={`${(8 / 70) * 100}%`}>
        {index !== 0 && (
          <TextField
            style={{ width: "100%" }}
            select
            InputLabelProps={{ shrink: false }}
            id="predecessorCohortName"
            value={sp.predecessorCohortName}
            onChange={(e) =>
              onSeqChange(
                "predecessorCohortName",
                e.target.value,
                sp.studyPlanCohortId || sp.key,
                "string"
              )
            }
            variant="outlined"
            size="small"
          >
            {seqCohorts
              .filter(
                (lsp) =>
                  (lsp.studyPlanCohortId || lsp.key) !==
                  (sp.studyPlanCohortId || sp.key)
              )
              .map((lsp) => {
                return (
                  <MenuItem
                    key={lsp.studyPlanCohortId || lsp.key}
                    value={lsp.cohortName}
                  >
                    {lsp.cohortName || "No Name"}
                  </MenuItem>
                );
              })}
          </TextField>
        )}
      </Cell>
      <Cell width={`${(6 / 70) * 100}%`}>
        {index !== 0 && (
          <TextField
            style={{ width: "100%" }}
            select
            InputLabelProps={{ shrink: false }}
            id="predecessorActivity"
            value={sp.predecessorActivity}
            onChange={(e) =>
              onSeqChange(
                "predecessorActivity",
                e.target.value,
                sp.studyPlanCohortId || sp.key,
                "string"
              )
            }
            variant="outlined"
            size="small"
          >
            {PREDECESSOR_ACTIVITY_OPTIONS.map((pao) => (
              <MenuItem key={pao.value} value={pao.value}>
                {pao.name}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Cell>
      <Cell width={`${(3 / 70) * 100}%`}>
        <TextField
          value={sp.patients}
          onChange={(e) => {
            if (isNaN(e.target.value)) return;
            onSeqChange(
              "patients",
              e.target.value,
              sp.studyPlanCohortId || sp.key
            );
          }}
          variant="outlined"
          size="small"
        />
      </Cell>
      <Cell className="remove_spin" width={`${(3 / 70) * 100}%`}>
        <TextField
          type="number"
          value={sp.lagDays}
          onChange={(e) => {
            if (isNaN(e.target.value)) return;
            onSeqChange(
              "lagDays",
              e.target.value,
              sp.studyPlanCohortId || sp.key,
              "string"
            );
          }}
          variant="outlined"
          size="small"
        />
      </Cell>
      <Cell width={`${(6 / 70) * 100}%`}>
        <SmallText>{sp.cohortStartDate}</SmallText>
      </Cell>
      {/* <Cell width={`${(2 / 70) * 100}%`}>
        <SmallText>{sp.cohortStartDay}</SmallText>
      </Cell> */}
      <Cell width={`${(4 / 70) * 100}%`}>
        <TextField
          value={sp.screeningDurationDays}
          onChange={(e) => {
            if (isNaN(e.target.value)) return;
            onSeqChange(
              "screeningDurationDays",
              e.target.value,
              sp.studyPlanCohortId || sp.key
            );
          }}
          variant="outlined"
          size="small"
        />
      </Cell>
      <Cell width={`${(5 / 70) * 100}%`}>
        <SmallText>{sp.screeningEndDate}</SmallText>
      </Cell>
      {/* <Cell width={`${(2 / 70) * 100}%`}>
        <SmallText>{sp.screeningEndDay}</SmallText>
      </Cell> */}
      <Cell width={`${(4 / 70) * 100}%`}>
        <TextField
          value={sp.enrollingDurationDays}
          onChange={(e) => {
            if (isNaN(e.target.value)) return;
            onSeqChange(
              "enrollingDurationDays",
              e.target.value,
              sp.studyPlanCohortId || sp.key
            );
          }}
          variant="outlined"
          size="small"
        />
      </Cell>
      <Cell width={`${(5 / 70) * 100}%`}>
        <SmallText>{sp.enrollingEndDate}</SmallText>
      </Cell>
      {/* <Cell width={`${(2 / 70) * 100}%`}>
        <SmallText>{sp.enrollingEndDay}</SmallText>
      </Cell> */}
      <Cell width={`${(4 / 70) * 100}%`}>
        <TextField
          value={sp.dtlDurationDays}
          onChange={(e) => {
            if (isNaN(e.target.value)) return;
            onSeqChange(
              "dtlDurationDays",
              e.target.value,
              sp.studyPlanCohortId || sp.key
            );
          }}
          variant="outlined"
          size="small"
        />
      </Cell>
      <Cell width={`${(5 / 70) * 100}%`}>
        <SmallText>{sp.dtlEndDate}</SmallText>
      </Cell>
      {/* <Cell width={`${(2 / 70) * 100}%`}>
        <SmallText>{sp.dtlEndDay}</SmallText>
      </Cell> */}
      <Cell width={`${(4 / 70) * 100}%`}>
        <TextField
          value={sp.srcDurationDays}
          onChange={(e) => {
            if (isNaN(e.target.value)) return;
            onSeqChange(
              "srcDurationDays",
              e.target.value,
              sp.studyPlanCohortId || sp.key
            );
          }}
          variant="outlined"
          size="small"
        />
      </Cell>
      <Cell width={`${(5 / 70) * 100}%`}>
        <SmallText>{sp.srcEndDate}</SmallText>
      </Cell>
      {/* <Cell width={`${(2 / 70) * 100}%`}>
        <SmallText>{sp.srcEndDay}</SmallText>
      </Cell> */}
      {seqCohorts.length > 1 && (
        <RemoveButtonContainer
          onClick={() => removeRow(sp.studyPlanCohortId || sp.key)}
        >
          <RemoveCircle style={{ fontSize: "1rem" }} />
        </RemoveButtonContainer>
      )}
    </Row>
  );
}

const SmallText = styled.div`
  font-size: 14px;
`;

const RemoveButtonContainer = styled.div`
  position: absolute;
  right: -8px;
  top: -5px;
  color: red;
  cursor: pointer;
  background: white;
  padding: 1px;
  border-radius: 50%;
`;

const Row = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  height: 50px;
  border-bottom: ${(p) => (p.noBorder ? "none" : "1px solid #ccc")};
  &:last-child {
    border: none;
  }
`;

const Cell = styled.div`
  background: ${(p) => (p.variant === "header" ? "#f3f4f4" : "white")};
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  width: ${(p) => (p.width ? p.width : "100%")};
  border-right: 1px solid #ccc;
  padding: 5px;
  text-align: center;
  border-left: ${(p) => (p.leftBorder ? "1px solid #ccc" : "none")};
  &:last-child {
    border: ${(p) => (p.rightBorder ? "1px solid #ccc" : "none")};
  }
  border: ${(p) => (p.fullBorder ? "1px solid #ccc" : "")};
`;
